import { Box, Container, Grid } from '@mui/material';
import HeaderSession from '../../../../utils/headerSession';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useNavigate } from 'react-router-dom';
import SearchSolicitacaoImportacao from './searchSolicitacaoImportacao';


export default function InspeccaoRegulatoria() {
    const navigate = useNavigate()

    return (

        <Container style={{ minHeight: '70vh' }}>
            <Box sx={{ display: { md: 'none' } }}>
                <HeaderSession title='INSPECÇÃO REGULATÓRIA' />
            </Box>

            <Grid container>
                <Grid margin={1} xs={12} md={4} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ border: '2px solid #85287e', padding: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/icones/Desalfandegar.svg" alt="ico" width={100} />
                        <p style={{ fontSize: 14 }}>
                            INSPECÇÃO REGULATÓRIA
                        </p>
                        {/* <br />
                        <SearchSolicitacaoImportacao /> */}
                    </div>

                </Grid>

                <Grid margin={1} xs={12} md={7} item >

                    <Box width={'100%'} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <HeaderSession title='INSPECÇÃO REGULATÓRIA' />
                    </Box>

                    <div>
                        {/* <MenuList
                            // autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                        // onKeyDown={'handleListKeyDown'}
                        >
                            <MenuItem onClick={() => navigate('/utente/solicitacao/numero-de-importador')}>Soliçitar número de importador</MenuItem>
                        </MenuList> */}


                        {/* Emissão de Certificado de Importação */}
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography component="span"> Emissão de Certificado de Importação </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MenuList
                                    // autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                // onKeyDown={'handleListKeyDown'}
                                >
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Medicamentos-controlados-internacionalmente')}>Medicamentos controlados internacionalmente</MenuItem>
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Medicamentos-controlados-nacionais')}>Medicamentos controlados nacionais</MenuItem>
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Medicamento-Normal')}>Medicamento Normal</MenuItem>
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Suplemento-Alimentar')}>Suplemento Alimentar</MenuItem>
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Equipamento')}>Equipamento</MenuItem>
                                    <MenuItem onClick={() => navigate('/inspeccao-regulatoria/solicitacao/importacao/Reagentes-TDRs')}>Reagentes / TDRs</MenuItem>
                                </MenuList>
                            </AccordionDetails>
                        </Accordion>

                        {/* Emissão de Declaração para Desalfandegamento */}
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography component="span"> Emissão de Declaração para Desalfandegamento </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MenuList
                                    // autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                // onKeyDown={'handleListKeyDown'}
                                >
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Medicamentos-controlados-internacionalmente')}>Medicamentos controlados internacionalmente</MenuItem>
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Medicamentos-controlados-nacionais')}>Medicamentos controlados nacionais</MenuItem>
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Medicamento-Normal')}>Medicamento Normal</MenuItem>
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Suplemento-Alimentar')}>Suplemento Alimentar</MenuItem>
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Equipamento')}>Equipamento</MenuItem>
                                    <MenuItem onClick={() => navigate('/utente/solicitacao/desalfandegamento-directo/Reagentes-TDRs')}>Reagentes / TDRs</MenuItem>

                                    <MenuItem onClick={() => navigate('/utente/solicitacao/numero-de-importador')}><b>Soliçitar número de importador ?</b></MenuItem>
                                </MenuList>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion disabled>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography component="span">Emissão da Autorização para Inutilidade</Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Accordion disabled>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <Typography component="span">Emissão de Certificado de Exportação</Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Accordion disabled>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4-content"
                                id="panel4-header"
                            >
                                <Typography component="span">Emissão da Autorização de importação de medicamentos com menos de 75% de validade ou com folheto sem o português</Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Accordion disabled>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                            >
                                <Typography component="span">Emissão da Autorização de envio de medicamento</Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Accordion disabled>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6-content"
                                id="panel6-header"
                            >
                                <Typography component="span">Emissão da Declaração de Exclusividade.</Typography>
                            </AccordionSummary>
                        </Accordion>
                    </div>

                    <br />
                    <br />
                    <SearchSolicitacaoImportacao />
                </Grid>
            </Grid>
            <br />
        </Container>
    )
}
