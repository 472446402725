import { Navigate } from 'react-router-dom';
import api from './api';

const register = (data) => {
    return new Promise((resolve, reject) => {
        api.post('/access/register', data)
            .then((response) => {
                // console.log(response)
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                // reject(error);
                // console.log(error)

                if (error?.response?.data?.message === 'jwt expired') {
                    // return <Navigate to={`/login}`} replace />;
                    window.location.reload()
                }
            });
    });
};

export { register };

