
import { useEffect, useState } from "react";
import HeaderSession from "../../../../../utils/headerSession";
import { Button, Card, Container, DialogActions } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../../../load/loading";
import api from "../../../../../services/api";
import RegisterAccess from "../../../../../utils/registerAccess";
import FichaEstabelecimento from "../../../../gestLicenciados/fichaEstabelecimento";
import ItensFactura from "./itensFactura";
import DocumentViewer from "../documentViewer";
import FichaSolicitacaoImportacao from "../fichaSolicitacaoImportacao";
import FichaSolicitacaoDesalfandegamento from "../fichaSolicitacaoDesalfandegamento";
import { useNavigate } from "react-router-dom";
import HeaderGestorConfigs from "../../../../../utils/headerGestorConfigs";

export default function ConsultaSolicitacaoImportacao({ element: component, ...rest }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [solicitacaoImportacao, setSolicitacao] = useState('')

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacao(response.data.solicitacaoImportacao)
            }).catch(err => 'console.log(err)')
    }, [id])

    return (
        <Container >
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'ACOMPANHAMENTO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />

            {solicitacaoImportacao ?
                <div>

                    {/* Action para solicitar desalfandegamento */}
                    {(solicitacaoImportacao?.status === 'Aprovado' && solicitacaoImportacao?.dataAprovacao && !solicitacaoImportacao?.declaracao) &&
                        <DialogActions>
                            <Button variant="outlined" style={{ textDecorationLine: 'underline', textTransform: 'capitalize', }} onClick={() => navigate(`/utente/solicitacao/desalfandegamento/${id}`)}>Clique aquí para solicitar a Declaração de Desalfandegamento desta importação</Button>
                        </DialogActions>
                    }

                    <FichaEstabelecimento title={'DADOS DO IMPORTADOR'} estabelecimento={solicitacaoImportacao?.importador} menu={false} />

                    {solicitacaoImportacao?.declaracao &&
                        <>
                            <br />
                            <FichaSolicitacaoDesalfandegamento solicitacaoImportacao={solicitacaoImportacao} />
                            <br />
                            <Card style={{ padding: 20 }}>
                                <DocumentViewer pdfUrls={solicitacaoImportacao?.declaracao.docs} title={'DOCUMENTOS ANEXADOS PARA DESALFANDEGAMENTO'} />
                            </Card>
                        </>
                    }

                    <br />
                    <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                    <br />
                    {solicitacaoImportacao?.docs &&
                        <Card style={{ padding: 20 }}>
                            <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} title={'DOCUMENTOS ANEXADOS PARA IMPORTAÇÃO'} />
                        </Card>
                    }


                    {/* <Card style={{ padding: 20 }}>
                        <DocumentViewer
                            pdfUrls={{
                                ...(solicitacaoImportacao?.docs || {}),
                                ...(solicitacaoImportacao?.declaracao?.docs || {}),
                            }}
                        />
                    </Card> */}

                    <br />
                    <Card style={{ padding: 20 }}>
                        <HeaderGestorConfigs title={`ITENS ADICIONADOS : ${solicitacaoImportacao?.items.length}`} />

                        {(solicitacaoImportacao.status === 'Análise') &&
                            <DialogActions>
                                <Button size="small" style={{ backgroundColor: '#85287e' }} variant="contained" onClick={() => navigate(`/solicitar/importacao/add-item/${solicitacaoImportacao._id}`)}>Adicionar mais itens</Button>
                            </DialogActions>
                        }
                        <ItensFactura itens={solicitacaoImportacao?.items} solicitacao={solicitacaoImportacao} utente={true} />
                    </Card>

                </div>
                :
                <Loading />
            }
        </Container>
    )
}