import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Box,
  // Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Modal,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { Medicamento } from "./typesMedicamentos";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";
import { useForm, Controller } from "react-hook-form";
import api from "../../services/api";
import { format } from "date-fns";
import ListaVazia from "../../utils/listaVazia";

type Categoria = {
  _id: string;
  nome: string;
  pai?: string;
};

type FormaFarmaceutica = {
  _id: string;
  titulo: string;
  descricao: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
};

const MedicamentosAutorizados: React.FC = () => {
  const [medicamentos, setMedicamentos] = useState<Medicamento[]>([]);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [formasFarmaceuticas, setFormasFarmaceuticas] = useState<
    FormaFarmaceutica[]
  >([]);
  const [medicamentosSubstitutos, setMedicamentosSubstitutos] = useState<
    Medicamento[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [
          medicamentosResponse,
          categoriasResponse,
          formasFarmaceuticasResponse,
        ] = await Promise.all([
          api.get("/medicamentos"),
          api.get("/categorias"),
          api.get("/formas-farmaceuticas"),
        ]);

        console.log(
          "Resposta completa formas farmacêuticas:",
          formasFarmaceuticasResponse
        );
        console.log(
          "Dados formas farmacêuticas:",
          formasFarmaceuticasResponse.data
        );

        // Garantindo que os dados são arrays antes de atribuir ao estado
        setMedicamentos(
          Array.isArray(medicamentosResponse.data.medicamentos)
            ? medicamentosResponse.data.medicamentos
            : []
        );
        setCategorias(
          Array.isArray(categoriasResponse.data.categorias)
            ? categoriasResponse.data.categorias
            : []
        );

        // Ajustando para pegar o array correto da resposta
        let formasFarmaceuticasData;
        if (
          formasFarmaceuticasResponse.data &&
          typeof formasFarmaceuticasResponse.data === "object"
        ) {
          // Se a resposta for um objeto, procura por propriedades que possam conter o array
          formasFarmaceuticasData =
            formasFarmaceuticasResponse.data.formasFarmaceuticas ||
            formasFarmaceuticasResponse.data.data ||
            formasFarmaceuticasResponse.data.items ||
            Object.values(formasFarmaceuticasResponse.data);
        } else {
          formasFarmaceuticasData = formasFarmaceuticasResponse.data;
        }

        console.log(
          "Dados formatados formas farmacêuticas:",
          formasFarmaceuticasData
        );

        // Se formasFarmaceuticasData for um array de arrays, pega o primeiro array
        if (
          Array.isArray(formasFarmaceuticasData) &&
          Array.isArray(formasFarmaceuticasData[0])
        ) {
          formasFarmaceuticasData = formasFarmaceuticasData[0];
        }

        setFormasFarmaceuticas(
          Array.isArray(formasFarmaceuticasData) ? formasFarmaceuticasData : []
        );
        console.log(
          "Estado final formas farmacêuticas:",
          formasFarmaceuticasData
        );

        setMedicamentosSubstitutos(
          Array.isArray(medicamentosResponse.data.medicamentos)
            ? medicamentosResponse.data.medicamentos
            : []
        );
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        // Em caso de erro, inicializar com arrays vazios
        setMedicamentos([]);
        setCategorias([]);
        setFormasFarmaceuticas([]);
        setMedicamentosSubstitutos([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const medicamentosFiltrados = medicamentos.filter((medicamento) => {
    const categoria = categorias.find(
      (cat) => cat._id === medicamento.categoria._id
    );
    const categoriaNome = categoria?.nome || "";
    return (
      medicamento.nomeGenerico
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      categoriaNome.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const categoriasMapeadas = categorias.reduce<Record<string, Categoria[]>>(
    (map, categoria) => {
      if (categoria.pai) {
        map[categoria.pai] = [...(map[categoria.pai] || []), categoria];
      } else {
        map[categoria._id] = [...(map[categoria._id] || [])];
      }
      return map;
    },
    {}
  );

  const gerarNumeracaoHierarquica = (
    categorias: Categoria[],
    prefixo: string = "",
    contador: Record<string, number> = {}
  ): Record<string, string> => {
    const numeracao: Record<string, string> = {};
    categorias.forEach((categoria, index) => {
      const numAtual = `${prefixo}${index + 1}`;
      numeracao[categoria._id] = numAtual;
      const subcategorias = categoriasMapeadas[categoria._id] || [];
      Object.assign(
        numeracao,
        gerarNumeracaoHierarquica(subcategorias, `${numAtual}.`, contador)
      );
    });
    return numeracao;
  };

  const numeracaoCategorias = gerarNumeracaoHierarquica(
    categorias.filter((cat) => !cat.pai)
  );

  const handleRowClick = (medicamento: Medicamento) => {
    navigate(`/medicamento-autorizado/${medicamento._id}`, {
      state: medicamento,
    });
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    reset();
  };

  const onSubmit = async (data: any) => {
    try {
      const referencias = data.references
        ? data.references
          .split("\n")
          .filter((ref: string) => ref.trim().length > 0)
        : [];

      const utilizacaoMedicamento = data.utilizationLevel || [];
      const medicamentosSubstitutos = data.substitutesMedications || [];
      const dataAtual = new Date();
      const dataFormatada = format(dataAtual, "dd/MM/yyyy");

      const formattedData = {
        ...data,
        nomeGenerico: String(data.genericName || "").trim(),
        nomeComercial: String(data.commercialName || "").trim(),
        classeTerapeutica: data.therapeuticClass,
        tipoSolicitacao:
          data.tipoSolicitacao === "INCLUSAO"
            ? "INCLUSÃO"
            : data.tipoSolicitacao === "EXCLUSAO"
              ? "EXCLUSÃO"
              : data.tipoSolicitacao === "SUBSTITUICAO"
                ? "SUBSTITUIÇÃO"
                : "",
        dosagem: String(data.dosage || "").trim(),
        indicacoesTerapeuticas: String(
          data.therapeuticIndications || ""
        ).trim(),
        doseAdulto: String(data.adultDose || "").trim(),
        dosepediatrica: String(data.pediatricDose || "").trim(),
        duracaoTratamento: String(data.treatmentDuration || "").trim(),
        contraIndicacoes: String(data.contraindications || "").trim(),
        precaucoes: String(data.precautions || "").trim(),
        toxicidade: String(data.toxicity || "").trim(),
        outrasIndicacoes: String(data.otherIndications || "").trim(),
        formaFarmaceutica: String(data.pharmaceuticalForm || "").trim(),
        utilizacaoMedicamento,
        medicamentosSubstitutos,
        motivoSubstituicao: String(data.substitutionReason || "").trim(),
        razoesTerapeuticas: String(data.therapeuticReasons || "").trim(),
        referenciasBibliograficas: referencias,
        nomeSolicitante: String(data.applicantName || "").trim(),
        cargoSolicitante: String(data.applicantPosition || "").trim(),
        localTrabalhoSolicitante: String(data.applicantWorkplace || "").trim(),
        contatoTelefoneSolicitante: String(data.applicantPhone || "").trim(),
        emailSolicitante: String(data.applicantEmail || "").trim(),
        dataSolicitacao: dataFormatada,
        biSolicitante: String(data.applicantBI || "").trim(),
      };

      console.log("Dados do formulário (data):", data);
      console.log("Dados formatados a serem enviados:", formattedData);

      const response = await api.post(
        "/formulario-solicitacao/create",
        formattedData
      );

      console.log("Resposta da API:", response);

      if (response.status === 200 || response.status === 201) {
        handleModalClose();
        fetchMedicamentos();
        alert("Formulário enviado com sucesso!");
      }
    } catch (error: any) {
      console.error("Erro ao enviar formulário:", error);
      console.error("Detalhes do erro:", error.response?.data);

      let errorMessage = "Erro ao enviar formulário. ";

      if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (error.response?.status === 500) {
        errorMessage +=
          "Erro interno do servidor. Por favor, tente novamente mais tarde.";
      } else if (error.message) {
        errorMessage = error.message;
      } else {
        errorMessage += "Por favor, verifique os dados e tente novamente.";
      }

      alert(errorMessage);
    }
  };

  const fetchMedicamentos = async () => {
    try {
      const response = await api.get("/medicamentos");
      setMedicamentos(response.data.medicamentos);
    } catch (error) {
      console.error("Erro ao carregar medicamentos:", error);
    }
  };

  return (
    <Container>
      <HeaderSession title="MEDICAMENTOS AUTORIZADOS" />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        {/* 
        <button
          onClick={handleModalOpen}
          style={{
            padding: "12px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            color: " white",
            backgroundColor: " #85287e",
          }}
        >
          Nova Solicitação
        </button>
        */}
      </Box>

      <Box sx={{ background: "white", padding: "1rem" }}>
        <Box marginBottom={2} display="flex" justifyContent="space-between">
          <TextField
            label="Buscar por Nome ou Categoria"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>

        {isLoading ? (
          <Box display="flex" justifyContent="center" marginTop={5}>
            <Loading text={""} focus={false} />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ border: "1px black" }} component={Paper}>
              <Table sx={{ border: "1px", borderColor: "black" }}>
                <TableHead sx={{ backgroundColor: "#dcdede" }}>
                  <TableRow>
                    <TableCell>Código Nacional</TableCell>
                    <TableCell>Código UNSPSC</TableCell>
                    <TableCell>Apresentação</TableCell>
                    <TableCell>Nome Genérico</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: "1px", borderColor: "black" }}>
                  {categorias
                    .filter((cat) => !cat.pai)
                    .map((categoria) => (
                      <React.Fragment key={categoria._id}>
                        {/* Categoria Pai */}
                        <TableRow>
                          <TableCell colSpan={5} style={{ fontWeight: "bold" }}>
                            {`${numeracaoCategorias[categoria._id]} ${categoria.nome
                              }`}
                          </TableCell>
                        </TableRow>

                        {/* Subcategorias e Medicamentos */}
                        {(categoriasMapeadas[categoria._id] || []).map(
                          (subcat) => (
                            <React.Fragment key={subcat._id}>
                              <TableRow>
                                <TableCell
                                  colSpan={5}
                                  style={{ paddingLeft: 20 }}
                                >
                                  {`${numeracaoCategorias[subcat._id]} ${subcat.nome}`}
                                </TableCell>
                              </TableRow>
                              {medicamentosFiltrados
                                .filter((med) => med.categoria._id === subcat._id)
                                .map((medicamento) => (
                                  <TableRow
                                    key={medicamento._id}
                                    hover
                                    onClick={() => handleRowClick(medicamento)}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell>
                                      {medicamento.codNacional}
                                    </TableCell>
                                    <TableCell>{medicamento.codUNSPSC}</TableCell>
                                    <TableCell>
                                      {medicamento.apresentacao}
                                    </TableCell>
                                    <TableCell>
                                      {medicamento.nomeGenerico}
                                    </TableCell>
                                    {/* <TableCell>
                                    {new Date(
                                      medicamento.createdAt
                                    ).toLocaleDateString()}
                                  </TableCell> */}
                                  </TableRow>
                                ))}
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {medicamentos.length < 1 && <ListaVazia text={''} />}

          </>
        )}
      </Box>
      {/*Este é o Modal*/}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 1200,
            maxHeight: "90vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Solicitação de Inclusão, Exclusão ou Substituição de Medicamentos
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="tipoSolicitacao"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Selecione o tipo de solicitação" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      required
                      error={!!errors.tipoSolicitacao}
                    >
                      <InputLabel id="tipo-solicitacao-label">
                        Tipo de Solicitação
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="tipo-solicitacao-label"
                        label="Tipo de Solicitação"
                      >
                        <MenuItem value="INCLUSAO">INCLUSÃO</MenuItem>
                        <MenuItem value="EXCLUSAO">EXCLUSÃO</MenuItem>
                        <MenuItem value="SUBSTITUICAO">SUBSTITUIÇÃO</MenuItem>
                      </Select>
                      {errors.tipoSolicitacao && (
                        <Typography color="error" variant="caption">
                          {errors.tipoSolicitacao.message as string}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="genericName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome genérico do medicamento"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="commercialName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Nome comercial" fullWidth />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel id="classe-terapeutica-label">
                    Classe Terapêutica
                  </InputLabel>
                  <Controller
                    name="therapeuticClass"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="classe-terapeutica-label"
                        label="Classe Terapêutica"
                      >
                        {Array.isArray(categorias) &&
                          categorias.map((categoria) => (
                            <MenuItem key={categoria._id} value={categoria._id}>
                              {categoria.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel id="forma-farmaceutica-label">
                    Forma Farmacêutica
                  </InputLabel>
                  <Controller
                    name="pharmaceuticalForm"
                    control={control}
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          labelId="forma-farmaceutica-label"
                          label="Forma Farmacêutica"
                        >
                          {Array.isArray(formasFarmaceuticas) &&
                            formasFarmaceuticas.length > 0 ? (
                            formasFarmaceuticas.map(
                              (forma: FormaFarmaceutica) => (
                                <MenuItem key={forma._id} value={forma._id}>
                                  {forma.titulo}
                                </MenuItem>
                              )
                            )
                          ) : (
                            <MenuItem disabled value="">
                              Nenhuma forma farmacêutica disponível
                            </MenuItem>
                          )}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="dosage"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Dosagem/Concentração"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="therapeuticIndications"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Indicações terapêuticas"
                      fullWidth
                      multiline
                      rows={3}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="adultDose"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Dose para adulto" fullWidth />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="pediatricDose"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Dose pediátrica" fullWidth />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="treatmentDuration"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Duração do tratamento"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="contraindications"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Contra-indicações"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="precautions"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Precauções"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="toxicity"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Toxicidade"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="otherIndications"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Outras Indicações"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="substitutionReason"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Motivo da Substituição"
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="therapeuticReasons"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Razões Terapêuticas"
                      fullWidth
                      required
                      multiline
                      rows={3}
                      error={!!errors.therapeuticReasons}
                      helperText={
                        errors.therapeuticReasons
                          ? "Este campo é obrigatório"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="medicamentos-substitutos-label">
                    Medicamentos Substitutos
                  </InputLabel>
                  <Controller
                    name="substitutesMedications"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="medicamentos-substitutos-label"
                        label="Medicamentos Substitutos"
                        multiple
                      >
                        {Array.isArray(medicamentosSubstitutos) &&
                          medicamentosSubstitutos.map((med) => (
                            <MenuItem key={med._id} value={med._id}>
                              {med.nomeGenerico}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="references"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Referências bibliográficas são obrigatórias",
                    validate: (value: string) => {
                      const refs = value
                        .split("\n")
                        .filter((ref: string) => ref.trim().length > 0);
                      return (
                        refs.length >= 3 ||
                        "É necessário fornecer pelo menos 3 referências bibliográficas"
                      );
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Referências Bibliográficas"
                      fullWidth
                      required
                      multiline
                      rows={4}
                      error={!!errors.references}
                      helperText={
                        errors.references
                          ? (errors.references.message as string)
                          : "Insira no mínimo 3 referências bibliográficas (uma por linha). Você pode adicionar quantas referências forem necessárias."
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Dados do Solicitante
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome do Solicitante"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantPosition"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Cargo" fullWidth required />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantWorkplace"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Local de Trabalho"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantPhone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Telefone" fullWidth required />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantEmail"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="E-mail"
                      type="email"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="applicantBI"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Número do B.I."
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Nível de utilização</Typography>
                <Controller
                  name="utilizationLevel"
                  control={control}
                  defaultValue={[]}
                  rules={{
                    required: "Selecione pelo menos um nível de utilização",
                  }}
                  render={({ field }) => (
                    <Box>
                      {[
                        {
                          id: "675c66f498bacaed54507cfe",
                          label: "Posto de Saúde",
                        },
                        {
                          id: "675c66f498bacaed54507cff",
                          label: "Centro de Saúde",
                        },
                        {
                          id: "675c66f498bacaed54507d00",
                          label: "Hospital Municipal",
                        },
                        {
                          id: "675c66f498bacaed54507d01",
                          label: "Hospital Geral ou Especializado",
                        },
                        { id: "675c66f498bacaed54507d02", label: "Outros" },
                      ].map((level) => (
                        <FormControlLabel
                          key={level.id}
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes(level.id)}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? [...field.value, level.id]
                                  : field.value.filter(
                                    (v: string) => v !== level.id
                                  );
                                field.onChange(newValue);
                              }}
                            />
                          }
                          label={level.label}
                        />
                      ))}
                    </Box>
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}
              >
                <button
                  style={{
                    padding: "12px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: " red",
                    backgroundColor: "transparent",
                  }}
                  onClick={handleModalClose}
                >
                  Cancelar
                </button>
                <button
                  style={{
                    padding: "12px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: " white",
                    backgroundColor: " #85287e",
                  }}
                  type="submit"
                >
                  Enviar Solicitação
                </button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default MedicamentosAutorizados;
