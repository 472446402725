import { Box, Card, MenuItem } from "@mui/material";
import { Divider } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StatusEstabelecimento from "../../utils/statusEstabelecimento";
import ImagemEdit from "../../utils/imagemEdit";
import ChangeStatusEstabelecimento from "../gestAdmin/changeStatusEstabelecimento";
import { useState } from "react";

export default function NavLateral({ estabelecimento, thisId }) {
  // thisId é passado como props quando este componente vai precisar de um id esquecifico en veis de captuar do useParams
  const url = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(estabelecimento.status);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus); // Atualiza o estado com o novo status
    // console.log("Status atualizado para:", newStatus);
    window.location.reload(); // Recarrega a página inteira
  };

  // console.log(estabelecimento);

  return (
    <Card style={{ padding: 10 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <ImagemEdit
          data={estabelecimento}
          url={"/estabelecimento/change-logo"}
          local={"estabelecimentoId"}
          openEditLogo={false}
          // setOpenEditLogo={setOpenEditLogo}
        />
        
      </div>
<div>
  
</div>
      <p style={{ fontSize: 14, textAlign: "center" }}>
        ESTABELECIMENTO LICENCIADO
        <img src="/icones/licenciamento.svg" alt="img" width={30} />
      </p>
      <StatusEstabelecimento status={estabelecimento.status} />
      <Divider />
      <ChangeStatusEstabelecimento
          estabelecimentoId={estabelecimento?._id}
          currentStatus={status}
          onStatusChange={handleStatusChange}
        />
      <Box marginTop={2}>
        <MenuItem
          selected={
            url.pathname === `/admin/gestor-licenciados/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/gestor-licenciados/${thisId ?? id}`);
          }}
        >
          Informções Gerais{" "}
        </MenuItem>
        <MenuItem
          selected={
            url.pathname === `/admin/autorizacao-de-exercicio/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/autorizacao-de-exercicio/${thisId ?? id}`);
          }}
        >
          Autorização de Exercício{" "}
        </MenuItem>
        <MenuItem
          selected={
            url.pathname ===
            `/admin/gestor-licenciados/inspecao/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/gestor-licenciados/inspecao/${thisId ?? id}`);
          }}
        >
          {" "}
          Inspeções{" "}
        </MenuItem>
        <MenuItem
          selected={
            url.pathname ===
            `/admin/gestor-licenciados/observacoes/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/gestor-licenciados/observacoes/${thisId ?? id}`);
          }}
        >
          {" "}
          Observações{" "}
        </MenuItem>
        <MenuItem
          selected={
            url.pathname ===
            `/admin/gestor-licenciados/solicitacoes/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/gestor-licenciados/solicitacoes/${thisId ?? id}`);
          }}
        >
          {" "}
          Solicitações{" "}
        </MenuItem>
        <MenuItem
          selected={
            url.pathname ===
            `/admin/gestor-licenciados/historicos/${thisId ?? id}`
          }
          onClick={() => {
            navigate(`/admin/gestor-licenciados/historicos/${thisId ?? id}`);
          }}
        >
          {" "}
          Históricos{" "}
        </MenuItem>
      </Box>
    </Card>
  );
}
