
import { useEffect, useState } from 'react'
import api from '../../../../../services/api';
import Loading from '../../../../../load/loading';
import { Card, Typography, } from '@mui/material';
// import DateHora from '../../../../../utils/dateHora';
// import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { format, parseISO } from 'date-fns';
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
import MessageSuccess from "../../../../../messages/messageSuccess";
import ReenviarEmail from '../../licenciamento/reenviarEmail';
import HeaderGestorConfigs from '../../../../../utils/headerGestorConfigs';
import CardInspeccao from './cardInspeccao';
import RegisterAccess from '../../../../../utils/registerAccess';
import Mes from '../../../../../utils/mes';
// import { generatePDF } from './generatePDF';

export default function InspeccaoRealizada({ inspeccaoId, numero }) {
    const [inspeccao, setInspeccao] = useState('')
    const [load, setLoad] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [openReenviarEmail, setOpenReenviarEmail] = useState(false);
    const [novoEmail, setNovoEmail] = useState('');
    const [viewRelatorio, setViewRelatorio] = useState('');
    const [refresh, setRefresh] = useState('');

    // console.log(inspecao)
    useEffect(() => {
        api.get(`/inspeccao/${inspeccaoId}`)
            .then(response => {
                // console.log(response)
                setInspeccao(response.data.inspeccao);
            }).catch(err => 'console.log(err)')

    }, [inspeccaoId, refresh]);


    const generatePDF = (action) => {
        setLoad(action);
        setMessageSuccess('');

        // Criar um novo pdfumento PDF
        const pdf = new jsPDF();

        // AUTO DE VISTORIA CONJUNTA
        function autoVistoriaConjunta(doc) {
            pdf.setFont('helvetica', 'bold');
            pdf.addImage('/img/logo/InsigniaAngola.png', 'PNG', 90, 15, 23, 23);
            pdf.setFontSize(10);
            pdf.text('REPÚBLICA DE ANGOLA', 105, 45, null, null, 'center');
            pdf.setTextColor(255, 0, 0);
            pdf.text('AUTO DE VISTORIA CONJUNTA', 105, 55, null, null, 'center');
            pdf.setTextColor(0, 0, 0);

            // Função para desenhar um checkbox vazio
            const drawEmptyCheckbox = (doc, x, y, size) => {
                pdf.rect(x, y, size, size);
            };

            // Função para desenhar um checkbox preenchido
            const drawFilledCheckbox = (doc, x, y, size) => {
                pdf.rect(x, y, size, size);
                pdf.line(x, y, x + size, y + size); // Linha diagonal
                pdf.line(x + size, y, x, y + size); // Linha diagonal
            };

            // Desenhar checkboxes
            inspeccao?.mindcom ?
                drawFilledCheckbox(doc, 45, 65, 5) // Posição (20, 40) com tamanho 5
                :
                drawEmptyCheckbox(doc, 45, 65, 5); // Posição (10, 40) com tamanho 5
            pdf.text("MINDCOM", 25, 70); // Texto 

            inspeccao?.minsa ?
                drawFilledCheckbox(doc, 75, 65, 5)
                :
                drawEmptyCheckbox(doc, 75, 65, 5);
            pdf.text("ARMED", 61, 70);

            inspeccao?.mirempet ?
                drawFilledCheckbox(doc, 105, 65, 5)
                :
                drawEmptyCheckbox(doc, 105, 65, 5);
            pdf.text("MIREMPET", 85, 70);

            inspeccao?.mintrans ?
                drawFilledCheckbox(doc, 135, 65, 5)
                :
                drawEmptyCheckbox(doc, 135, 65, 5);
            pdf.text("MINTRANS", 115, 70);

            inspeccao?.minint ?
                drawFilledCheckbox(doc, 165, 65, 5)
                :
                drawEmptyCheckbox(doc, 165, 65, 5);
            pdf.text("MININT", 152, 70);


            pdf.text(`PORTA DE ENTRADA DO PROCESSO: ${inspeccao?.portaEntrada}`, 25, 80);

            pdf.setFont('helvetica', 'normal');
            // Dividindo o texto em linhas menores
            const textLongo = pdf.splitTextToSize(`Ao abrigo do artigo ${inspeccao?.artigo}º do Decreto Presidencial nº ${inspeccao?.decretoPresidencial} delocou-se do dia ${new Date(inspeccao?.fim).getDay()} do mês de ${new Date(inspeccao?.fim).getMonth() + 1} e do ano de ${new Date(inspeccao?.fim).getFullYear()}, a comissão de vistoria ao estabelecimento comercial, sito na Rua ${inspeccao?.estabelecimento.rua ?? '...'} nº${inspeccao?.estabelecimento?.ruaNumero ?? '...'} Comuna de ${inspeccao?.estabelecimento?.comuna ?? '...'}  Município de ${inspeccao?.estabelecimento?.muninicipio ?? '...'} na cidade de ${inspeccao?.estabelecimento?.cidade ?? '...'} pertencente a ${inspeccao?.estabelecimento?.empresa.nome ?? '...'} com o NIF ${inspeccao?.estabelecimento?.empresa.nif ?? '...'} e email ${inspeccao?.estabelecimento?.empresa.email ?? '...'} e Telemóvel ${inspeccao?.estabelecimento?.empresa.tel1 ?? '...'} tendo-se constatado o seguinte:`, 170);
            // pdf.text(textLongo, 25, 90);

            const lineHeight = 5; // Espaçamento entre linhas
            let yPosition = 90; // Posição inicial no eixo y

            textLongo.forEach(line => {
                pdf.text(line, 25, yPosition);
                yPosition += lineHeight;
            });

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.2); // largura da linha
            pdf.line(25, 110, 190, 110);

            pdf.setFontSize(8);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`Total do Número de Trabalhadores`, 25, 115);
            pdf.text(`Classificação do Estabelecimento Comercial:`, 100, 115);
            pdf.setFont('helvetica', 'normal');
            pdf.text(`Homes Nacionais:        ${inspeccao?.totalTrabalhadores.nacionais.homens}`, 25, 120);
            pdf.text(`Mulheres Nacionais:     ${inspeccao?.totalTrabalhadores.nacionais.mulheres}`, 25, 125);
            pdf.text(`Homens Estrangeiros:    ${inspeccao?.totalTrabalhadores.estrangeiros.homens}`, 25, 130);
            pdf.text(`Mulheres Estrangeiros:  ${inspeccao?.totalTrabalhadores.estrangeiros.mulheres}`, 25, 135);

            // Classificação do Estabelecimento Comercial
            inspeccao?.classificacaoEstabelecimento === 'Centro Comercial' ?
                drawFilledCheckbox(doc, 127, 117, 3)
                :
                drawEmptyCheckbox(doc, 127, 117, 3);
            pdf.text("Centro Comercial", 90, 120);

            inspeccao?.classificacaoEstabelecimento === 'Comércio Grossista' ?
                drawFilledCheckbox(doc, 127, 123, 3)
                :
                drawEmptyCheckbox(doc, 127, 123, 3);
            pdf.text("Comércio Grossista", 90, 126);

            inspeccao?.classificacaoEstabelecimento === 'Comércio a Retalho' ?
                drawFilledCheckbox(doc, 127, 129, 3)
                :
                drawEmptyCheckbox(doc, 127, 129, 3);
            pdf.text("Comércio a Retalho", 90, 132);

            inspeccao?.classificacaoEstabelecimento === 'Comércio Geral' ?
                drawFilledCheckbox(doc, 127, 135, 3)
                :
                drawEmptyCheckbox(doc, 127, 135, 3);
            pdf.text("Comércio Geral", 90, 138);

            inspeccao?.classificacaoEstabelecimento === 'Comércio de Representação' ?
                drawFilledCheckbox(doc, 127, 141, 3)
                :
                drawEmptyCheckbox(doc, 127, 141, 3);
            pdf.text("Comércio  de Representação", 90, 144);

            // ########################################################################################
            inspeccao?.classificacaoEstabelecimento === 'Hipermercado' ?
                drawFilledCheckbox(doc, 175, 117, 3)
                :
                drawEmptyCheckbox(doc, 175, 117, 3);
            pdf.text("Hipermercado", 145, 120);

            inspeccao?.classificacaoEstabelecimento === 'supermercado' ?
                drawFilledCheckbox(doc, 175, 123, 3)
                :
                drawEmptyCheckbox(doc, 175, 123, 3);
            pdf.text("supermercado", 145, 126);

            inspeccao?.classificacaoEstabelecimento === 'Minimercado' ?
                drawFilledCheckbox(doc, 175, 129, 3)
                :
                drawEmptyCheckbox(doc, 175, 129, 3);
            pdf.text("Minimercado", 145, 132);

            inspeccao?.classificacaoEstabelecimento === 'Loja de Conveniência' ?
                drawFilledCheckbox(doc, 175, 135, 3)
                :
                drawEmptyCheckbox(doc, 175, 135, 3);
            pdf.text("Loja de Conveniência", 145, 138);

            inspeccao?.classificacaoEstabelecimento === 'Cash and Carry' ?
                drawFilledCheckbox(doc, 175, 141, 3)
                :
                drawEmptyCheckbox(doc, 175, 141, 3);
            pdf.text("Cash and Carry", 145, 144);


            pdf.setFont('helvetica', 'bold');
            pdf.text(`Classificação de Actividade Económica a exercer:  ${inspeccao?.cae}`, 25, 160);

            pdf.text('RESULTADOS DA INSPECÇÃO A INFRA-ESTRUTURA COMERCIAL OU DE SERVIÇO:', 105, 170, null, null, 'center');

            const startX = 25; // Posição inicial no eixo X
            const startY = 175; // Posição inicial no eixo Y
            const colWidth = 45; // Largura de cada coluna
            const rowHeight1 = 5; // Altura da primeira linha
            const rowHeight2 = 23; // Altura da segunda linha
            // Função para desenhar uma célula
            function drawCell(x, y, width, height, text) {
                pdf.rect(x, y, width, height);
                pdf.text(text, x + 5, y + 15);
            }
            // Desenhar a primeira linha
            drawCell(startX + 0 * 33, startY, 35, rowHeight1, '');
            for (let i = 1; i < 4; i++) {
                drawCell(15 + i * colWidth, startY, colWidth, rowHeight1, '');
            }
            // Desenhar a segunda linha
            drawCell(startX + 0 * 33, startY + rowHeight1, 35, rowHeight2, '');
            for (let i = 1; i < 4; i++) {
                drawCell(15 + i * colWidth, startY + rowHeight1, colWidth, rowHeight2, '');
            }
            // ADICIONAR DADOS DA TABELA
            pdf.setFont('helvetica', 'normal');
            pdf.setFontSize(7);
            pdf.text('ÁREAS', 40, 178);
            pdf.text('ESTADO DA FUNCIONALIDADE', 67, 178);
            pdf.text('CONDIÇOES DE SAÚDE PUBLICA', 107, 178);
            pdf.text('CONDIÇOES SEGURANÇA PUBLICA', 152, 178);

            pdf.setFontSize(10);
            // AREAS
            // pdf.text('Exposição e venda', 27, 185);
            // pdf.text(inspeccao?.areaExposicao, 30, 185);
            // pdf.text('Bruta', 40, 195);
            // pdf.text(inspeccao?.areaBruta, 30, 190);

            pdf.text('Exposição e venda', 27, 185);
            pdf.text(inspeccao?.resultadoIICS?.areas?.exposicao ?? '', 35, 190);
            pdf.text('Bruta', 40, 195);
            pdf.text(inspeccao?.resultadoIICS?.areas?.bruta ?? '', 35, 200);

            // FUNCAO DE AVALIACAO
            function avaliacaoTabelaResultados(resultado, x, y) {
                pdf.setFontSize(8);

                resultado === 'Bom' ?
                    drawFilledCheckbox(doc, x, y + 5, 3)
                    :
                    drawEmptyCheckbox(doc, x, y + 5, 3);
                pdf.text("BOM", x - 30, y + 7);

                resultado === 'Regular' ?
                    drawFilledCheckbox(doc, x, y + 10, 3)
                    :
                    drawEmptyCheckbox(doc, x, y + 10, 3);
                pdf.text("REGULAR", x - 30, y + 12);

                resultado === 'Mau' ?
                    drawFilledCheckbox(doc, x, y + 15, 3)
                    :
                    drawEmptyCheckbox(doc, x, y + 15, 3);
                pdf.text("MAU", x - 30, y + 17);

                resultado === 'Categoria de risco' ?
                    drawFilledCheckbox(doc, x, y + 20, 3)
                    :
                    drawEmptyCheckbox(doc, x, y + 20, 3);
                pdf.text("CATEGORIA DE RISCO", x - 32, y + 22);

            }

            // ESTADO DA FUNCIONALIDADE
            avaliacaoTabelaResultados(inspeccao?.resultadoIICS?.estadoFuncionalidade, 95, 178)
            // CONDIÇOES DE SAÚDE PUBLICA
            avaliacaoTabelaResultados(inspeccao?.resultadoIICS?.condicoesSaudePublica, 140, 178)
            // CONDIÇOES SEGURANÇA PUBLICA
            avaliacaoTabelaResultados(inspeccao?.resultadoIICS?.condicoesSegurancaPublica, 185, 178)

            pdf.setFont('helvetica', 'bold');
            pdf.text('APÓS DELIBERAÇÃO, A COMISSÃO DE VISTORIA, CONSIDERA QUE O ESTABELECIMENTO COMERCIAL ESTA:', 105, 210, null, null, 'center');
            pdf.setFont('helvetica', 'normal');
            pdf.setFontSize(10);
            inspeccao?.aposDCVCECE === 'apto' ?
                drawFilledCheckbox(doc, 25, 215, 3)
                :
                drawEmptyCheckbox(doc, 25, 215, 3);
            pdf.text("Apto para a Emissão/Renovação do Alvará Comercial / Autorização para exercício farmacêutico.", 30, 218);

            inspeccao?.aposDCVCECE === 'semAlvara' ?
                drawFilledCheckbox(doc, 25, 222, 3)
                :
                drawEmptyCheckbox(doc, 25, 222, 3);
            pdf.text("Em funcionanto sem Alvará Comercial.", 30, 225);

            inspeccao?.aposDCVCECE === 'deficiente' ?
                drawFilledCheckbox(doc, 25, 228, 3)
                :
                drawEmptyCheckbox(doc, 25, 228, 3);
            pdf.text(`Com deficiêcias e recomendou-se sua superação no prazo de ${inspeccao?.prazoSuperacao ?? '________'} dias a contar da presente data`, 30, 231);

            inspeccao?.aposDCVCECE === 'naoApto' ?
                drawFilledCheckbox(doc, 25, 234, 3)
                :
                drawEmptyCheckbox(doc, 25, 234, 3);
            pdf.text(`Não apto para a Emissão/Renovação do Alvará Comercial / Autorização para exercício farmacêutico.`, 30, 237);

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.2); // largura da linha
            pdf.line(25, 245, 190, 245);

            pdf.setFont('helvetica', 'bold');
            pdf.text('ASSINATURAS', 105, 250, null, null, 'center');
            pdf.setFont('helvetica', 'normal');
            pdf.setFontSize(8);

            // ? inspeccao?.nomeInstituicao1?.slice(0, 20) : ''}

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.1); // largura da linha
            pdf.line(25, 260, 70, 260);
            pdf.text(inspeccao?.nomeInstituicao1?.slice(0, 35), 25, 259);
            pdf.text("NOME / INSTITUIÇÃO", 30, 265);

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.1); // largura da linha
            pdf.line(80, 260, 130, 260);
            pdf.text(inspeccao?.nomeInstituicaoCoordenador?.slice(0, 35), 80, 259);
            pdf.text("NOME / INSTITUIÇÃO COORDENADOR", 80, 265);

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.1); // largura da linha
            pdf.line(140, 260, 190, 260);
            // pdf.text(inspeccao?.nomeInstituicao2?.slice(0, 35) , 140, 259); 
            pdf.text((inspeccao?.nomeInstituicao2?.slice(0, 35) ?? ''), 140, 259);
            pdf.text("NOME / INSTITUIÇÃO", 150, 265);

            pdf.setDrawColor(0, 0, 0); // cor preta
            pdf.setLineWidth(0.1); // largura da linha
            pdf.line(70, 280, 140, 280);
            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(10);
            pdf.text(inspeccao?.requerente, 105, 279, null, null, 'center');
            pdf.text("O Requerente", 105, 275, null, null, 'center');

            pdf.addPage();
        }


        // Função para adicionar o rodapé
        function addRodape(doc) {
            // Adicionar rodapé
            pdf.setFontSize(10);
            pdf.setLineWidth(0.5);
            pdf.line(20, 264, 190, 264);

            // Adicionar informações de endereço no rodapé (lado esquerdo)
            pdf.text('Tel.(+244) 945 817 227 ', 20, 270);
            pdf.text('E-mail: expedientegeral@armed.gov.ao | www.armed.gov.ao', 20, 275);
            pdf.text('Rua Cmdt, Che-Guevara nº 86/86A, Maculusso,', 20, 280);
            pdf.text('Luanda - Angola', 20, 285);

            // Adicionar imagem ao lado direito do rodapé
            const footerImagePath = '/img/logo/logoArmed.png'; // Caminho para a imagem do rodapé
            const footerImageWidth = 40; // Largura da imagem do rodapé em porcentagem
            const footerImageHeight = 17; // Altura da imagem do rodapé em porcentagem
            pdf.addImage(footerImagePath, 'png', 150, 270, footerImageWidth, footerImageHeight);
            // Fim rodapé
        }

        // Função para adicionar o cabeçalho da tabela
        function addTableHeader(doc, y) {
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.setFillColor(220, 220, 220); // cor de fundo cinza claro

            // Desenha os retângulos de fundo
            doc.rect(25, y - 5, 70, 10, 'F');
            doc.rect(95, y - 5, 20, 10, 'F');
            doc.rect(115, y - 5, 20, 10, 'F');
            doc.rect(135, y - 5, 20, 10, 'F');
            doc.rect(155, y - 5, 40, 10, 'F');

            // Desenha o texto do cabeçalho sobre os retângulos de fundo
            doc.text("Descrição", 25, y);
            doc.text("Mau", 95, y);
            doc.text("Suficiente", 115, y);
            doc.text("Bom", 135, y);
            doc.text("Observações", 155, y);
        }


        // Função para marcar com X
        function avaliacao(avaliacao, marginTop) {
            pdf.setFontSize(13);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`${avaliacao.avaliacao === "Mau" ? 'X' : ''}`, 103, marginTop);
            pdf.text(`${avaliacao.avaliacao === "Suficiente" ? 'X' : ''}`, 125, marginTop);
            pdf.text(`${avaliacao.avaliacao === "Bom" ? 'X' : ''}`, 145, marginTop);
            pdf.setFont('helvetica', 'normal');
            pdf.setFontSize(10);
            pdf.text(`${avaliacao?.observacoes ? avaliacao?.observacoes?.slice(0, 20) : ''} ${avaliacao?.observacoes?.length > 20 ? '...' : ''}`, 157, marginTop);
        }


        autoVistoriaConjunta();

        // ########################################################################################
        // PAGINA 1
        pdf.setFont('helvetica', 'bold');
        pdf.addImage('/img/logo/InsigniaAngola.png', 'PNG', 90, 15, 23, 23);
        pdf.setFontSize(10);
        pdf.text('REPUBLICA DE ANGOLA', 105, 45, null, null, 'center');
        pdf.text('MINISTÉRIO DA SAÚDE', 105, 50, null, null, 'center');
        pdf.text('AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIA DE SAÚDE', 105, 55, null, null, 'center');
        pdf.text("AUTO DE VISTORIA PARA FARMÁCIAS /DEPÓSITOS DE MEDICAMENTOS E DE EQUIPAMENTOS", 105, 70, null, null, 'center');
        pdf.setDrawColor(0, 0, 0); // cor preta
        pdf.setLineWidth(0.1); // largura da linha
        pdf.line(20, 71, 190, 71);

        // Add instructions
        //    pdf.text("A PREENCHER PREFERENCIALMENTE ANTES DA VISTORIA", 20, 80);
        pdf.text("1. Instruções de preenchimento:", 25, 85);
        pdf.setFont('helvetica', 'normal');
        pdf.text("1.1. Marcar com X a cada item consoante o cumprimento dos requisitos: Mau suficiente e bom;", 25, 90);
        pdf.text("1.2. Preencher na coluna das observações eventuais inconformidades observadas no terreno;", 25, 95);
        pdf.text("1.3. Somar o total dos pontos;", 25, 100);
        pdf.text("1.4. Avaliação final da entidade vistoriada:", 25, 105);
        pdf.text("1.4.1. Mau - indeferido", 27, 110);
        pdf.text("1.4.2. Médio - recomendações a cumprir (prazo de 6 meses)", 27, 115);
        pdf.text("1.4.3. Bom - deferido para licenciamento", 27, 120);

        //    Dados do estabelecimento
        pdf.setFont('helvetica', 'bold');
        pdf.text("2. Dados do estabelecimento:", 25, 130);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`2.1. Nome do Depósito/Farmácia:`, 25, 140);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.nome}`, 80, 140);
        pdf.setFont('helvetica', 'normal');
        pdf.text("2.2. Proprietário :", 25, 145);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.empresa?.nome}`, 55, 145);
        pdf.setFont('helvetica', 'normal');
        pdf.text("2.3. Director Técnico:", 25, 150);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.directorTecnico?.dadosPessoais?.nome}`, 60, 150);
        pdf.setFont('helvetica', 'normal');
        pdf.text("2.4. Contacto telefónico e e-mail:", 25, 155);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.empresa?.tel1}`, 80, 155);
        pdf.text(`${' / ' + inspeccao?.estabelecimento?.email}`, 100, 155);
        pdf.setFont('helvetica', 'normal');
        pdf.text("2.5. Localização", 25, 160);
        pdf.text("Rua: ", 26, 165);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.rua}`, 35, 165);
        pdf.setFont('helvetica', 'normal');
        pdf.text("Bairro: ", 26, 170);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.bairro}`, 40, 170);
        pdf.setFont('helvetica', 'normal');
        pdf.text("Município:", 26, 175);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.municipio}`, 45, 175);
        pdf.setFont('helvetica', 'normal');
        pdf.text("Província:", 26, 180);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.estabelecimento?.provincia}`, 45, 180);
        pdf.setFont('helvetica', 'normal');
        pdf.text("Coordenadas GPS: ", 26, 185);

        // Finalidade da Vistoria
        pdf.setFont('helvetica', 'bold');
        pdf.text("3. Finalidade da Vistoria:", 25, 200);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${inspeccao?.tipo}`, 28, 205);
        //    pdf.text("Licenciamento         Renovação         Monitorização           Outros:_________________________________________", 25, 200);

        // Tipo de estabelecimeto
        pdf.setFont('helvetica', 'bold');
        pdf.text("4. Tipo de estabelecimento:", 25, 215);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${inspeccao?.estabelecimento?.tipo?.designacao ??''}`, 28, 220);
        // pdf.text(`???`, 28, 220);

        // Adicionar rodapé
        addRodape()


        // ########################################################################################
        // PAGINA 2
        pdf.addPage();
        pdf.setFont('helvetica', 'bold');
        pdf.text("3. Tipo de infraestrutura:", 25, 20);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${inspeccao?.infraestrutura}`, 28, 25);
        pdf.text(`3.1. Água: ${inspeccao?.agua}`, 25, 35);
        pdf.text(`3.2. Esgoto: ${inspeccao?.esgoto}`, 25, 40);
        pdf.text(`3.3. Condições de higiene e de segurança: ${inspeccao?.condicoesHS}`, 25, 45);
        pdf.text(`3.4. Medidas de combate a pragas: ${inspeccao?.medidasCP}`, 25, 50);

        // FACHADA PRINCIPAL
        pdf.setFont('helvetica', 'bold');
        pdf.text("4. FACHADA PRINCIPAL", 25, 70);
        pdf.setFont('helvetica', 'normal');

        //    Table data
        addTableHeader(pdf, 80);
        pdf.setFont('helvetica', 'normal');
        const tableDataFachadaPrincipal = [
            { description: "", y: 90 },
            { description: "", y: 100 },
            { description: "", y: 110 },
            { description: "", y: 120 },
            { description: "", y: 130 }
        ];

        pdf.text("4.1. Letreiro afixado perpendicular", 26, 90);
        pdf.text("à parede do edifício", 26, 94);

        pdf.text("4.2. Pintura exterior sem imagens/", 26, 100);
        pdf.text("gravuras nas paredes", 26, 104);

        pdf.text("4.3. Placa com nome do Director Técnico ", 26, 110);
        pdf.text("na porta principal (« facultativo ) ou interior", 26, 114);

        pdf.text("4.4. Condições de higiene e de segurança", 26, 120);

        pdf.text("4.5. Rampa de acesso com máximo de 10º", 26, 130);
        pdf.text("(se aplicável)", 26, 134);

        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        avaliacao(inspeccao.fachaPrincipal.letreiroAPPE, 90)
        avaliacao(inspeccao.fachaPrincipal.pinturaESIGP, 100)
        avaliacao(inspeccao.fachaPrincipal.placaCNDPP, 110)
        avaliacao(inspeccao.fachaPrincipal.condicoesHS, 120)
        avaliacao(inspeccao.fachaPrincipal.rampaACM10, 130)
        // Draw table
        tableDataFachadaPrincipal.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 70, 10);  // Mau
            pdf.rect(95, row.y - 5, 20, 10);  // Mau
            pdf.rect(115, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(135, row.y - 5, 20, 10);  // Bom
            pdf.rect(155, row.y - 5, 40, 10); // Observações
        });


        //  ATENDIMENTO
        pdf.setFont('helvetica', 'bold');
        pdf.text("5. ATENDIMENTO ", 25, 150);
        pdf.setFont('helvetica', 'normal');

        //    Table data
        addTableHeader(pdf, 160);
        pdf.setFont('helvetica', 'normal');
        const tableDataAtendimento = [
            { description: "", y: 170 },
            { description: "", y: 175 },
            { description: "", y: 180 },
            { description: "", y: 185 },
            { description: "", y: 190 },
            { description: "", y: 195 },
            { description: "", y: 200 },
            { description: "", y: 205 },
            { description: "", y: 210 },
            { description: "", y: 215 },
        ];

        pdf.text("5.1. Dimensões mínimas de 35m²", 26, 169);
        pdf.text("5.2. Pintura clara de qualidade", 26, 174);
        // pdf.text("5.3. Inexistência de imagens/gravuras ou materiais publicitários pintados nas paredes, balcões e/ou vitrinas", 26, 170);
        pdf.text("5.3. Inexistência de imagens/gravuras...", 26, 179);
        pdf.text("5.4 Chão", 26, 184);
        pdf.text("5.5. Tecto", 26, 189);
        pdf.text("5.6. Balcões de atendimento ", 26, 194);
        pdf.text("5.7. Climatização com A/C ", 26, 199);
        pdf.text("5.8. Iluminação adequada", 26, 204);
        pdf.text("5.9. Mobiliário/cadeiras adequad", 26, 209);
        pdf.text("5.10. Extintores (quantificar)", 26, 214);


        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        avaliacao(inspeccao.atendimento.dimensoesM35M, 169)
        avaliacao(inspeccao.atendimento.pinturaCQ, 174)
        avaliacao(inspeccao.atendimento.inexistenciaIGMPPPBV, 179)
        avaliacao(inspeccao.atendimento.chao, 184)
        avaliacao(inspeccao.atendimento.tecto, 189)
        avaliacao(inspeccao.atendimento.balcoesAtendimento, 194)
        avaliacao(inspeccao.atendimento.climatizacaoAC, 199)
        avaliacao(inspeccao.atendimento.iluminacaoAdequada, 204)
        avaliacao(inspeccao.atendimento.mobiliarioAdequado, 209)
        avaliacao(inspeccao.atendimento.extintores, 214)

        // Draw table
        tableDataAtendimento.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 70, 10);  // Mau
            pdf.rect(95, row.y - 5, 20, 10);  // Mau
            pdf.rect(115, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(135, row.y - 5, 20, 10);  // Bom
            pdf.rect(155, row.y - 5, 40, 10); // Observações
        });

        // Adicionar rodapé
        addRodape()



        // ########################################################################################
        // PAGINA 3
        pdf.addPage();

        //  ARMAZEM
        pdf.setFont('helvetica', 'bold');
        pdf.text("6. ARMAZÉM", 25, 20);
        pdf.setFont('helvetica', 'normal');

        //    Table data
        addTableHeader(pdf, 30);
        pdf.setFont('helvetica', 'normal');
        const tableDataArmazem = [
            { description: "", y: 40 },
            { description: "", y: 50 },
            { description: "", y: 60 },
            { description: "", y: 70 },
            { description: "", y: 80 },
            { description: "", y: 90 },
            { description: "", y: 100 },
            { description: "", y: 110 },
            { description: "", y: 120 },
            { description: "", y: 130 },
            { description: "", y: 140 },
            { description: "", y: 150 },
            { description: "", y: 160 },
            { description: "", y: 170 },
        ];

        pdf.text("6.1. Dimensões mínimas de 300m²/ 20m²", 26, 40);

        pdf.text("6.2. Pavimento ladrilhado/epoxy (especificar)", 26, 50);

        pdf.text("6.3. Pintura clara de qualidade", 26, 60);

        pdf.text("6.4. Inexistência de imagens/gravuras ou ", 26, 70);
        pdf.text("material publicitário nas paredes e/ou vitrinas", 26, 74);

        pdf.text("6.5. Tecto", 26, 80);
        pdf.text("6.6. Climatização com A/C", 26, 90);
        pdf.text("6.7. Iluminação adequada", 26, 100);
        pdf.text("6.8. Termohigrómetros", 26, 110);
        pdf.text("6.9. Estanteria/ prateleiras adequadas", 26, 120);

        pdf.text("6.10. Câmara frigorífica com termóstato", 26, 130);
        pdf.text("(para vacinas)", 26, 134);

        pdf.text("6.11.Mobiliário/cadeiras de escritório", 26, 140);
        pdf.text("6.12. Área para controlados", 26, 150);
        pdf.text("6.13. Extintores (quantificar)", 26, 160);

        pdf.text("6.14. Cortinas plásticas na zona de", 26, 170);
        pdf.text("carga e descarga", 26, 174);

        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        avaliacao(inspeccao.armazem.dimensoesM300M20M, 40)
        avaliacao(inspeccao.armazem.pavimentoLE, 50)
        avaliacao(inspeccao.armazem.pinturaCQ, 60)
        avaliacao(inspeccao.armazem.InexistenciaIGMPPPBV, 70)
        avaliacao(inspeccao.armazem.tecto, 80)
        // avaliacao(inspeccao.armazem.chao, 80)
        avaliacao(inspeccao.armazem.climatizacaoAC, 90)
        avaliacao(inspeccao.armazem.iluminacaoAdequada, 100)
        avaliacao(inspeccao.armazem.termohigrometros, 110)
        avaliacao(inspeccao.armazem.estanteria, 120)
        avaliacao(inspeccao.armazem.camaraFrigorifica, 130)
        avaliacao(inspeccao.armazem.mobiliario, 140)
        avaliacao(inspeccao.armazem.areaControlados, 150)
        avaliacao(inspeccao.armazem.extintores, 160)
        avaliacao(inspeccao.armazem.cortinasPZCD, 170)

        // Draw table
        tableDataArmazem.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 70, 10);  // Mau
            pdf.rect(95, row.y - 5, 20, 10);  // Mau
            pdf.rect(115, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(135, row.y - 5, 20, 10);  // Bom
            pdf.rect(155, row.y - 5, 40, 10); // Observações
        });



        //  SALA DO PESSOAL
        pdf.setFont('helvetica', 'bold');
        pdf.text("7. SALA DO PESSOAL", 25, 180);
        pdf.setFont('helvetica', 'normal');

        //    Table data
        addTableHeader(pdf, 190);
        pdf.setFont('helvetica', 'normal');
        const tableSAlaPessoal = [
            { description: "", y: 200 },
            { description: "", y: 205 },
        ];

        pdf.text("7.1. Cacifo (obrigatório)", 26, 199);

        pdf.text("7.2. Copa (se aplicável)", 26, 204);


        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        avaliacao(inspeccao.salaDoPessoal.cacifo, 199)
        avaliacao(inspeccao.salaDoPessoal.copa, 204)

        // Draw table
        tableSAlaPessoal.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 70, 10);  // Mau
            pdf.rect(95, row.y - 5, 20, 10);  // Mau
            pdf.rect(115, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(135, row.y - 5, 20, 10);  // Bom
            pdf.rect(155, row.y - 5, 40, 10); // Observações
        });



        //  INSTALACOES SANITÁRIAS
        pdf.setFont('helvetica', 'bold');
        pdf.text("8. INSTALAÇÕES SANITÁRIAS", 25, 220);
        pdf.setFont('helvetica', 'normal');

        //    Table data
        addTableHeader(pdf, 230);
        pdf.setFont('helvetica', 'normal');
        const tableisntalacoesSanitarias = [
            { description: "", y: 240 },
            { description: "", y: 245 },
            { description: "", y: 250 },
        ];

        pdf.text("8.1. Condições de higiene", 26, 239);
        pdf.text("8.2. Sanita e lavatório", 26, 244);
        pdf.text("8.3. Pavimento e paredes", 26, 249);

        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        avaliacao(inspeccao.instalacoesSanitarias.condicoesHigiene, 239)
        avaliacao(inspeccao.instalacoesSanitarias.pavimentoParede, 244)
        avaliacao(inspeccao.instalacoesSanitarias.sanitaLavatorio, 249)

        // Draw table
        tableisntalacoesSanitarias.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 70, 10);  // Mau
            pdf.rect(95, row.y - 5, 20, 10);  // Mau
            pdf.rect(115, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(135, row.y - 5, 20, 10);  // Bom
            pdf.rect(155, row.y - 5, 40, 10); // Observações
        });

        // Adicionar rodapé
        addRodape()


        // ########################################################################################
        // PAGINA 4
        pdf.addPage();

        //  TOTAL GERAL
        //    Table data
        // addTableHeader(pdf, 30);
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bold');
        pdf.setFillColor(220, 220, 220); // cor de fundo cinza claro

        // Desenha os retângulos de fundo
        pdf.rect(25, 30 - 5, 40, 10, 'F');
        pdf.rect(65, 30 - 5, 20, 10, 'F');
        pdf.rect(85, 30 - 5, 20, 10, 'F');
        pdf.rect(105, 30 - 5, 20, 10, 'F');
        pdf.rect(125, 30 - 5, 70, 10, 'F');

        // Desenha o texto do cabeçalho sobre os retângulos de fundo
        pdf.text("TOTAL GERAL", 30, 40);
        pdf.text("Mau", 65, 30);
        pdf.text("Suficiente", 85, 30);
        pdf.text("Bom", 105, 30);
        pdf.text("Classificaçao final", 125, 30);

        pdf.setFont('helvetica', 'normal');
        const tableTotalGeral = [
            { description: "", y: 40 },
        ];

        // avaliacao(avaliacao, marginTop)
        //   AVALIÇÃO
        // TOTAL GERAL

        // Função para marcar com X
        function avaliacaoTotalGeral(avaliacao, marginTop) {
            pdf.setFontSize(13);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`${avaliacao.avaliacao === "Mau" ? 'X' : ''}`, 75, marginTop);
            pdf.text(`${avaliacao.avaliacao === "Suficiente" ? 'X' : ''}`, 95, marginTop);
            pdf.text(`${avaliacao.avaliacao === "Bom" ? 'X' : ''}`, 115, marginTop);
            pdf.setFont('helvetica', 'normal');
            pdf.setFontSize(10);
            pdf.text(`${avaliacao?.observacoes ? avaliacao?.observacoes?.slice(0, 20) : ''} ${avaliacao?.observacoes ?? avaliacao?.observacoes?.length > 20 ? '...' : ''}`, 100, marginTop);
        }

        avaliacaoTotalGeral(inspeccao?.totalGeral, 40)

        // CLASSIFICACAO FINAL
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${inspeccao?.totalGeral.classificacaoFinal}`, 127, 40);

        // Draw table
        tableTotalGeral.forEach(row => {
            // pdf.text(row.description, 25, row.y);
            pdf.rect(25, row.y - 5, 40, 10);  // Mau
            pdf.rect(65, row.y - 5, 20, 10);  // Mau
            pdf.rect(85, row.y - 5, 20, 10);  // Suficiente
            pdf.rect(105, row.y - 5, 20, 10);  // Bom
            pdf.rect(125, row.y - 5, 70, 10); // Observações
        });


        // RECOMENDACOES
        pdf.setFont('helvetica', 'bold');
        pdf.text("9. RECOMENDAÇÕES", 25, 69);
        pdf.setFont('helvetica', 'normal');
        // Dividindo o texto em linhas menores
        const splitText = pdf.splitTextToSize(inspeccao?.recomendacoes, 170);
        pdf.text(splitText, 30, 75);

        // DATA DA VISTORIA
        pdf.text(`Data da Vistoria: ${format(parseISO(inspeccao?.fim), 'dd-MM-yyyy HH:mm:ss')}`, 185, 120, null, null, 'right');


        // INSPECTORS ACTUANTES
        pdf.setFont('helvetica', 'bold');
        pdf.text("Inspectores atuantes:", 25, 130);
        pdf.setFont('helvetica', 'normal');
        let novaLinha = 0;
        inspeccao.inspectores.forEach(inspector => {
            pdf.text(inspector.dadosPessoais.nome, 30, 135 + novaLinha);
            novaLinha += 5;
        });
        inspeccao.outrosInspectores.forEach(inspector => {
            pdf.text(inspector.nome, 30, 135 + novaLinha);
            novaLinha += 5;
        });

        // DATA 
        pdf.text(`Data: ${format(parseISO(inspeccao?.fim), 'dd-MM-yyyy HH:mm:ss')}`, 185, 200, null, null, 'right');

        // ASSINATURA
        pdf.setDrawColor(0, 0, 0); // cor preta
        pdf.setLineWidth(0.1); // largura da linha
        pdf.line(60, 225, 150, 225);
        pdf.text(inspeccao.inspectores[0].dadosPessoais.nome, 105, 224, null, null, 'center');
        pdf.text("Responsável pela inspecção", 105, 230, null, null, 'center');
        // pdf.text("Assinatura", 105, 230, null, null, 'center');

        // Adicionar rodapé
        addRodape()



        // // ANEXOS
        // ########################################################################################
        // PAGINA 5
        // pdf.addPage();
        // pdf.setFont('helvetica', 'bold');
        // pdf.text("ANEXOS", 25, 20);
        // pdf.setFont('helvetica', 'normal');
        // // pdf.addImage(inspeccao.fotos[2], 'JPEG', 25, 30, 100, 100);

        // // Adicionar rodapé
        // addRodape()

        // html2canvas(document.getElementById(1)).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     console.log(imgData)
        //     pdf.addImage(imgData, 'PNG', 25, 30, 100, 100);
        // });

        if (action === 'viewRelatorio') {
            // imprimir PDF
            const pdfBlob = pdf.output('blob');
            // Criar um URL para o Blob
            setViewRelatorio(URL.createObjectURL(pdfBlob))
            setLoad(false)
        }


        if (action === 'print') {
            // imprimir PDF
            const pdfBlob = pdf.output('blob');
            // Criar um URL para o Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Abrir o PDF em uma nova aba
            window.open(pdfUrl, '_blank');
            setLoad(false)
            setMessageSuccess('Sucesso')
        }

        if (action === 'save') {
            // Salvar PDF
            pdf.save(`inspeccao-${inspeccao?.estabelecimento.nome}.pdf`);
            setLoad(false)
            setMessageSuccess('Sucesso')
        }


        if (action === 'Enviando_email') {
            const pdfBlob = pdf.output('blob');
            // Criar um objeto FormData para enviar o Blob como um arquivo

            const msg = {
                to: novoEmail ?? inspeccao?.estabelecimento?.email,
                // from: process.env.SENDER_EMAIL!,
                subject: `Relatório de inspecção de estabelecimento`,
                html:
                    `<h1>Estabelecimento '${inspeccao?.estabelecimento?.nome}'</h1
            
                    <p><b>Nota:</b> Este e-mail foi gerado pela plataforma <b>ARMED</b></p>
                    `,
            };

            const formData = new FormData();
            formData.append('to', msg.to); //
            formData.append('subject', msg.subject); //
            formData.append('html', msg.html); //
            formData.append('attachment', pdfBlob, `inspeccao-${inspeccao?.estabelecimento?.nome}.pdf`); // O terceiro argumento é o nome do arquivo

            // for (let dado of formData.values()) { console.log(dado); }

            api.post('/email/send', formData)
                .then(response => {
                    // console.log(response)
                    setOpenReenviarEmail(false)
                    setLoad(false)
                    setMessageSuccess('E-mail enviado com Sucesso')
                }).catch(err => {
                    setOpenReenviarEmail(false)
                    // console.log(err) 
                    setLoad(false)
                })
        }
    };

    useEffect(() => {
        // GERAR RELATORIO PARA VISUALIZACAO
        inspeccao && generatePDF('viewRelatorio')
    }, [inspeccao]);

    return (
        <Card style={{ marginBottom: 10 }}>
            {inspeccao
                ?
                <>
                    <RegisterAccess page={'inspeccao realizada'} />
                    <LoadingBackdrop open={load} text={load} />
                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {/* {messageError && <MessageError message={messageError} />} */}

                    <ReenviarEmail
                        estabelecimento={inspeccao.estabelecimento}
                        setNovoEmail={setNovoEmail}
                        generatePDF={generatePDF}
                        openReenviarEmail={openReenviarEmail}
                        setOpenReenviarEmail={setOpenReenviarEmail}
                        load={load}
                    />
                    
                    <div style={{ padding: 20 }}>
                        <HeaderGestorConfigs
                            menu={true}
                            generatePDF={generatePDF}
                            setOpenReenviarEmail={setOpenReenviarEmail}
                            configArea={'inspeccao'}
                            title={`${numero ?? ''} INSPECÇÃO REALIZADA`}
                        />
                    </div>

                    <Typography marginLeft={4}>
                        Data: {inspeccao.dataAgendada?.dia} de <Mes mes={inspeccao.dataAgendada?.mes} /> as {inspeccao.dataAgendada?.hora < 10 && 0}{inspeccao.dataAgendada?.hora}:{inspeccao.dataAgendada?.minuto < 10 && 0}{inspeccao.dataAgendada?.minuto}
                        <br />
                        Tipo de Inspecção: {inspeccao?.tipo}
                        <br />
                        Estado: {inspeccao?.status}
                    </Typography>

                    <CardInspeccao setRefresh={setRefresh} thisInspeccao={inspeccao} generatePDF={generatePDF} viewRelatorio={viewRelatorio} />
                </>
                :
                <Loading />
            }
        </Card>
    );
}
