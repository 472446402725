import { useState } from 'react'
import { Button, TextField, MenuItem } from '@mui/material'
import LoadingBackdrop from '../../load/loadingBackdrop'
import MessageError from '../../messages/messageError'

import api from '../../services/api'
import { useSelector } from 'react-redux'
import MessageSuccess from '../../messages/messageSuccess'


export default function ChangeStatusAdmin({ admin, setHandleUpdate }) {
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [status, setStatus] = useState(admin?.status)
    const [load, setLoad] = useState(false)
    const user = useSelector(state => state.account.user);
    // console.log(admin)

    async function changeStatusAdmin() {
        setLoad(true)
        setErrorMessage('')
        setMessageSuccess('')

        await api.patch('/admin/funcionario/change-status', { status, id: admin._id, userId: user._id })
            .then(response => {
                // console.log(response)
                setLoad(false)
                setMessageSuccess(response.data.msg)
                setHandleUpdate(response.data)

            }).catch(error => {
                // console.log(error)
                setErrorMessage(error.response.data.message)
                setLoad(false)
            })
    }

    return (
        <>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && < MessageError message={messageError} />}
            <LoadingBackdrop open={load} text={status} />

            <div style={{ margin: 10 }}>
                <TextField
                    
                    type="text"
                    label="Status"
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => setStatus(e.target.value)}
                    defaultValue={admin?.status}
                >
                    <MenuItem key={'a'} value={'Activo'} name="status">
                        Activo
                    </MenuItem>
                    <MenuItem key={'i'} value={'Inactivo'} name="status">
                        Inactivo
                    </MenuItem>
                </TextField>

                {(status !== admin?.status) &&
                    <Button dis size='small' onClick={changeStatusAdmin}>
                        Salvar
                    </Button>
                }
            </div>
        </>
    )
}
