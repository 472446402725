import { useEffect, useState } from "react";
import InformacaoAdmin from "./InformacaoAdmin";
import api from "../../services/api";
import Loading from "../../load/loading";
import {
  Container,
  DialogActions,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";
import { RelatorioAdministadoresPdf } from "../exports/gerarelatoriopdf";
import { RelatorioAdministadoresExcel } from "../exports/gerarelatorioexcel";

export default function ListaFuncionarios() {
  const [admins, setAdmins] = useState([]);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [status, setStatus] = useState("");
  const [funccao, setFunccao] = useState("");
  const [funcoes, setFuncoes] = useState([]);

  useEffect(() => {
    api
      .get(`/admins?status=${status}&funcao=${funccao}`)
      .then((response) => {
        setAdmins(response.data.Admins);
        setTotalAdmins(response.data.totalItems);

        // Extrair funções únicas dos administradores
        const uniqueFuncoes = [
          ...new Set(response.data.Admins.map((admin) => admin.funcao)),
        ];
        setFuncoes(uniqueFuncoes);
      })
      .catch((err) => console.error("Erro ao buscar funcionários:", err));
  }, [status, funccao]); // Sempre que status ou funccao mudar, a busca será feita automaticamente

  return (
    <Container>
      <RegisterAccess page={"lista de funcionarios"} />

      <HeaderSession
        title={"USUARIOS ADMINISTRADORES DO SISTEMA"}
        actions={true}
        handleExportExcel={() => RelatorioAdministadoresExcel(admins)}
        handleExportPDF={() => RelatorioAdministadoresPdf(admins)}
      />

      {/* Filtros */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            select
            fullWidth
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Activo">Ativo</MenuItem>
            <MenuItem value="Inactivo">Inativo</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            select
            fullWidth
            label="Função"
            value={funccao}
            onChange={(e) => setFunccao(e.target.value)} // Apenas atualiza o estado
            variant="outlined"
          >
            <MenuItem value="">Todas as Funções</MenuItem>
            {funcoes.map((funcao) => (
              <MenuItem key={funcao} value={funcao}>
                {funcao}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {totalAdmins > 0 && (
          <DialogActions>
            Total: <b> {totalAdmins}</b>
          </DialogActions>
        )}
      </Grid>

      {admins.length > 0 ? (
        admins.map((admin) => <InformacaoAdmin key={admin._id} admin={admin} />)
      ) : (
        <Loading />
      )}
    </Container>
  );
}
