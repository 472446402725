import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function PontosEntrada({handleChange }) {

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="grouped-select">Pontos de Entrada</InputLabel>
            <Select
                // defaultValue="" 
                id="select"
                label="Ponto de Entrada"
                onChange={handleChange}
                name="pontoEntrada"
            >
                <MenuItem value=""></MenuItem>
                <ListSubheader>Portos Maritimos</ListSubheader>
                <MenuItem value={'Porto comercial de Luanda'}>Porto comercial de Luanda</MenuItem>
                <MenuItem value={'Porto comercial do Lobito'}>Porto comercial do Lobito</MenuItem>
                <MenuItem value={'Porto comercial de Cabinda'}>Porto comercial de Cabinda</MenuItem>
                <MenuItem value={'Porto comercial de Namibe'}>Porto comercial de Namibe</MenuItem>

                <ListSubheader>Aeroporto Internacional</ListSubheader>
                <MenuItem value={'4 de Fevereiro'}>4 de Fevereiro</MenuItem>
                <MenuItem value={'Dr.António Agostinho Neto'}>Dr. António Agostinho Neto</MenuItem>

                <ListSubheader>Correios/Encomendas postais</ListSubheader>
                <MenuItem value={'Correios da Marginal'}>Correios da Marginal</MenuItem>
                <MenuItem value={'Correios da Vila Alice'}>Correios da Vila Alice</MenuItem>
                <MenuItem value={'Correios Províncias'}>Correios Províncias</MenuItem>

                <ListSubheader>Pontos de Entrada Terrestre</ListSubheader>
                <MenuItem value={'Santa-Clara'}>Santa-Clara</MenuItem>
                <MenuItem value={'Luvo'}>Luvo</MenuItem>
                <MenuItem value={'Cabinda'}>Cabinda</MenuItem>
                <MenuItem value={'Moxico'}>Moxico</MenuItem>
                <MenuItem value={'Noki'}>Noki</MenuItem>
                <MenuItem value={'Catuito'}>Catuito</MenuItem>

            </Select>
        </FormControl>
    );
}

