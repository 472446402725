/// <reference no-default-lib="true"/>
/* eslint-disable */

import { useState } from "react";
import { Button, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import { BsFiletypeTxt } from "react-icons/bs";

export default function AssinaturaUser({ admin, setHandleUpdate }) {
  const [assinatura, setAssinatura] = useState("");
  const [open, setOpen] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");
  const user = useSelector((state) => state.account.user);

  const submitEdit = async () => {
    setOpen(true);
    setMessageSuccess("");
    setMessageError("");

    const formData = new FormData();
    formData.append("assinatura", assinatura);
    formData.append("dadosPessoaisId", admin?.dadosPessoais?._id ?? "");
    formData.append("userId", admin?._id ?? "");

    await api
      .patch("/admin/edit-assinatura", formData)
      .then((res) => {
        setAssinatura("");
        setOpen(false);
        setMessageSuccess(" Salvo com sucesso.");
        setHandleUpdate(res.data);
      })
      .catch((err) => {
        setOpen(false);
        setMessageError(err.response.data.message);
      });
  };

  return (
    <>
      {messageSuccess && <MessageSuccess message={messageSuccess} />}
      {messageError && <MessageError message={messageError} />}
      <Card
        style={{
          backgroundColor: "white",
          color: "#333",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          padding: "8px",
          width: "100%",
          fontFamily: "sans-serif",
          borderRadius: "8px",
          overflow: "hidden",
          margin: "16px auto",
        }}
      >
        <LoadingBackdrop open={open} text={"A salvar o Assinatura."} />

        <div
          style={{ padding: "0 16px", display: "flex", alignItems: "center" }}
        >
          <BsFiletypeTxt size={16} />
          <p style={{ fontSize: "14px", marginLeft: "8px" }}>
            {assinatura?.name || "Assinatura.png"}
          </p>
        </div>

        <label
          htmlFor={admin?._id}
          style={{
            backgroundColor: "#85287e",
            color: "white",
            fontSize: "14px",
            padding: "8px 12px",
            borderRadius: "6px",
            cursor: "pointer",
            marginLeft: "auto",
            display: "block",
          }}
        >
          Upload
        </label>

        <input
          style={{ display: "none" }}
          id={admin?._id}
          type="file"
          name={"assinatura"}
          onChange={(e) => setAssinatura(e.target.files[0])}
        />
      </Card>

      {assinatura && (
        <Button
          variant="outlined"
          color="success"
          onClick={submitEdit}
          size="small"
          style={{ marginTop: "20px" }}
        >
          Salvar
        </Button>
      )}
    </>
  );
}
