import pdfMake from "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";
//import { meses } from '@/app/services/dados';

function getBase64Image(img) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
function capitalizeWords(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
const PdfRelatorioGeral = async (dadosFiltrados) => {

    const provincia = dadosFiltrados[0]?.provincia ?? 'Luanda';
  // const imagemProvincia = await import(`/icones/mapaProvinciasAngola${provincia}.png`);

  
    const hoje = new Date();
    const anoAtual = hoje.getFullYear();
    const mesAtual = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(hoje);
    /** CONVERÇÃO DE IMAGEM PARA BASE64 **/
    const imageBlob = await fetch('/img/logo/InsigniaAngola.png').then(res => res.blob());
    const imageBase64 = await getBase64Image(imageBlob);
   const logoarmdurl = await fetch('/img/logo/logoArmed.png').then(res => res.blob());
   const logoarmd = await getBase64Image(logoarmdurl);
  const LuandaUrl = await fetch(`/icones/mapaProvinciasAngola/${provincia}.png`).then(res => res.blob());
  const LuandaPic = await getBase64Image(LuandaUrl);
    function gararTabela0() {
        const table = {
            headerRows: 2,
            // widths: ['auto', '*', '*', '*', '*', '*', 'auto', 'auto', 'auto'],
            widths: ['auto', 'auto', '*', '9%', '18%', '10%', '10%', '10%', '8%'],
            body: [
                [
                    { text: `RELATÓRIO DE Estabelecimetos`.toUpperCase(), fillColor: '#BDD6EE', style: 'header', colSpan: 8, color: '#fafafa', alignment: 'center', fontSize: 9, bold: true },
                    {}, {}, {}, {}, {}, {}, {}, {}
                ],
                [
                    { text: '#', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Nome', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Tipo', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Telefone', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Email', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Provincia', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Municipio', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Estado', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                    { text: 'Data', fillColor: '#528DD3', style: 'header', color: '#fafafa', alignment: 'center', fontSize: 7, bold: true },
                ],
                ...dadosFiltrados.map((dado, index) => [
                    { text: index + 1, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.nome, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: capitalizeWords(dado?.tipo?.designacao ?? '') , style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.empresa?.tel1, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.empresa?.email, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.provincia, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.municipio, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: dado?.status, style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                    { text: new Date(dado?.createdAt).getFullYear(), style: 'header', color: '#111', alignment: 'center', fontSize: 7 },
                ]),
                [
                    { text: 'Total de Estabelecimetos', fillColor: '#BDD6EE', style: 'header', color: '#111', alignment: 'left', fontSize: 7, bold: true, colSpan: 8, },
                    {}, {}, {}, {}, {}, {}, {},
                    { text: dadosFiltrados.length.toString() ??'', fillColor: '#fafafa', style: 'header', color: '#111', alignment: 'center', fontSize: 7, bold: true },
                ],
            ],
        };
        return table;
    }
    function gararTabela1() {
        const usuariosCadastrados = dadosFiltrados.filter((estabelecimento) => {
            const dataEstabelcecimento = estabelecimento?.tipo?.designacao;
            return dataEstabelcecimento === estabelecimento?.tipo?.designacao;;
        }).reduce((acc, estabelecimento) => {
            if (estabelecimento?.tipo?.designacao ??'' != '') {
                const tipo = estabelecimento?.tipo?.designacao;
                /*  if (!acc[tipo]) {
                        acc[tipo] = {};
                    } */
                if (!acc[tipo]) {
                    acc[tipo] = 1;
                } else {
                    acc[tipo]++;
                }
            }
            return acc;
        }, {});
        //    console.log(usuariosCadastrados);

        const repartir = Object.entries(usuariosCadastrados).map(([key, value]) => {
            // console.log(value);
            return { tipo: key, quantidade: value };
        })
        // console.log(repartir);

        const columns = [
            {
                width: '50%',
                stack: [
                    {
                        image: LuandaPic,
                        fit: [200, 200]
                    },
                  // { text: `${dadosFiltrados[0]?.provincia}`.toUpperCase(), fontSize: 20, bold: true, color: '#fafafa', alignment: 'center', margin: [-65, -55, 0, 0] },
                ],
                fontSize: 15
            },
            {
                width: '50%',
              //  margin: [0, 30, 0, 30],
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto'],
                    body: [
                        [{ text: ` ${dadosFiltrados[0]?.provincia}`.toUpperCase(), fontSize: 25, bold: true, colSpan: 2 }, {}],
                        //repartir.map((item) => [item?.tipo, item?.quantidade]),
                        ...Object.entries(repartir).map(([key, value]) => [value?.tipo, value?.quantidade]),
                        [{ text: 'TOTAL', fontSize: 10, bold: true, alignment: 'right' }, repartir.reduce((acc, item) => acc + (item.quantidade), 0)],
                    ]
                },
                layout: 'lightHorizontalLines'
            },
        ];
        return columns;
    }

    const docDefinition = {
        /** COFIGURAÇÃO INICIAL DE DOCUMENTOS **/
        pageSize: 'A4',
        // pageOrientation: 'landscape',
        pageMargins: [30, 100, 50, 30],
        /** CONTEUDO PRINCIPAL**/
        content: [
            { canvas: [{ type: 'rect', x: 100, y: -60, w: 300, h: 60, lineWidth: 3, lineColor: '#fafafa', color: '#fafafa' },] },
            /** PAGINA 1 **/
            { image: imageBase64, width: 55, height: 60, alignment: 'center', margin: [0, -70, 0, 0] },
            /** TITULO E SUBTITULO **/
            { text: 'REPÚBLICA DE ANGOLA', style: 'header', },
            { text: 'MINISTÉRIO DA SAÚDE', style: 'header', },
            { text: 'AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE', style: 'header', },
            { text: `TITULO`, color: '#5f2565', fontSize: 25, margin: [50, 200, 0, 0] },
            { text: `RELATÓRIO DE ACTIVIDADES \n(${mesAtual.toUpperCase()} DE ${anoAtual})`, fontSize: 30, bold: true, margin: [50, 0, 0, 0] },
            { canvas: [{ type: 'rect', x: 50, y: 25, w: 380, h: 1, lineWidth: 1, lineColor: '#5f2565', color: '#5f2565' },] },
            { image: logoarmd, width: 130, height: 50, alignment: 'justify', margin: [50, 10, 0, 0] },
            { text: `${anoAtual}`, fontSize: 50, bold: true, alignment: 'justify', color: '#5f2565', margin: [50, 0, 0, 0] },
            //  { text: `${mesAtual.toUpperCase()} ${anoAtual}`, alignment: 'center', fontSize: 8, margin: [0, 200, 0, 0] },
            { text: '', pageBreak: 'after' },
            { text: '', pageOrientation: 'portrait', pageBreak: 'after' },
            /** TABELA Nº 1. DOCUMENTOS EMITIDOS NO PERÍODO EM REFERÊNCIA **/
            { table: gararTabela0() },
            { text: '', fontSize: 20, margin: [0, 30, 0, 50] },
          //  { text: '', pageBreak: 'before' },
          //  { canvas: [{ type: 'rect', x: 100, y: 0, w: 300, h: 10, lineWidth: 1, lineColor: '#fafafa', color: '#fafafa' },] },
            { columns: gararTabela1(), pageBreak: 'before' },
        ],

        footer: function (currentPage, pageCount, pageSize) {
            if (currentPage > 1) {
                return [
                    { text: `Página ${currentPage}`, alignment: (currentPage % 2) ? 'left' : 'right', margin: [10, 0, 10, 0] },
                    { canvas: [{ type: 'rect', x: (currentPage % 2) ? 100 : -100, y: 2, w: pageSize.width - 1, h: 1, lineColor: '#5f2565', }] },
                ]
            }
        },
        header: function (currentPage, pageCount, pageSize) {
            if (currentPage >= 2) {
                return [
                    { qr: 'https://www.armed.gov.ao/', fit: '50', alignment: (currentPage % 2) ? 'left' : 'right', margin: [10, 5, 10, 5] },
                    //{ text: 'Cabecalho', alignment: (currentPage % 2) ? 'left' : 'right' },
                    { canvas: [{ type: 'rect', x: (currentPage % 2) ? 100 : -100, y: -20, w: pageSize.width - 1, h: 1, lineColor: '#5f2565', }] }

                ]
            }
        },
        background: function (currentPage, pageSize, pageCount) {
            if (currentPage === 1) {
                return [
                    { canvas: [{ type: 'rect', x: 40, y: 60, w: pageSize.width - 80, h: pageSize.height - 100, lineWidth: 1, lineColor: '#5f2565' }] },
                ];
            }
        },
        /** ESTILOS DE DOCUMENTO **/
        styles: {
            header: {
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 0],
                fontSize: 7,
            },
        },
        defaultStyle: {
            fontSize: 9,
        }
    };
    //pdfMake.createPdf(docDefinition as any).open();
    const pdfGerarAquivo = pdfMake.createPdf(docDefinition);
    return pdfGerarAquivo;
    
}

export { PdfRelatorioGeral };