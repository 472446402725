import { useEffect, useRef, useState } from 'react';
import { Typography, makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import ListaVazia from "../../../../../utils/listaVazia";
import { IconButton } from "@mui/material";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import Date from '../../../../../utils/date';


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' },
    /* Estilo para a div fullscreen */
    fullscreenDiv: {
        // display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        color: '#000',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        textAlign: 'center',
        transition: 'all 0.3s ease-in-out'
    },
    /* Quando ativa, a div aparece */
    fullscreenDivActive: {
        display: 'flex'
    },

}))

export default function ItensFactura({ itens }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const fullscreenDivRef = useRef(null); // Referência para a div que irá para fullscreen
    const [isFullscreen, setIsFullscreen] = useState(false); // Estado para controlar o modo tela cheia
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Função para entrar no modo fullscreen
    const openFullscreen = () => {
        if (fullscreenDivRef.current.requestFullscreen) {
            fullscreenDivRef.current.requestFullscreen();
        } else if (fullscreenDivRef.current.webkitRequestFullscreen) {
            // Suporte para Safari
            fullscreenDivRef.current.webkitRequestFullscreen();
        } else if (fullscreenDivRef.current.msRequestFullscreen) {
            // Suporte para IE11
            fullscreenDivRef.current.msRequestFullscreen();
        }
        setIsFullscreen(true); // Atualiza o estado
    };

    // Função para sair do modo fullscreen
    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setIsFullscreen(false); // Atualiza o estado
    };

    // Detecta a tecla Esc para sair do modo fullscreen
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isFullscreen) {
                closeFullscreen();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullscreen]); // Reexecuta o useEffect quando isFullscreen muda 

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer ref={fullscreenDivRef} className={`${isFullscreen ? classes.fullscreenDiv : ''}`}>

            {isFullscreen ?
                <small>
                    Sair do Modo Tela Cheia
                    <IconButton color='secondary' onClick={closeFullscreen}> <CloseFullscreenOutlinedIcon fontSize='small' /> </IconButton>
                </small>
                :
                <small>
                    Abrir em Tela Cheia
                    <IconButton color='secondary' onClick={openFullscreen}> <OpenInFullOutlinedIcon fontSize='small' /> </IconButton>
                </small>
            }

            {itens && (
                <>
                    <Table id="myTable" size="small" aria-label="sticky table">
                        <TableHead >
                            <TableRow>
                                <TableCell ><Typography noWrap> <strong>Forma Farmaceutica | Nome</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Teor Base (G)	</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Quantidade</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Quantidade Importada</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Diferença</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Moeda</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Valor Unitário	</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Valor Total	</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Valor Total em Kz</strong></Typography></TableCell>
                                {/* <TableCell ><Typography noWrap> <strong>Ponto de Entrada</strong></Typography></TableCell> */}
                                <TableCell ><Typography noWrap> <strong>Data de Emissão</strong></Typography></TableCell>
                                <TableCell ><Typography noWrap> <strong>Data de Validade</strong></Typography></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {itens
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => (
                                    <TableRow key={item?._id} className={classes.hover} onClick={() => navigate(``)} title={item?.nome}>
                                        <TableCell>{item?.formaFarmaceutica}</TableCell>
                                        <TableCell>{item?.teorBaseG}</TableCell>
                                        <TableCell>{item?.quantidade}</TableCell>
                                        <TableCell>{item?.quantidadeImportada ?? '####'}</TableCell>
                                        <TableCell>{item?.diferenca ?? '####'}</TableCell>
                                        <TableCell>{item?.moeda ?? '####'}</TableCell>
                                        <TableCell>{item?.valorUnitario}</TableCell>
                                        <TableCell>{item?.valorTotal}</TableCell>
                                        <TableCell>{item?.valorKZ}</TableCell>
                                        {/* <TableCell >{item?.pontoEntrada}</TableCell> */}
                                        <TableCell><Date date={item?.dataEmissao} /></TableCell>
                                        <TableCell><Date date={item?.dataValidade} /></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={itens.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}

            {itens.length < 1 && <ListaVazia text={'NENHUM ITEM ADICIONADO'} />}
        </TableContainer>
    )
}
