/// <reference no-default-lib="true"/>
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useDebounce from "./useDebounce";

interface ConfigLista {
  nextPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalItems: number;
}

interface FerramentaNavegacaoExportacaoProps {
  lista: any[];
  configLista: ConfigLista;
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setOrder: (order: "ASC" | "DESC") => void;
  FerramentaNavegacaoExportacao?: boolean;
  urlLista?: string;
}

export default function FerramentaNavegacaoExportacao(props: FerramentaNavegacaoExportacaoProps) {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, 3000);

  useEffect(() => {
    if (debouncedValue) {
      props.setPerPage(Number(debouncedValue));
    }
  }, [debouncedValue, props]);

  const handleChangePageNext = () => {
    props.setPage(props.configLista.nextPage);
  };

  const handleChangePagePreviou = () => {
    props.setPage(props.configLista.nextPage - 2);
  };

  return (
    <div style={{ padding: 20 }}>
      <br />
      Visto <strong>{props.lista.length * props.page} </strong> de <strong>{props.configLista.totalItems}</strong> no total


      <IconButton component="button" disabled={!props?.configLista?.hasPreviousPage} type="submit" onClick={handleChangePagePreviou}>
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton component="button" disabled={!props?.configLista?.hasNextPage} type="submit" onClick={handleChangePageNext}>
        <NavigateNextIcon />
      </IconButton>
      <input
        style={{ padding: 3, width: 100, margin: 5 }}
        placeholder='Itens'
        defaultValue={props.perPage}
        type="number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      />


      {props.FerramentaNavegacaoExportacao && (
        <>
          <IconButton component="button" disabled type="submit" onClick={() => navigate(`/admin/${props.urlLista}`)}>
            <PrintIcon color="action" titleAccess="Imprimir PDF" />
          </IconButton>
        </>
      )}
    </div>
  );
}