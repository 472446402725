import { Button, Card, Checkbox, FormControlLabel, Typography } from '@mui/material'
import HeaderGestorConfigs from '../../../../utils/headerGestorConfigs'
import StatusEstabelecimento from '../../../../utils/statusEstabelecimento'
import DateHora from '../../../../utils/dateHora'
import ButtonLeft from "../../../../utils/buttonLeft";
import FazerObservacoes from "../../dlif/fazerObservacoes.";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from '../../../../load/loadingBackdrop';
import * as yup from 'yup'; // VALIDACAO
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
// import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

export default function FichaSolicitacaoDesalfandegamento({ solicitacaoImportacao, setRefresh, admin = false }) {
    const user = useSelector(state => state.account.user);
    // const { id } = useParams()
    const dispatch = useDispatch()
    const [action, setAction] = useState('approve')
    const [obs, setObs] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const [confirmProcesso, setConfirmProcesso] = useState(false)

    const handleChangeStatus = () => {
        setLoad(true)
        api.patch('/declaracao-desalfandegamento/change-status', {
            // status: status,
            status: 'Reprovado',
            declaracaoDesalfandegamentoId: solicitacaoImportacao.declaracao._id,
            userId: user?._id
        }).then(response => {
            setRefresh(response.data)
            dispatch({
                type: 'alert',
                payload: { alert: 'Solicitação reprovada com sucesso.!' },
            });
            // console.log(response)
        }).catch(error => {
            // console.log(error)
            setMessageError(error.response.data.message)
        }).finally(() => setLoad(false))
    }

    const handleApprove = (assunto) => {
        setLoad(true)
        api.patch('/declaracao-desalfandegamento/approve', {
            declaracaoDesalfandegamentoId: solicitacaoImportacao.declaracao._id,
            userId: user?._id
        }).then(response => {
            // console.log(response);
            HandleCreateobservacao(assunto);
            setRefresh(response.data);
            dispatch({
                type: 'alert',
                payload: { alert: 'Solicitação aprovada com sucesso.!' },
            });
        }).catch(error => {
            // console.log(error);
            setMessageError(error.response.data.message)
        }).finally(() => setLoad(false))
    }

    async function HandleCreateobservacao(assunto) {
        setLoad(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: assunto,
            descricao: obs,
            userId: user._id,
            estabelecimentoId: solicitacaoImportacao.importador._id
        }).then(res => {
            setLoad(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setLoad(false)
        })
    }

    async function validate() {
        setMessageError('');
        setAction('reprove')

        let schema = yup.object().shape({
            observacao: yup.string("Necessário inserir as observações").required("Necessário inserir observações"),
        })

        try {
            await schema.validate({ 'observacao': obs })
            handleChangeStatus()
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        <>
            <LoadingBackdrop open={load} text={'Estás aprovar uma solicitacao de importação, aguarde.! '} />

            <Card style={{ padding: 20, position: 'relative' }}>
                {messageSuccess && <MessageSuccess message={messageSuccess} />}
                {messageError && <MessageError message={messageError} />}

                <HeaderGestorConfigs
                    menu={false}
                    configArea={''}
                    title={'SOLICITAÇÃO DE DESALFANDEGAMENTO'}
                />

                {(solicitacaoImportacao?.declaracao.status === 'Aprovado' && solicitacaoImportacao?.declaracao.dataAprovacao) &&
                    <img src="/img/icones/licenciamentoicon.svg" alt="autor.." width={35} style={{ position: 'absolute', right: 25 }} />
                }
                <StatusEstabelecimento status={solicitacaoImportacao?.declaracao.status} />
                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Declaração:</strong> {solicitacaoImportacao?.declaracao.numeroDeclaracao ?? '####'}</Typography>
                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nº de Porte:</strong> {solicitacaoImportacao?.declaracao.numeroPorte ?? '####'}</Typography>
                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Ponto de Entrada:</strong> {solicitacaoImportacao?.declaracao.pontoEntrada ?? '####'} </Typography>
                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Destino:</strong> {solicitacaoImportacao?.declaracao.destino ?? '####'}</Typography>
                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Data de Solicitação:</strong> <DateHora date={solicitacaoImportacao.declaracao.createdAt} /></Typography>

                {(solicitacaoImportacao?.declaracao.status !== 'Aprovado' && !solicitacaoImportacao?.declaracao.dataAprovacao && admin) &&
                    <>
                        <FazerObservacoes setObs={setObs} obs={obs} titulo={action === 'approve' ? '(caso seja necessário fazer alguma observação)' : '(Descreve os motivos desta reprovação para o utente)'} />
                        <Typography my={2}>
                            <b> O processo está conformidade? </b>
                            <FormControlLabel value={!confirmProcesso} name='tipoAutorizacao' control={<Checkbox checked={confirmProcesso} />} onChange={() => setConfirmProcesso(!confirmProcesso)} />
                        </Typography>
                        <ButtonLeft title={'Aprovar'} funcao={() => handleApprove('Aprovado')} disabled={false} >
                            <Button color="error" onClick={validate}>Reprovar</Button>
                        </ButtonLeft>
                    </>
                }
            </Card>
        </>
    )
}
