import { useState } from "react";
import { Button, CircularProgress, Snackbar, Alert, Portal } from "@mui/material";
import api from "../../services/api";

const ChangeStatusEstabelecimento = ({ estabelecimentoId, onStatusChange, currentStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);

  const toggleStatus = async () => {
    const newStatus = currentStatus === "Activo" ? "Inactivo" : "Activo";
    setLoading(true);
    setError(null);

    try {
      await api.patch("/estabelecimento/change-status", {
        status: newStatus,
        estabelecimentoId,
      });
      onStatusChange(newStatus);
      setSuccessMessage(true); // Exibe mensagem de sucesso
    } catch (error) {
      console.error("Erro ao alterar status", error);
      setError("Erro ao alterar status. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{width:"100%"}}>
      <Button
        variant="outlined"
        style={{width:"100%"}}
        color={currentStatus === "Activo" ? "error" : "success"}
        onClick={toggleStatus}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : `Mudar para ${currentStatus === "Activo" ? "Inactivo" : "Activo"}`}
      </Button>
      <Portal>
        <Snackbar open={successMessage} autoHideDuration={3000} onClose={() => setSuccessMessage(false)}>
          <Alert severity="success">Status alterado com sucesso!</Alert>
        </Snackbar>
      </Portal>
      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
};

export default ChangeStatusEstabelecimento;