import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavLateral from "./navLateral";
import HeaderSession from "../../../../utils/headerSession";
import { Card, Container, Typography } from "@mui/material";
import api from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import RegisterAccess from "../../../../utils/registerAccess";
import AlertEdit from "../../../../utils/alertEdit";
import TextoFormatado from "../../../../utils/textoFormatado";

const useStyles = makeStyles((theme) => ({
  grid1: {
    width: 300,
    marginBottom: 15,
    marginLeft: "auto",
    marginRight: "auto",

    [theme.breakpoints.up("md")]: {
      // width: '100%',
      height: "100%",
      position: "sticky",
      top: 70,
    },
  },
  grid2: {
    flexGrow: 1,
    marginLeft: 15,
    // minHeight: '100vh',
    width: 500,
    zIndex: 1,
    // position: 'relative',
  },
}));

export default function GestPedidosLicenciamento({
  element: component,
  ...rest
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [estabelecimento, setEstabelecimento] = useState("");
  const [solicitacaoRenovacao, setSolicitacaoRenovacao] = useState("");
  const [observacoesFeitas, setobservacoesFeitas] = useState("");

  useEffect(() => {
    setEstabelecimento("");

    api
      .get(`/estabelecimento/${id}`)
      .then((response) => {
        // console.log(response)
        if (
          response.data.estabelecimento.approved === true &&
          response.data.estabelecimento.status === "Activo"
        ) {
          navigate(`/admin/gestor-licenciados/${id}`);
        }
        setEstabelecimento(response.data.estabelecimento);
        setSolicitacaoRenovacao(response.data?.solicitacaoRenovacaos);
      })
      .catch((err) => "");

    api
      .get(`/observacaos?estabelecimentoId=${id}`)
      .then((response) => {
        // console.log(response)
        setobservacoesFeitas(response.data.observacaos);
      })
      .catch((err) => "");
  }, [id]);

  return (
    <Container>
      <RegisterAccess page={"analise de processo"} />
      <HeaderSession title={"ANÁLISE DE PROCESSO "} />

      {estabelecimento && observacoesFeitas ? (
        <>
          {estabelecimento?.statusInspeccao === "Reprovada" && (
            <AlertEdit
              texto={
                "Este processo foi reprovado porque o estabelecimento mostoru dificiências no acto a inspecção. O estabelecimento esta a se organizar para solicitar uma nova inspecção."
              }
            />
          )}

          {estabelecimento?.statusInspeccao &&
            estabelecimento?.statusInspeccao !== "Reprovada" &&
            estabelecimento.status === "Analisado Chefe de Secção" && (
              <Card style={{ padding: 20, marginBottom: 10 }}>
                <Typography>O estabelecimento informou o seguinte:</Typography>
                <TextoFormatado texto={estabelecimento.statusInspeccao} />
              </Card>
            )}

          <Grid container>
            <Grid item className={classes.grid1}>
              <NavLateral
                observacoesFeitas={observacoesFeitas}
                estabelecimento={estabelecimento}
              />
            </Grid>

            <Grid xs item className={classes.grid2}>
              {React.cloneElement(component, {
                estabelecimento: estabelecimento,
                solicitacaoRenovacao: solicitacaoRenovacao,
              })}
            </Grid>
          </Grid>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}
