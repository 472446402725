import api from './api';

class AuthService {

    signIn = (login) => {

        return new Promise((resolve, reject) => {
            api.post('/auth/login-admin', login)
                .then((response) => {
                    // console.log(response)
                    if (response.data.user) {
                        // localStorage.setItem("user", JSON.stringify(response.data.user))
                        localStorage.setItem("tipo", JSON.stringify(response.data.user?.isAdmin))
                        // guardar o departamento 
                        localStorage.setItem("dep", JSON.stringify(response.data.user?.departamento))
                        this.setToken(response.data.token);
                        resolve(response.data.user);
                    } else {

                        reject(response.data.error);
                    }
                })
                .catch((error) => { reject(error); });
        });
    };

    signInMembro = (login) => {

        return new Promise((resolve, reject) => {
            api.post('/auth/login-membro', login)
                .then((response) => {
                    if (response.data.user) {
                        localStorage.setItem("tipo", JSON.stringify(response.data.user.tipo))
                        localStorage.setItem("user", JSON.stringify(response.data.user))
                        // localStorage.setItem("membro", JSON.stringify(response.data))
                        this.setToken(response.data.token);
                        resolve(response.data.user);
                    } else {

                        reject(response.data.error);
                    }
                })
                .catch((error) => { reject(error); });
        });
    };

    signInWithToken = (dispatch) => {
        return new Promise((resolve, reject) => {
            api.post('/auth/is-logged-admin', { 'token': this.getToken() }) // envio o token pelo body
                .then((response) => {
                    // console.log(response)
                    if (response.data.user) {
                        resolve(response.data.user);
                        localStorage.setItem("dep", JSON.stringify(response.data.user?.departamento))
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    // console.log(error)
                    this.signOut()
                    // reject(error);
                    // reject('');

                    dispatch({
                        type: 'alert',
                        payload: { alert: 'Sessão expirada, faça login novamente!' },
                    });
                });
        });
    };

    signInWithTokenMembro = () => {
        return new Promise((resolve, reject) => {
            api.post('/auth/is-logged-membro', { 'token': this.getToken() }) // envio o token pelo body
                .then((response) => {
                    if (response.data.user) {
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    // reject(error.data.error);
                    // reject('');
                });
        });
    };

    signOut = () => {
        this.removeUser();
        this.removeMembro();
        this.removeToken();
        this.removeTipo();
    };

    setToken = (token) => { localStorage.setItem('accessToken', token); };
    getToken = () => localStorage.getItem('accessToken');
    removeToken = () => localStorage.removeItem('accessToken');
    removeUser = () => localStorage.removeItem('user');
    removeMembro = () => localStorage.removeItem('membro');
    removeTipo = () => localStorage.removeItem('tipo');
    isAuthenticated = () => !!this.getToken();
}

const authService = new AuthService();

export default authService;
