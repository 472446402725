import { Avatar, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
// import DateHora from '../../utils/dateHora'
import { useEffect, useState } from 'react'
import DateHora from '../../../utils/dateHora'
import api from '../../../services/api'
import Loading from '../../../load/loading'
import RegisterAccess from '../../../utils/registerAccess'
import HeaderSession from '../../../utils/headerSession'

export default function HistoricosLogin() {

    const [historicosLogin, setHistoricosLogin] = useState('')

    useEffect(() => {
        api.get('/auth/historico-logins')
            .then(response => {
                setHistoricosLogin(response.data.historicoLogins)
            }).catch(err => '')
    }, [])

    return (
        <div>
            <RegisterAccess page={'historicos de login no sistema'} />
            <HeaderSession title={'HISTÓRICOS DE LOGIN NO SISTEMA'} />

            <Card style={{ padding: 20, marginTop: 20 }}>

                {historicosLogin ?
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">

                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <strong>Admin</strong></TableCell>
                                    <TableCell align="center"> <strong>Função</strong></TableCell>
                                    <TableCell align="center"> <strong>Ação</strong></TableCell>
                                    <TableCell align="center"> <strong>Browser</strong></TableCell>
                                    <TableCell align="center"> <strong>Plataforma</strong></TableCell>
                                    <TableCell align="center"> <strong>SO</strong></TableCell>
                                    <TableCell align="center"> <strong>Dispositivo</strong></TableCell>
                                    <TableCell align="center"> <strong>Data</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {historicosLogin.map((historicoLigin) => (
                                    <TableRow key={historicoLigin?._id} >
                                        <TableCell align="center" component="th" scope="row">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar src={historicoLigin?.user?.dadosPessoais?.fotoURL} />
                                                <Typography variant="body1" style={{marginLeft:'15px'}}>
                                                    {historicoLigin?.user?.dadosPessoais?.nome}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell  ><Typography noWrap> {historicoLigin?.user?.funcao}</Typography></TableCell>
                                        <TableCell  ><Typography noWrap>{historicoLigin?.action}</Typography></TableCell>
                                        {/* <TableCell  ><Typography noWrap>{historicoLigin?.modelName}</Typography></TableCell> */}
                                        <TableCell  ><Typography noWrap>{historicoLigin?.device?.browser}</Typography></TableCell>
                                        <TableCell  ><Typography noWrap>{historicoLigin?.device?.platform}</Typography></TableCell>
                                        <TableCell  ><Typography noWrap>{historicoLigin?.device?.os}</Typography></TableCell>
                                        <TableCell  ><Typography noWrap>{historicoLigin?.device?.isDesktop && 'Computador'} {historicoLigin?.device?.isMobile && 'Mobile'}</Typography></TableCell>
                                        <TableCell  ><Typography noWrap><DateHora date={historicoLigin?.updatedAt} /></Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        {/* <FerramentaNavegacaoExportacao
                configLista={configLista}
                lista={historicosLogin}
                setPage={setPage}
                setPerPage={setPerPage}
                page={page}
            /> */}
                    </TableContainer>
                    :
                    <Loading text={"Buscando historico de login"} />
                }
            </Card>
        </div>

    )
}
