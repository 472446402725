
import { useState } from "react";
import { makeStyles } from "@material-ui/core"
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { Card, Container, Grid, Typography, TextField } from "@mui/material";
import { CountryDropdown } from 'react-country-region-selector';
import { useNavigate, useParams } from "react-router-dom";
import InputDocUpload from "../../../../../utils/inputFileUpload/inputDocUpload";
import * as yup from 'yup'; // VALIDACAO
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    inputPais: {
        padding: 15,
        borderRadius: 4,
        borderColor: '#e1e1e1',
        width: '100%',
        // border: 'dashed',
    },
}))

export default function FormSoliciteImportacao() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { tipo } = useParams()
    const classes = useStyles()
    const [solicitacao, setSolicitacao] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)

    async function HandleChangeFile(file, name) {
        setSolicitacao((prevCadastro) => ({ ...prevCadastro, [name]: file }))
    }

    function handleChange(e) {
        e.target.files ?
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
            :
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value })
    }

    async function createSolicitacaoImportacao() {
        const formData = new FormData();

        formData.append("tipoCertificado", tipo)
        formData.append("endereco", solicitacao?.endereco)
        formData.append("numFatura", solicitacao?.numFatura)
        formData.append("numFaturaPro", solicitacao?.numFaturaPro)
        formData.append("paisOrigem", solicitacao?.paisOrigem)
        formData.append("numeroAutorizacao", solicitacao?.numeroAutorizacao)
        // formData.append("importadorId", '6515659218f5cec218410fe4')

        // Docs
        formData.append("solicitacao", solicitacao?.solicitacao ?? '') // 1.	Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora
        formData.append("autorizacaoExportacao", solicitacao?.autorizacaoExportacao ?? '')  // 2.	Autorização de exportação emitida pelas entidades competentes do país de origem
        formData.append("parecerDNSP", solicitacao?.parecerDNSP ?? '') //3.	Parecer da Direcção Nacional de Saúde Pública para a importação de antituberculosos
        formData.append("parecerINLS", solicitacao?.parecerINLS ?? '') //4.	Parecer do Instituto Nacional de Luta contra a Sida para a importação de anti-retrovirais
        formData.append("copiaValidacao", solicitacao?.copiaValidacao ?? '') // 5.	Cópia da validação emitida pelo Instituto Nacional de Investigação em Saúde, para a importação de Testes de Diagnóstico Rápido para rastreio e diagnóstico de doenças infecciosas
        formData.append("cartaOriginal", solicitacao?.cartaOriginal ?? '') // 6.	Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes
        formData.append("faturaPro", solicitacao?.faturaPro ?? '') // 7.	Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola);
        formData.append("certificadoBPF", solicitacao?.certificadoBPF ?? '') // 8.	Certificado de Boas Práticas de Fabrico (GMP)
        formData.append("copiaFI", solicitacao?.copiaFI ?? '') // 9.	Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa
        formData.append("faturaEmolumento", solicitacao?.faturaEmolumento ?? '') // 10. Fatua de pagamento de emolumento Armed

        setOpen(true)
        setErrorMessage('')
        setMessage('')

        await api.post('/solicitacao-importacao/register', formData)
            .then(res => {
                setMessage(res.data.msg)
                dispatch({
                    type: 'alert',
                    payload: { alert: 'Solicitação foi enviada com sucesso.!' },
                });
                navigate(`/solicitar/importacao/add-item/${res.data.solicitacaoImportacao._id}`)
                // console.log(res)
            }).catch(error => {
                setErrorMessage(error.response.data.message)
                // console.log(error)
            }).finally(() => setOpen(false))
    }


    async function validate() {
        setErrorMessage('');
        let schema;

        if (tipo !== 'Medicamentos-controlados-nacionais' && tipo !== 'Medicamentos-controlados-internacionalmente') {
            schema = yup.object().shape({
                autorizacaoExportacao: yup.string("Necessário fazer o upload da Autorização de exportação emitida pelas entidades competentes do país de origem")
                    .required("Necessário fazer o upload da Autorização de exportação emitida pelas entidades competentes do país de origem"),
            })
        }

        if (tipo === 'Medicamentos-controlados-nacionais' || tipo === 'Medicamentos-controlados-internacionalmente') {
            schema = yup.object().shape({
                // cartaOriginal: yup.string("Necessário fazer o upload da Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes")
                // .required("Necessário fazer o upload da Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes"),

                certificadoBPF: yup.string("Necessário fazer o upload do Certificado de Boas Práticas de Fabrico (GMP)")
                    .required("Necessário fazer o upload do Certificado de Boas Práticas de Fabrico (GMP)"),

                copiaFI: yup.string("Necessário fazer o upload da Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa")
                    .required("Necessário fazer o upload da Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa"),
            })
        }

        schema = yup.object().shape({
            faturaPro: yup.string("Necessário fazer o upload da Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)").required("Necessário fazer o upload da Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)"),
            faturaEmolumento: yup.string("Necessário fazer o upload da fatura de pagamento de emolumento Armed").required("Necessário fazer o upload da fatura de pagamento de emolumento Armed"),
            solicitacao: yup.string("Necessário fazer o upload da solicitação de importação dirigida à Armed").required("Necessário fazer o upload da solicitação de importação dirigida à Armed"),

            numFaturaPro: yup.string("Necessário inserir o número da factura proforma").required("Necessário inserir o número da factura proforma"),
            numFatura: yup.string("Necessário inserir o número de factura emitida pela Armed").required("Necessário inserir o número de factura emitida pela Armed"),
            endereco: yup.string("Necessário inserir o endereço da compra").required("Necessário inserir o endereço da compra"),
            paisOrigem: yup.string("Necessário inserir o pais da compra").required("Necessário inserir o pais da compra"),
            numeroAutorizacao: yup.string("Necessário inserir número de autorização do importador").required("Necessário inserir o número de autorização do importador"),
            // tipoCertificado: yup.string("Necessário selecionar o tipo de certificado").required("Necessário selecionar o tipo de certificado"),
        })

        try {
            await schema.validate(solicitacao);
            createSolicitacaoImportacao();
        } catch (error) {
            setErrorMessage(error.errors);
        }
        return false
    }


    return (
        <div>
            <RegisterAccess page={'solicitação de importação'} />
            <LoadingBackdrop open={open} text={'Enviando solicitação.!'} />

            <Container style={{ minHeight: '70vh' }}>
                <HeaderSession title={`SOLICITAÇÃO DE IMPORTAÇÃO | ${tipo}`} />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}

                <Card style={{ padding: 10 }}>

                    <Grid container>
                        <Grid xs={12} md item m >
                            <TextField
                                disabled
                                type="text"
                                label="Certificado para:"
                                fullWidth
                                name="tipoCertificado"
                                variant="outlined"
                                defaultValue={tipo}
                            // defaultValue={tipo === 'produto-nao-controlado' ? 'Produtos não Controlados' : 'Produtos Controlados'}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº de autorização do Importador"
                                fullWidth
                                name="numeroAutorizacao"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº da Factura (Emolumento Armed)"
                                fullWidth
                                name="numFatura"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item m />

                    </Grid>

                    <hr style={{ border: '1px solid #c43ab9', marginTop: 10 }} />
                    <Typography align="center" m={2} color={'secondary'}>DADOS DA ORIGEM</Typography>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <CountryDropdown
                                style={{
                                    color: "gray",
                                    border: '1px solid gray-100',
                                    backgroundColor: "transparent",
                                    height: "100%",
                                }}
                                className={classes.inputPais}
                                value={solicitacao?.paisOrigem ?? ""}
                                onChange={(pais) =>
                                    setSolicitacao({ ...solicitacao, paisOrigem: pais })
                                }
                                defaultOptionLabel="Pais de Origem"
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Endereço"
                                fullWidth
                                name="endereco"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº da Fatura Proforma"
                                fullWidth
                                name="numFaturaPro"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid xs={12} md item m />
                    </Grid>


                    {/* ANEXAR DOCUMENTOS */}
                    <Typography align="center" m={2} color={'secondary'}>ANEXAR DOCUMENTOS </Typography>


                    {(tipo === 'Medicamentos-controlados-nacionais' || tipo === 'Medicamentos-controlados-internacionalmente') ?
                        <>
                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'solicitacao'}
                                        inputDescription={'Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora'}
                                        inputTitle={'Solicitação de importação dirigida à ARMED'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'cartaOriginal'}
                                        inputDescription={'Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes'}
                                        inputTitle={'Carta original da unidade hospitalar dirigida ao importador (Se aplicavel)'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaPro'}
                                        inputDescription={'Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)'}
                                        inputTitle={'Factura Proforma dos produtos a serem importados'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'certificadoBPF'}
                                        inputDescription={'Certificado de Boas Práticas de Fabrico (GMP)'}
                                        inputTitle={'Certificado de Boas Práticas de Fabrico (GMP)'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'copiaFI'}
                                        inputDescription={'Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa'}
                                        inputTitle={'Cópia do Folheto Informativo'}
                                    />
                                </Grid>


                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaEmolumento'}
                                        inputDescription={'Factura de pagamento de emolumento na Armed'}
                                        inputTitle={'Factura de pagamento de emolumento na Armed'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'solicitacao'}
                                        inputDescription={'Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora'}
                                        inputTitle={'Solicitação de importação'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'autorizacaoExportacao'}
                                        inputDescription={'Autorização de exportação emitida pelas entidades competentes do país de origem'}
                                        inputTitle={'Autorização de exportação'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaPro'}
                                        inputDescription={'Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)'}
                                        inputTitle={'Factura Proforma dos produtos a serem importados'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaEmolumento'}
                                        inputDescription={'Factura de pagamento de emolumento na Armed'}
                                        inputTitle={'Factura de pagamento de emolumento na Armed'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }

                    <Typography marginTop={5} align="center" variant="subtitle2" >
                        Clica em seguinte para adicionar os itens da factura que pretende importar.
                    </Typography>

                    <br />
                    <ButtonLeft title={'Seguinte'} funcao={validate} disabled={!solicitacao} />
                </Card>

            </Container>
            <br />
        </div>
    );
}
