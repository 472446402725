import authService from '../services/authService';
export const LOGIN_SUCCESS = '@ACCOUNT/LOGIN_SUCCESS';
export const SILENT_LOGIN = '@ACCOUNT/SILENT_LOGIN';
export const SIGNOUT = '@ACCOUNT/SIGNOUT';
export const SIGNUP = '@ACCOUNT/SIGNUP';

const signIn = (login) => {
  return async (dispatch) => {
    const user = await authService.signIn(login);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });
    return user
  };
};

const signInMembro = (login) => {
  return async (dispatch) => {
    const user = await authService.signInMembro(login);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });
  };
};

const signUp = (fullName, email, password) => {
  return async (dispatch) => {
    const user = await authService.signUp(fullName, email, password);

    dispatch({
      type: SIGNUP,
      payload: {
        user,
      },
    });
  };
};

const setUserData = () => {
  return async (dispatch) => {
    const user = await authService.signInWithToken(dispatch);
    // console.log(user);
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
  };
};

const setMembroData = () => {
  return async (dispatch) => {
    const user = await authService.signInWithTokenMembro();
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
  };
};

const signOut = () => {
  return async (dispatch) => {
    await authService.signOut();
    dispatch({
      type: SIGNOUT,
    });
  };
};

export { signIn, signInMembro, signUp, setUserData, setMembroData, signOut };
