
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Container, Dialog, DialogActions, MenuItem, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import apiCNP from '../../../../services/apiCNP';
import api from '../../../../services/api';
import HeaderSession from '../../../../utils/headerSession';
import InputDocUpload from '../../../../utils/inputFileUpload/inputDocUpload';
import MessageSuccess from '../../../../messages/messageSuccess';
import MessageError from '../../../../messages/messageError';
import LoadingShort from '../../../../load/loadingShort';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
// import compressImage from '../../../../../../services/compressImage';


const useStyles = makeStyles({
    gridItem: { margin: 8 }
})

export default function CadastroImportador() {
    const classes = useStyles()
    const [importador, setImportador] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    // const user = useSelector(state => state.account.user);


    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            })
            .catch(err => '')

        importador?.provinciaId &&
            apiCNP.get(`/municipios-by-provincia/${importador?.provinciaId}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                })
                .catch(err => '')
    }, [importador?.provinciaId])


    async function HandleChangeFile(file, name) {
        setImportador((prevCadastro) => ({ ...prevCadastro, [name]: file }))
    }

    async function HandleChange(e) {
        // await compressImage(e.target.files[0])
        e.target.files ?
            setImportador((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.files[0] }))
            :
            setImportador((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.value }))

        if (e.target.name === 'provinciaId') {

            setImportador((prevCadastro) => ({ ...prevCadastro, municipio: '' }));
            await apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setImportador((prevCadastro) => ({ ...prevCadastro, provincia: res.data.provincia.designacao }));
                })
                .catch(err => '')
        }
    }

    async function createImportador() {

        const formData = new FormData();
        formData.append('foto', importador?.foto ?? '');
        formData.append('nome', importador?.nome ?? '');
        formData.append('numeroIdentificacao', importador?.numeroIdentificacao ?? '');
        formData.append('email', importador?.email ?? '');
        formData.append('tel1', importador?.tel1 ?? '');
        formData.append('provincia', importador?.provincia ?? '');
        formData.append('municipio', importador?.municipio ?? '');
        formData.append('bairro', importador?.bairro ?? '');
        formData.append('rua', importador?.rua ?? '');
        formData.append('copiaDI', importador?.documentoIdentificacao ?? '');

        setLoad(true)
        setErrorMessage('')
        setMessageSuccess('')

        await api.post('/importador/register', formData)
            .then(res => {
                // console.log(res)
                setMessageSuccess(res.data)

            }).catch(error => {
                // console.log(error)
                setErrorMessage(error.response.data.message)
            }).finally(() => setLoad(false))
    }

    return (
        <Container style={{ minHeight: '70vh' }}>

            <HeaderSession title={'SOLICITAR NÚMERO DE IMPORTADOR'} />

            {messageSuccess && <MessageSuccess message={messageSuccess.msg} />}
            {messageError && < MessageError message={messageError} />}
            {/* <LoadingBackdrop open={load} text={`A cadastrar ${' ' + dadosAdmin?.nome}`} /> */}

            {!messageSuccess &&
                <>
                    <Grid container alignItems='flex-end' >
                        <Grid xs={12} md={2} item className={classes.gridItem} >
                            <Paper style={{ border: '1px dashed #3e3d3f' }}>
                                <label htmlFor="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                    {importador?.foto ?
                                        <Avatar style={{ width: '100%', height: 145 }} variant="square" src={URL.createObjectURL(importador.logo)} title="Clique para carregar uma imagem" />
                                        :
                                        <div style={{ padding: 5 }}>
                                            <img style={{ height: 100 }} src='/img/avatar/avatarEstabelecimento.svg' title="Clique para carregar uma imagem" />
                                            <br />
                                            <small>Imagem</small>
                                        </div>
                                    }
                                    <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                                </label>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                type="text"
                                label="Nome "
                                fullWidth
                                size="small"
                                name="nome"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.nome}
                            />
                        </Grid>

                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                required
                                type="text"
                                label="Nº de identifição (ex: BI ou NIF)"
                                fullWidth
                                size="small"
                                name="numeroIdentificacao"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.numeroIdentificacao}
                            />
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                type="text"
                                label="Contacto "
                                fullWidth
                                size="small"
                                name="tel1"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.contacto}
                            />
                        </Grid>

                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                type="email"
                                label="E-mail "
                                fullWidth
                                size="small"
                                name="email"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.email}
                            />
                        </Grid>
                    </Grid>


                    <Grid container >
                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                required
                                type="text"
                                label="Províncía"
                                select
                                fullWidth
                                size="small"
                                name="provinciaId"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.provinciaId ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Províncía</MenuItem>
                                {provincias?.map((provincia) => (
                                    <MenuItem key={provincia._id} value={provincia._id}>
                                        {provincia.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                disabled={!importador?.provinciaId}
                                required
                                type="text"
                                label="Município"
                                select
                                fullWidth
                                size="small"
                                name="municipio"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.municipio ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Município</MenuItem>
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio.designacao}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                required
                                type="text"
                                label="Bairro"
                                fullWidth
                                size="small"
                                name="bairro"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.bairro}
                            />
                        </Grid>

                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                required
                                type="text"
                                label="Rua"
                                fullWidth
                                size="small"
                                name="rua"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={importador?.rua}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    {/* ANEXO DE DOCUMENTOS */}

                    <Grid container>
                        <Grid xs={12} md item className={classes.gridItem}>
                            <InputDocUpload onFileSelect={HandleChangeFile} inputName={'documentoIdentificacao'} inputTitle={'Cópia colorida do documento de identificação'} />
                        </Grid>
                    </Grid>

                    <DialogActions>
                        <Button disabled={!importador?.documentoIdentificacao || load} variant="contained" color='secondary' style={{ background: '#85287e' }} onClick={createImportador}>
                            {load ? <LoadingShort /> : 'Submeter'}
                        </Button>
                    </DialogActions>
                </>
            }

            {messageSuccess &&
                <Dialog open={true} fullWidth>
                    <Typography style={{ margin: 20 }} align="center">
                        <CheckCircleOutlineIcon fontSize="large" color="success" />
                        <br />
                        {messageSuccess.msg}
                    </Typography>

                    <Typography style={{ margin: 20, marginBottom: 30 }} >
                        Dados da Solicitação:
                        <br />
                        Importador: <strong>{messageSuccess.importador?.nome}</strong>
                        <br />
                        Número de Importador: <strong>{messageSuccess.importador?.numeroAutorizacao}</strong>
                        <br />
                        <br />
                        <strong>OBS:</strong> Foi enviado estes dados para o seu endereço de email.
                    </Typography>

                    <Button onClick={() => navigate('/inspeccao-regulatoria')}>Inspecção Regulatória</Button>
                </Dialog>
            }
        </Container>
    )
}
