import { Card, Grid, Typography } from "@mui/material";
import Loading from "../../load/loading";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import QRCode from "../../qrCode/qrCode";
import DadosPessoalEdit from "../dadosGenericos/dadosPessoalEdit";
import { useState } from "react";
import { useSelector } from "react-redux";
import printerService from "../../services/printer";
import FotoEdit from "../../utils/fotoEdit";
import ChangeStatusAdmin from "./changeStatusAdmin";
import AssinaturaUser from "./assinaturaUser";
import CarimboUser from "./carimboUser";
import EditeSenha from "./editeSenha";

export default function FichaAdmin({ admin, local, setHandleUpdate }) {
  const user = useSelector((state) => state.account.user);
  const [editDados, setEditDados] = useState(false);
  const [dadosPessoalAdmin, setDadosPessoalAdmin] = useState(admin);
  const [openEditFoto, setOpenEditFoto] = useState(false);
  const baseUrl = window.location.origin;

  const printQrCode = (qrCodeAdmin) => {
    var preview = document.getElementById(qrCodeAdmin);
    preview.style = "display:  ";
    printerService.printer(qrCodeAdmin);
    preview.style = "display: none";
  };

  return (
    <>
      {admin ? (
        <Card style={{ padding: 20, minHeight: "20vh" }}>
          <HeaderGestorConfigs
            menu={local === "perfilAdmin" ? true : false}
            configArea={"fichaAdmin"}
            title={
              dadosPessoalAdmin?.dadosPessoais?.nome +
              " ( " +
              dadosPessoalAdmin?.funcao +
              " )"
            }
            editDados={editDados}
            setEditDados={setEditDados}
            dadosPessoalAdmin={dadosPessoalAdmin}
            setOpenEditFoto={setOpenEditFoto}
            printQrCode={printQrCode}
            
          />

          {!editDados && (
            <Grid container>
              <Grid item gap={8} justifyContent={"center"}>
                <FotoEdit
                  openEditFoto={local === "perfilAdmin" ? openEditFoto : ""}
                  setOpenEditFoto={setOpenEditFoto}
                  dadosPessoal={dadosPessoalAdmin.dadosPessoais}
                />
                <div style={{ marginTop: '20px' }}>
                  {(user?.funcao === "CNP" || user?.funcao === "Director Geral ") && (
                    <div>
                      <ChangeStatusAdmin
                        admin={admin}
                        setHandleUpdate={setHandleUpdate}
                      />
                      <AssinaturaUser
                        admin={admin}
                        setHandleUpdate={setHandleUpdate}
                      />
                      <CarimboUser
                        admin={admin}
                        setHandleUpdate={setHandleUpdate}
                      />
                    </div>)}
                </div>
              </Grid>

              <Grid xs item display={"flex"} justifyContent={"space-between"}>
                <article style={{ padding: 20 }}>
                  {/* {dadosPessoalAdmin._id} */}
                  {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Secção:</strong> {dadosPessoalAdmin?.dadosPessoais?.nome}</Typography> */}
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Nome:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.nome}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Departamento:</strong>{" "}
                    {dadosPessoalAdmin?.departamento}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Secção:</strong> {dadosPessoalAdmin?.seccao}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Agente:</strong> {dadosPessoalAdmin?.numeroAgente}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Nome:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.nome}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>BI:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.numeroBI}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>E-mail:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.email}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Telefone1:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.tel1}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Telefone2:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.tel2}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Rua:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.rua}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Bairro:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.bairro}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Distrito / Município:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.municipio}{" "}
                  </Typography>
                  <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                    <strong>Provincia:</strong>{" "}
                    {dadosPessoalAdmin?.dadosPessoais?.provincia}{" "}
                  </Typography>
                  <img width={"50%"} src={dadosPessoalAdmin?.assinatura} />
                  <img width={"50%"} src={dadosPessoalAdmin?.carimbo} /> 
                </article>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <QRCode
                    link={`${baseUrl}/verifica-tecnico/${dadosPessoalAdmin?._id}`}
                  />
                </div>
              </Grid>


              <Grid item>
                <div
                  id={dadosPessoalAdmin?.dadosPessoais?._id}
                  style={{ display: "none" }}
                >
                  <QRCode
                    link={`${baseUrl}/verifica-tecnico/${dadosPessoalAdmin?._id}`}
                  />
                  <br />
                  {dadosPessoalAdmin?.dadosPessoais?.nome}
                </div>
              </Grid>
            </Grid>
          )}
          {editDados === "dadosPessoais" && (
            <DadosPessoalEdit
              dadosPessoais={dadosPessoalAdmin.dadosPessoais}
              setDadosPessoais={setDadosPessoalAdmin}
              cancelEditDados={setEditDados}
            />
          )}


          {editDados === "senha" && (
            <EditeSenha user={admin} cancelEdite={setEditDados} />
          )}
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
}
