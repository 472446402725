import React, { useState, useEffect } from 'react'
import { Box, Dialog, Divider, ListItemIcon, TextField, MenuItem } from '@mui/material';
import { GrUserAdmin } from "react-icons/gr";
import { RiAdminLine } from "react-icons/ri";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useSelector } from 'react-redux';
import LinkIcon from '@mui/icons-material/Link';
import ChangeLevelAdmin from '../admin/gestAdmin/changeStatusEstabelecimento';
import api from '../services/api';
import LoadingShort from '../load/loadingShort';
import MessageSuccess from '../messages/messageSuccess';
import MessageError from '../messages/messageError';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function HeaderGestorConfigs(props) {
    const navigate = useNavigate()
    const user = useSelector(state => state.account.user);

 
    const [load, setLoad] = useState(false);
    const [messegeSuccess, setMessageSuccess] = useState('');
    const [messegeError, setMessageError] = useState('');
    const [openRepresentante, setOpenRepresentante] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [categorias, setCategorias] = useState([]);

    const [dadosRepresentante, setDadosRepresentante] = useState({
        userId: user._id,
        ordem: '',
        numeroCarteira: '',
        adminId: props?.dadosPessoalAdmin?._id,
        categoriaId: ''
    })

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseRepresentante = () => { setOpenRepresentante(false) }
    const handleCloseConfirm = () => { setOpenConfirm(false) }

    useEffect(() => {
        api.get('/categoria-tecnico')
            .then((res) => setCategorias(res.data.categoriaTecnicos))
            .catch((err) => 'console.log(err)')
    }, [])

    const changeToRepresentante = () => {
        setLoad(true);
        api.post('/admin/become-representante', {
            adminId: props?.dadosPessoalAdmin?._id,
            userId: user._id
        })
            .then((res) => setMessageSuccess('O usuário agora é um representante'))
            .catch(() => setMessageError('Não foi Possivel tornar - lo representante'))
            .finally(() => setLoad(false))
    }
    const changeToDirectorTecnico = () => {
        setLoad(true);
        api.post('/admin/become-tecnico', dadosRepresentante)
            .then((res) =>{ 
                console.log(res.data);
                handleCloseConfirm();
                setMessageSuccess('Usuário aprovado como Director Técnico')
            })
            .catch((err) =>{ 
                handleCloseConfirm();
                setMessageError(err.response.data.msg)
            })
            .finally(() => setLoad(false))
    }

    const handleChange = (event) => {
        setDadosRepresentante({ ...dadosRepresentante, [event.target.name]: event.target.value });
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontSize: 20, flexGrow: 1 }}>
                    {load ? <LoadingShort /> : ''} {props.title}
                    {messegeSuccess && <MessageSuccess message={messegeSuccess} />}
                    {messegeError && <MessageError message={messegeError} />}
                </Typography>

                <React.Fragment>

                    {props.menu &&
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Tooltip title="Mais opções">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    // sx={{ ml: 1 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {(props.configArea === 'recibo' || props.configArea === 'inspeccao') &&
                            <div>
                                <MenuItem size='small' onClick={() => props.generatePDF('print')}> <ListItemIcon> <PrintIcon color='secondary' />  </ListItemIcon> Imprimir </MenuItem>
                                <MenuItem size='small' onClick={() => props.generatePDF('save')}> <ListItemIcon> <DownloadIcon color='secondary' />  </ListItemIcon>  Baixar PDF </MenuItem>
                                <MenuItem size='small' onClick={() => props.setOpenReenviarEmail(true)}> <ListItemIcon> <AttachEmailIcon color='secondary' />  </ListItemIcon>  Enviar por email </MenuItem>
                            </div>
                        }


                        {props.configArea === 'fichaCadastramento' &&
                            <div>
                                {/* <MenuItem size='small' onClick={() => navigate(`/exportar-ficha-estabelecimento/${props.estabelecimento._id}`)}>Exportar </MenuItem> */}
                                {/* <MenuItem size='small' onClick={() => props.exportToPDF()}> Imprimir </MenuItem> */}
                                <MenuItem size='small'><a style={{ textDecoration: 'none' }} href={`/exportar-ficha-estabelecimento/${props.estabelecimento._id}`}> Exportar</a> </MenuItem>
                            </div>
                        }

                        {props.configArea === 'autorizacaoExercio' &&
                            <div>
                                <MenuItem size='small' onClick={() => navigate(`/admin/gestor-licenciados/${props.autorizacao.estabelecimento._id}`)}> <ListItemIcon> <LinkIcon color='secondary' /></ListItemIcon> Ir </MenuItem>
                                <MenuItem size='small' onClick={() => props.generatePDF(props.autorizacao)}> <ListItemIcon> <PrintIcon color='secondary' />  </ListItemIcon> Imprimir </MenuItem>
                                {/* <MenuItem size='small' onClick={() => props.generatePDF('save', props.autorizacao)}> <ListItemIcon> <DownloadIcon color='secondary' />  </ListItemIcon>  Baixar PDF </MenuItem> */}
                                {/* <MenuItem size='small' onClick={() => props.setOpenReenviarEmail(true)}> <ListItemIcon> <AttachEmailIcon color='secondary' />  </ListItemIcon>  Enviar por email </MenuItem> */}
                            </div>
                        }


                        {props.configArea === 'qrCode' &&
                            <div>
                                {/* <MenuItem size='small' onClick={() => navigate(`/exportar-qrcode-estabelecimento/${props.estabelecimento._id}`)}>Exportar </MenuItem> */}
                                <MenuItem size='small' onClick={() => props.exportQrCode()}>  <ListItemIcon> <PrintIcon color='secondary' />  </ListItemIcon>  Imprimir </MenuItem>
                            </div>
                        }


                        {props.configArea === 'fichaEstabelecimento' &&
                            <div>
                                {user ?
                                    <>
                                        <MenuItem size='small' onClick={() => navigate(`/admin/gestor-licenciados/${props.estabelecimento._id}`)}> <ListItemIcon> <LinkIcon color='secondary' /></ListItemIcon> Ir </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' /></ListItemIcon> Editar dados </MenuItem>
                                        <MenuItem size='small'>
                                            <ListItemIcon> <ModeEditIcon color='secondary' /> </ListItemIcon>
                                            <ChangeLevelAdmin userId={props.dadosPessoalAdmin?.dadosPessoais?._id} />
                                        </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setChangeDirectorTecnico(true)}> <ListItemIcon> <img src="/icones/Director_tecnico2.svg" width={25} alt="" /> </ListItemIcon>  Mudar de Director Técnico </MenuItem>
                                        <MenuItem size='small' onClick={() => props.delete(true)}> <ListItemIcon> <DeleteIcon color='secondary' />  </ListItemIcon>   Eliminar estabelecimento  </MenuItem>
                                        {props.estabelecimento?.status === 'Reprovado' &&
                                            <MenuItem size='small' onClick={() => props.setOpenReenviarLinkReprovacao(true)}> <ListItemIcon>  </ListItemIcon> Reenviar Link de Reprovação  </MenuItem>
                                        }
                                    </>
                                    :
                                    <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' /></ListItemIcon> Editar dados </MenuItem>
                                }
                            </div>
                        }


                        {props.configArea === 'dadosempresa' &&
                            <div>
                                {user ?
                                    <>
                                        <MenuItem size='small' onClick={() => navigate(`/admin/get-empresa/${props.empresa._id}`)}> <ListItemIcon> <LinkIcon color='secondary' /></ListItemIcon> Ir </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar dados </MenuItem>
                                        {/* {user?.funcao === 'CNP' && */}
                                        <MenuItem size='small' onClick={() => props.setChangeRepresentante(true)}> <ListItemIcon> <img src="/icones/representante2.svg" width={25} alt="" />  </ListItemIcon> Mudar de Representante </MenuItem>
                                        <MenuItem size='small' onClick={() => props.delete(true)}> <ListItemIcon> <DeleteIcon color='secondary' />  </ListItemIcon>  Eliminar Empresa </MenuItem>
                                    </>
                                    :
                                    <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' /></ListItemIcon> Editar dados </MenuItem>
                                }
                            </div>
                        }


                        {props.configArea === 'noticia' &&
                            <div>
                                {props.noticia.status === "Inactivo" &&
                                    <MenuItem size='small' onClick={() => props.HandleEdit(props.noticia)}> Publicar agora </MenuItem>
                                }
                                <MenuItem size='small' onClick={() => props.HandleEdit(props.noticia)}> <ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar </MenuItem>
                            </div>
                        }


                        {props.configArea === 'fichaAdmin' &&
                            <div>
                                {props.editDados === false &&
                                    <MenuItem size='small' onClick={() => props.setOpenEditFoto(true)}> Editar Imagem </MenuItem>
                                }
                                <MenuItem size='small' onClick={() => props.setEditDados('senha')}> <ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar senha </MenuItem>
                                <MenuItem size='small' onClick={() => props.setEditDados('dadosPessoais')}> <ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar dados pessoal </MenuItem>
                                <MenuItem size='small' onClick={() => props.printQrCode(props.dadosPessoalAdmin?.dadosPessoais?._id)}> <ListItemIcon> <PrintIcon color='secondary' />  </ListItemIcon>  Imprimir QRCode </MenuItem>
                                <MenuItem size='small' onClick={() => changeToRepresentante()}> <ListItemIcon><GrUserAdmin color='#9C27B0' />  </ListItemIcon>Tornar Representante</MenuItem>
                                <MenuItem size='small' onClick={() => setOpenRepresentante(true)}> <ListItemIcon><RiAdminLine color='#9C27B0' /> </ListItemIcon>Tornar Diretor Técnico</MenuItem>
                            </div>
                        }


                        {props.configArea === 'fichaRepresentante' &&
                            <div>
                                {user ?
                                    <>
                                        <MenuItem size='small' onClick={() => navigate(`/admin/get-representante/${props.representante._id}`)}> <ListItemIcon> <LinkIcon color='secondary' /></ListItemIcon> Ir </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar dados pessoal </MenuItem>
                                        {/* {user?.funcao === 'CNP' && */}
                                        <MenuItem size='small' onClick={() => props.delete(true)}> <ListItemIcon> <DeleteIcon color='secondary' />  </ListItemIcon>  Eliminar Representante </MenuItem>
                                        <MenuItem size='small' onClick={() => props.representanteCreateDirectorTecnico(true)}> <ListItemIcon> '' </ListItemIcon>  Criar Director Técnico </MenuItem>
                                    </>
                                    :
                                    <MenuItem size='small' onClick={() => props.setEditDados(!props.editDados)}> <ListItemIcon> <ModeEditIcon color='secondary' /></ListItemIcon> Editar dados </MenuItem>
                                }
                            </div>
                        }


                        {props.configArea === 'fichaDT' &&
                            <div>
                                {user ?
                                    <>
                                        <MenuItem size='small' onClick={() => navigate(`/admin/get-director-tecnico/${props.directorTecnico._id}`)}> <ListItemIcon> <LinkIcon color='secondary' /></ListItemIcon> Ir </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setEditDados('dadospessoaledit')}><ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar dados pessoal </MenuItem>
                                        <MenuItem size='small' onClick={() => props.setEditDados('dadosdirectortecnicoedit')}><ListItemIcon> <ModeEditIcon color='secondary' />  </ListItemIcon>  Editar outros dados </MenuItem>
                                        {/* {user?.funcao === 'CNP' && */}
                                        <MenuItem size='small' onClick={() => props.delete(true)}><ListItemIcon> <DeleteIcon color='secondary' />  </ListItemIcon>  Eliminar Director Técnico </MenuItem>
                                        <MenuItem size='small' onClick={() => props.directorTecnicoCreateRepresentante(true)}> <ListItemIcon> '' </ListItemIcon>  Criar Representante </MenuItem>
                                    </>
                                    :
                                    <MenuItem size='small' onClick={() => props.setEditDados('all')}> <ListItemIcon> <ModeEditIcon color='secondary' /></ListItemIcon> Editar dados </MenuItem>
                                }
                            </div>
                        }


                        {props.configArea === 'AutoVistoria' &&
                            <div>
                                <MenuItem size='small' onClick={() => navigate(`/admin/modo-inspeccao/${props.inspeccao._id}`)} ><ListItemIcon> <ContentPasteSearchIcon color='secondary' />  </ListItemIcon>  Activar modo inspecção </MenuItem>
                            </div>
                        }
                    </Menu>
                </React.Fragment>
            </div>

            <Divider style={{ marginBottom: 10 }} />

            <Dialog
                open={openRepresentante}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseRepresentante}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Tornar Diretor Técnico"}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '7px', minWidth: '500px' }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        Preencha os campos abaixo:
                    </DialogContentText>
                    <TextField size='small' id="ordem" name='ordem' label="Ordem" variant="outlined" onChange={handleChange} fullWidth />
                    <TextField size='small' id="nCarteira" name='numeroCarteira' label="Nº Carteira" variant="outlined" onChange={handleChange} fullWidth />
                    <TextField
                        type="text"
                        placeholder="Categoria"
                        label='Categoria'
                        name='categoriaId'
                        select
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    >
                        {categorias.map((categoria) => (
                            < MenuItem key={categoria?._id} value={categoria?._id}>
                               {categoria?.designacao}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRepresentante}>Cancelar</Button>
                    <Button disabled={dadosRepresentante.ordem === '' || dadosRepresentante.numeroCarteira === ''}
                        onClick={() => {
                            setOpenConfirm(true);
                            handleCloseRepresentante()
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog
                open={openConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseConfirm}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Tornar Diretor Técnico"}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '7px', minWidth: '500px' }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        Deseja tormar este usuário Representante?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancelar</Button>
                    <Button onClick={() => changeToDirectorTecnico()}>{load ? <LoadingShort /> : 'Sim'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
