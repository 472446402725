import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
} from "@mui/material";
import api from "../../services/api";
import { format } from "date-fns";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";

interface Solicitacao {
  _id: string;
  nomeGenerico: string;
  nomeComercial: string;
  dosagem: string;
  indicacoesTerapeuticas: string;
  contraIndicacoes: string;
  tipoSolicitacao: string;
  formaFarmaceutica: {
    _id: string;
    titulo: string;
    descricao: string;
    status: boolean;
  };
  createdAt: string;
}

const Solicitacoes: React.FC = () => {
  const [solicitacoes, setSolicitacoes] = useState<Solicitacao[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    api
      .get("/formulario-solicitacoes")
      .then((response) => {
        setSolicitacoes(response.data.formularioSolicitacao || []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Erro ao buscar dados:", err);
        setError(
          "Não foi possível carregar os dados. Tente novamente mais tarde."
        );
        setIsLoading(false);
      });
  }, []);

  const solicitacoesFiltradas = useMemo(() => {
    return solicitacoes.filter((solicitacao) => {
      return (
        solicitacao.nomeGenerico
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        solicitacao.tipoSolicitacao
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });
  }, [solicitacoes, searchTerm]);

  const handleRowClick = (solicitacao: Solicitacao) => {
    navigate(`/detalhes-solicitacao/${solicitacao._id}`, {
      state: solicitacao,
    });
  };

  const getTagStyles = (tipo: string) => {
    switch (tipo.toLowerCase()) {
      case "exclusão":
        return { backgroundColor: "#FFD6D6", color: "#B30000" };
      case "inclusão":
        return { backgroundColor: "#D6F5D6", color: "#006600" };
      case "substituição":
        return { backgroundColor: "#FFF5CC", color: "#806600" };
      default:
        return { backgroundColor: "#E0E0E0", color: "#333333" };
    }
  };

  return (
    <Container>
      <HeaderSession title="TABELA DE SOLICITAÇÕES" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        borderRadius={"5px"}
        gap={"2rem"}
        padding={"1rem"}
        bgcolor={"white"}
      >
        <Box>
          <TextField
            label="Buscar por Nome ou Tipo de Solicitação"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>

        {error && (
          <Box color="error.main" textAlign="center">
            {error}
          </Box>
        )}

        {isLoading ? (
          <Loading text={"Carregando..."} focus={false} />
        ) : solicitacoesFiltradas.length === 0 ? (
          <Box textAlign="center" marginTop={5}>
            Nenhuma solicitação encontrada.
          </Box>
        ) : (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 590 }} component={Paper}>
              <Table stickyHeader>
                <TableHead sx={{ backgroundColor: "#dcdede" }}>
                  <TableRow>
                    <TableCell>Nome Genérico</TableCell>
                    <TableCell>Nome Comercial</TableCell>
                    <TableCell>Dosagem</TableCell>
                    <TableCell>Forma Farmacêutica</TableCell>
                    <TableCell>Tipo de Solicitação</TableCell>
                    <TableCell>Data de Solicitação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {solicitacoesFiltradas.map((solicitacao) => (
                    <TableRow
                      key={solicitacao._id}
                      hover
                      onClick={() => handleRowClick(solicitacao)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{solicitacao.nomeGenerico}</TableCell>
                      <TableCell>{solicitacao.nomeComercial}</TableCell>
                      <TableCell>{solicitacao.dosagem}</TableCell>
                      <TableCell>
                        {solicitacao.formaFarmaceutica?.titulo || "N/A"}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "inline-block",
                            padding: "6px 12px",
                            borderRadius: "15px",
                            textAlign: "center",
                            ...getTagStyles(solicitacao.tipoSolicitacao),
                          }}
                        >
                          {solicitacao.tipoSolicitacao}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {format(new Date(solicitacao.createdAt), "dd/MM/yyyy")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default Solicitacoes;
