import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import api from "../../services/api";
import Loading from "../../load/loading";
import { Avatar, Box, Card, Divider } from "@mui/material";
import GraficoHistorico from "./graficoHistorico";
import HeaderSession from "../../utils/headerSession";
import * as C from "./styles";
import SelectInput from "./SelectInput"
import Mes from "../../utils/mes";
import TabelaActividades from "./tabelaActividades";
import RegisterAccess from "../../utils/registerAccess";
import GraficoHistoricoSecond from "./graficoHistorico2";


export default function HistoricosActividades() {

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [historicosActividades, setHistoricosActividades] = useState('')
    const [admins, setAdmins] = useState([])
    const [adminsData, setAdminsData] = useState({});

    const [stapReload, setStapReload] = useState(true)
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')
    const [perPage, setPerPage] = useState(100)
    const [changeGrafico, setChangeGrafico] = useState(true)



    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await api.get('/admins');
                setAdmins(response.data.Admins);
            } catch (err) {
                console.error('Erro ao buscar admins:', err);
            }
        };
        fetchAdmins();
    }, []);

    useEffect(() => {
        setHistoricosActividades('')
        const fetchHistoricos = async () => {
            if (!admins.length) return;

            try {
                const generalRequest = api.get(`/historico-actividades?cnp=false&page=${page}&perPage=${perPage}&ano=${selectedYear}&mes=${selectedMonth}`);

                const adminRequests = admins.map(admin =>
                    api.get(`/historico-actividades?cnp=false&userId=${admin._id}&ano=${selectedYear}&mes=${selectedMonth}`)
                );

                const responses = await Promise.all([generalRequest, ...adminRequests]);

                setHistoricosActividades(responses[0].data.historicoActividades);
                setConfigLista(responses[0].data);

                const adminsDataObj = {};
                admins.forEach((admin, index) => {
                    adminsDataObj[admin._id] = responses[index + 1].data;
                });
                setAdminsData(adminsDataObj);

            } catch (error) {
                console.error('Erro ao buscar históricos:', error);
            }
        };
        fetchHistoricos();

    }, [admins, page, perPage, selectedMonth, selectedYear])

    const objectMonths = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' }
    ]

    // Gera uma lista de anos a partir do ano atual até anos passados até 2024
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= 2024; year--) {
            years.push({ value: year, label: year });
        }
        return years;
    };

    const generateMonthOptions = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const months = [];

        // Se o ano selecionado for o ano atual, exibe apenas os meses que já se passaram
        // Caso contrário, exibe todos os meses do ano
        const endMonth = selectedYear === currentYear ? currentMonth : 12;

        for (let month = 0; month <= endMonth; month++) {
            months.push(objectMonths[month]);
        }
        return months;
    };

    return (
        <Card style={{ padding: 20, marginTop: 20 }}>
            <RegisterAccess page={'historico de actividades no sistema'} />
            <HeaderSession title={'HISTÓRICOS DE ACTIVIDADES NO SISTEMA'} />

            <C.Container>
                <C.Header>
                    <div style={{ flexGrow: 1, }}>
                        <C.Title color="#8c8c98">
                            Mês de <Mes mes={selectedMonth} />
                        </C.Title>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end', gap: '1rem' }}>
                        {/* estou a usar o setSelectedYear para selectedYear selectedMonth porque o estou a mesmo componente para exibir os meses e os anos */}
                        <SelectInput
                            options={generateYearOptions()}
                            selected={selectedYear}
                            setSelectedYear={setSelectedYear}
                        />
                        <SelectInput
                            options={generateMonthOptions()}
                            selected={selectedMonth}
                            setSelectedYear={setSelectedMonth}
                        />
                    </div>
                </C.Header>
            </C.Container>

            {historicosActividades ?
                <>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            onClick={() => setChangeGrafico(!changeGrafico)}
                            style={{
                                border: '1px solid #85287E',
                                padding: '6px',
                                color: '#85287E',
                                borderRadius: '5px',
                                textTransform: 'capitalize'
                            }}>Mudar Gráfico</Button>
                    </div>


                    {changeGrafico ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                        }}>
                            <GraficoHistorico
                                data={Object.values(adminsData).map(data => data.totalItems)}
                                labels={admins.map(admin => admin.dadosPessoais?.nome || 'Sem nome')}
                            />

                            <Box style={{ display: 'flex', flexDirection: 'column', height: 950, marginTop: '20px' }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {admins &&
                                    admins.map((admin, index) => (
                                        <div key={admin._id} style={{ flex: 1, }}>
                                            <Avatar
                                                title={'Ver historico individual'}
                                                style={{ cursor: 'pointer', height: '35px', width: '35px' }}
                                                src={admin.dadosPessoais?.fotoURL}
                                                onClick={() => {
                                                    setStapReload(false);
                                                    setHistoricosActividades(
                                                        adminsData[admin._id]?.historicoActividades || []
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                            </Box>
                        </div>
                        :
                        <div>
                            <GraficoHistoricoSecond
                                data={Object.values(adminsData).map(data => data.totalItems)}
                                labels={admins.map(admin => admin.dadosPessoais?.nome || 'Sem nome')}
                            />

                            <Box style={{ marginTop: '30px' }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {admins &&
                                    admins.map((admin, index) => (
                                        <div key={admin._id} style={{ flex: 1, }}>
                                            <Avatar
                                                title={'Ver historico individual'}
                                                style={{ cursor: 'pointer', height: '50px', width: '50px' }}
                                                src={admin.dadosPessoais?.fotoURL}
                                                onClick={() => {
                                                    setStapReload(false);
                                                    setHistoricosActividades(
                                                        adminsData[admin._id]?.historicoActividades || []
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                            </Box>
                        </div>
                    }

                    <br />
                    <Divider />
                    <br />

                    <TabelaActividades
                        historicosActividades={historicosActividades}
                        configLista={configLista}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        page={page}
                    />
                </>
                :
                <Loading />
            }
        </Card>
    )
}
