import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import {
  Box,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  DialogActions,
} from "@mui/material";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";

interface Categoria {
  _id: string;
  nome: string;
  subcategorias?: Categoria[];
}

interface NovoMedicamento {
  nomeGenerico: string;
  codNacional: string;
  codUNSPSC: string;
  apresentacao: string;
  categoria: string;
  utilizacaoMedicamento: string[];
}

const AdicionarMedicamento: React.FC = () => {
  const [categoriasHierarquicas, setCategoriasHierarquicas] = useState<
    Categoria[]
  >([]);
  const [utilizacoesOpcoes, setUtilizacoesOpcoes] = useState<
    { _id: string; tipo: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [novoMedicamento, setNovoMedicamento] = useState<NovoMedicamento>({
    nomeGenerico: "",
    codNacional: "",
    codUNSPSC: "",
    apresentacao: "",
    categoria: "",
    utilizacaoMedicamento: [],
  });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Carregar categorias e utilizações da API
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      api.get("/categorias"), // Substitui getCategorias
      api.get("/utilizacao-Medicamento"), // Substitui getUtilizacoesMedicamento
    ])
      .then(([categoriasRes, utilizacoesRes]) => {
        setCategoriasHierarquicas(categoriasRes.data.categorias || []);
        const utilizacoes =
          utilizacoesRes.data.utilizacaoMedicamento?.map((item: any) => ({
            _id: item._id,
            tipo: item.tipo,
          })) || [];
        setUtilizacoesOpcoes(utilizacoes);
      })
      .catch((err) => {
        console.error("Erro ao carregar dados:", err);
        setError("Não foi possível carregar as informações. Tente novamente.");
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Função recursiva para gerar nomes hierárquicos
  const gerarNomesHierarquicos = (
    categorias: Categoria[],
    prefixo: string = ""
  ): { _id: string; nome: string }[] => {
    return categorias.flatMap((categoria, index) => {
      const numeroAtual = `${prefixo}${index + 1}`;
      const nomeAtual = `${numeroAtual}. ${categoria.nome}`;
      const filhos =
        categoria.subcategorias && categoria.subcategorias.length > 0
          ? gerarNomesHierarquicos(categoria.subcategorias, `${numeroAtual}.`)
          : [];

      return [{ _id: categoria._id, nome: nomeAtual }, ...filhos];
    });
  };

  // Renderizar categorias enumeradas
  const categoriasEnumeradas = gerarNomesHierarquicos(categoriasHierarquicas);

  // Filtrar apenas subcategorias (categorias que possuem um pai)
  const subcategorias = categoriasHierarquicas.flatMap((categoria) =>
    categoria.subcategorias
      ? categoria.subcategorias.map((sub) => ({
          _id: sub._id,
          nome: `${categoria.nome} > ${sub.nome}`,
        }))
      : []
  );
  // Submeter os dados do medicamento
  // Função de envio do formulário
  const handleSubmit = () => {
    if (!novoMedicamento.nomeGenerico || !novoMedicamento.categoria) {
      setError("Preencha todos os campos obrigatórios.");
      return;
    }

    // Verifica se pelo menos uma área de utilização foi selecionada
    if (novoMedicamento.utilizacaoMedicamento.length === 0) {
      setError("Selecione pelo menos uma área de utilização.");
      return;
    }

    // Mapeamento correto das áreas de utilização
    const areasUtilizacao = [
      { _id: "675c66f498bacaed54507cfe", tipo: "Posto de Saúde" },
      { _id: "675c66f498bacaed54507cff", tipo: "Centro de Saúde" },
      { _id: "675c66f498bacaed54507d00", tipo: "Hospital Municipal" },
      { _id: "675c66f498bacaed54507d01", tipo: "Hospital Geral ou Especializado" },
      { _id: "675c66f498bacaed54507d02", tipo: "Outros" }
    ];

    // Formata os dados para envio
    const dadosParaEnvio = {
      nomeGenerico: novoMedicamento.nomeGenerico,
      codNacional: novoMedicamento.codNacional,
      codUNSPSC: novoMedicamento.codUNSPSC,
      apresentacao: novoMedicamento.apresentacao,
      categoria: novoMedicamento.categoria,
      // Filtra e formata as áreas de utilização selecionadas
      utilizacaoMedicamento: areasUtilizacao
        .filter(area => novoMedicamento.utilizacaoMedicamento.includes(area._id))
    };

    console.log("Dados para envio:", dadosParaEnvio);

    api.post("/medicamento/create", dadosParaEnvio)
      .then((response) => {
        console.log("Resposta da API:", response.data);
        alert("Medicamento adicionado com sucesso!");
        navigate("/admin/Tabela-Geral");
      })
      .catch((err) => {
        console.error("Erro ao adicionar medicamento:", err);
        console.error("Detalhes do erro:", err.response?.data);
        setError("Erro ao adicionar o medicamento. Tente novamente.");
      });
  };

  // Lógica para exibir os checkboxes de utilização
  <Typography variant="h6" marginTop="1rem">
    Áreas de Utilização
  </Typography>
  {[
    { id: "675c66f498bacaed54507cfe", label: "Posto de Saúde" },
    { id: "675c66f498bacaed54507cff", label: "Centro de Saúde" },
    { id: "675c66f498bacaed54507d00", label: "Hospital Municipal" },
    { id: "675c66f498bacaed54507d01", label: "Hospital Geral ou Especializado" },
    { id: "675c66f498bacaed54507d02", label: "Outros" }
  ].map((level) => (
    <FormControlLabel
      key={level.id}
      control={
        <Checkbox
          checked={novoMedicamento.utilizacaoMedicamento.includes(level.id)}
          onChange={(e) => {
            setNovoMedicamento(prev => {
              const novaUtilizacoes = e.target.checked
                ? [...prev.utilizacaoMedicamento, level.id]
                : prev.utilizacaoMedicamento.filter(id => id !== level.id);
              
              console.log("IDs das áreas selecionadas:", novaUtilizacoes);
              
              return {
                ...prev,
                utilizacaoMedicamento: novaUtilizacoes
              };
            });
          }}
        />
      }
      label={level.label}
    />
  ))}

  return (
    <Container>
      <HeaderSession title="ADICIONAR NOVO MEDICAMENTO" />
      <Box
        sx={{ backgroundColor: "white", padding: "1rem", borderRadius: "10px" }}
      >
        {isLoading ? (
          <Loading text={""} focus={false} />
        ) : (
          <Box>
            {error && (
              <Typography color="error" gutterBottom>
                {error}
              </Typography>
            )}

            <TextField
              label="Nome Genérico *"
              variant="outlined"
              fullWidth
              margin="normal"
              value={novoMedicamento.nomeGenerico}
              onChange={(e) =>
                setNovoMedicamento((prev) => ({
                  ...prev,
                  nomeGenerico: e.target.value,
                }))
              }
            />
            <div style={{ display: "flex", gap: "1rem" }}>
              <TextField
                label="Código Nacional"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.codNacional}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    codNacional: e.target.value,
                  }))
                }
              />

              <TextField
                label="Código UNSPSC"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.codUNSPSC}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    codUNSPSC: e.target.value,
                  }))
                }
              />
            </div>

            <TextField
              label="Apresentação"
              variant="outlined"
              fullWidth
              margin="normal"
              value={novoMedicamento.apresentacao}
              onChange={(e) =>
                setNovoMedicamento((prev) => ({
                  ...prev,
                  apresentacao: e.target.value,
                }))
              }
            />

            <Autocomplete
              options={subcategorias}
              getOptionLabel={(option) => option.nome}
              noOptionsText="Nenhuma subcategoria disponível"
              onChange={(_, value) =>
                setNovoMedicamento((prev) => ({
                  ...prev,
                  categoria: value?._id || "", // Salvando apenas o _id da subcategoria selecionada
                }))
              }
              value={
                subcategorias.find(
                  (cat) => cat._id === novoMedicamento.categoria
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subcategoria *"
                  variant="outlined"
                />
              )}
              fullWidth
            />

            <Typography variant="h6" marginTop="1rem">
              Áreas de Utilização
            </Typography>
            {[
              { id: "675c66f498bacaed54507cfe", label: "Posto de Saúde" },
              { id: "675c66f498bacaed54507cff", label: "Centro de Saúde" },
              { id: "675c66f498bacaed54507d00", label: "Hospital Municipal" },
              { id: "675c66f498bacaed54507d01", label: "Hospital Geral ou Especializado" },
              { id: "675c66f498bacaed54507d02", label: "Outros" }
            ].map((level) => (
              <FormControlLabel
                key={level.id}
                control={
                  <Checkbox
                    checked={novoMedicamento.utilizacaoMedicamento.includes(level.id)}
                    onChange={(e) => {
                      setNovoMedicamento(prev => {
                        const novaUtilizacoes = e.target.checked
                          ? [...prev.utilizacaoMedicamento, level.id]
                          : prev.utilizacaoMedicamento.filter(id => id !== level.id);
                        
                        console.log("IDs das áreas selecionadas:", novaUtilizacoes);
                        
                        return {
                          ...prev,
                          utilizacaoMedicamento: novaUtilizacoes
                        };
                      });
                    }}
                  />
                }
                label={level.label}
              />
            ))}
            <DialogActions>
              <Box marginTop="2rem">
                <button
                  onClick={handleSubmit}
                  style={{
                    padding: "12px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: " white",
                    backgroundColor: " #85287e",
                  }}
                >
                  Salvar
                </button>
                <button
                  style={{
                    padding: "12px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: " red",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => navigate("/tabela-geral")}
                >
                  Cancelar
                </button>
              </Box>
            </DialogActions>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AdicionarMedicamento;
