import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { QRCodeSVG } from 'qrcode.react';
import ReactDOMServer from 'react-dom/server';
import "@fontsource/roboto";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
/** REINDERIZAÇÃO DE IMAGEM, PARA CONVERÇÃO **/
function getBase64Image(img) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
function capitalizeWords(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
const AutorizacaoPDF = async (dado) => {
    const baseUrl = 'https://www.armed.gov.ao';
    const url = `${baseUrl}/verifica-estabelecimento/${dado.estabelecimento._id}`;
    const qrpic = await fetch('/img/faviconARMED.png').then(res => res.blob());
    const qrpic64 = await getBase64Image(qrpic);
    const qrCodeSVG = ReactDOMServer.renderToStaticMarkup(
        <QRCodeSVG value={url} renderAs="svg" imageSettings={{ src: `${qrpic64}`, height: 20, width: 20, excavate: true }} />
    );

    /** CONVERÇÃO DE IMAGEM PARA BASE64 **/
    const imageBlob = await fetch('/img/logo/InsigniaAngola.png').then(res => res.blob());
    const imageBase64 = await getBase64Image(imageBlob);
    const imageFundo = await fetch('/img/backGrounds/bg-autorizacao.png').then(res => res.blob());
    const imageFundo64 = await getBase64Image(imageFundo);
    /** CHAMADA DE TIPOS DE FONT**/
    pdfMake.fonts = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-BoldItalic.ttf'
        },
    };
    const dataEmissao = new Date(dado.dataEmissao);
    const dataExpiracao = new Date(dado.dataExpiracao);
    const anosDeValidade = dataExpiracao.getFullYear() - dataEmissao.getFullYear();
    /** INICIALIZAÇÃO DE DOCUMENTOS **/
    const docDefinition = {
        /** COFIGURAÇÃO INICIAL DE DOCUMENTOS **/
        pageSize: 'A4',
        pageMargins: [15, 20, 15, 20],
        /** CONTEUDO PRINCIPAL**/
        content: [
            // AAdiciona a imagem de logo
            {
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: qrCodeSVG,
                                width: 40,
                                height: 40,
                            },
                        ],
                        alignment: 'left', margin: [0, 0, 0, 0],
                    },
                    {
                        width: '50%',
                        stack: [
                            { text: `${dado?.estabelecimento?.tipo?.designacao}`.toUpperCase(), },
                        ], alignment: 'right', style: 'header', margin: [0, 0, 0, 0],
                    },
                ],
                margin: [20, 0, 20, 0],
            },
            {
                image: imageBase64,
                width: 55,
                height: 55,
                alignment: 'center',
                margin: [0, -35, 0, 0],
            },
            /** TITULO E SUBTITULO **/
            { text: 'REPÚBLICA DE ANGOLA', style: 'header' },
            { text: 'MINISTÉRIO DA SAÚDE', style: 'subheader' },
            { text: 'AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE', style: 'subheader' },
            { text: 'AUTORIZAÇÃO DE EXERCÍCIO', style: 'declaration', margin: [0, 30, 0, 0] },
            { text: `${dado?.estabelecimento?.nome}`.toUpperCase(), style: 'declaration', margin: [0, 20, 0, 15] },
            // Texto da declaração
            {
                text: [
                    `O Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde, no uso das competências conferidas pelo Artigo 40 do Decreto Presidencial n.º 136/21 de 1 de Junho, que cria `,
                    { text: ` a Agência Reguladora de Medicamentos e Tecnologias de Saúde ARMED,`, bold: true },
                ],
                style: 'body',
                margin: [45, 0, 45, -5],
            },
            // Texto adicional
            { text: 'Tendo sido cumpridos os requisitos consignados no Artigo 23º do Decreto Presidencial nº 180/10 de 18 de Agosto, sobre as Bases Gerais da Política Nacional Farmacêutica, nos Artigos 6º, 16º, 17º e 21º do Decreto Presidencial nº 191/10 de 1 de Setembro, sobre Regulamento do Exercício da Actividade Farmacêutica e o Decreto Presidencial nº 41/24 de 29 de Janeiro, Regulamento sobre o Licenciamento para o Exercício da Actividade Farmacêutica', style: 'body', margin: [45, 10, 45, 10], },
           
            { text: [
                    `Autoriza a empresa `,
                    { text: ` ${dado?.estabelecimento?.empresa?.nome} `.toUpperCase(), bold: true },
                    `a exercer a `,
                    {
                        text: (() => {
                            if (dado?.tipoAutorizacao === 'a') {
                                return `actividade de venda a ${dado?.estabelecimento.tipo.venda} de medicamentos essenciais de uso humano, e material gastável, `;
                            } else if (dado?.tipoAutorizacao === 'b') {
                                return `actividade de venda a ${dado?.estabelecimento.tipo.venda} de medicamentos essenciais de uso humano, incluindo medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, `;
                            } else if (dado?.tipoAutorizacao === 'c') {
                                return `actividade de Importação e Distribuição de equipamentos médico, meios de diagnóstico e material hospitalar, `;
                            } else if (dado?.tipoAutorizacao === 'd') {
                                return `actividade de Importação e Distribuição de medicamentos essenciais de uso humano, incluindo medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                            } else if (dado?.tipoAutorizacao === 'e') {
                                return `actividade de Importação de equipamentos médico, meios de diagnóstico e material hospitalar, `;
                            }
                            else if (dado?.tipoAutorizacao === 'f') {
                                return `actividade de Importação de medicamentos essenciais de uso humano, incluindo medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                            } else if (dado?.tipoAutorizacao === 'g') {
                                return `actividade de Distribuição de equipamentos médico, meios de diagnóstico e material hospitalar, `;
                            } else if (dado?.tipoAutorizacao === 'h') {
                                return `actividade de Distribuição de medicamentos essenciais de uso humano, incluindo medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                            }
                            else if (dado?.tipoAutorizacao === 'i') {
                                return `actividade de Venda a Retalho de equipamentos médico, meios de diagnóstico e material hospitalar, `;
                            }
                            else if (dado?.tipoAutorizacao === 'j') {
                                return `actividade de importação e distribuição de equipamentos para oftalmologia e optometria, lentes, lentes oculares e material de diagnóstico optico, `;
                            }
                            else if (dado?.tipoAutorizacao === 'k') {
                                return `actividade de venda a retalho de equipamentos para oftalmologia e optometria, lentes, lentes oculares e material de diagnóstico optico, `;
                            }
                            else if (dado?.tipoAutorizacao === 'l') {
                                return ` actividade de venda a retalho de cosmeticos, `;
                            }
                            else if (dado?.tipoAutorizacao === 'm') {
                                return ` actividade de importação e distribuição de produtos cosmeticos, `;
                            }
                            else {
                                return ''; // ou algum outro valor padrão
                            }
                        })(),
                        bold: true
                    },
                    `no estabelecimento localizado: `,
                ],
                style: 'body',
                margin: [45, 2, 45, 2],
            },
            {
                columns: [
                    {
                        width: '45%', stack: [
                            { text: 'Endereço Estabelecimento'.substring(0, 25), bold: true, fontSize: 11, },
                            { text: 'Rua/Avenida', bold: false },
                            { text: 'Bairro', bold: false },
                            { text: 'Distrito/Município', bold: false },
                            { text: 'Província', bold: false },
                        ], alignment: 'left', style: 'body', margin: [105, 0, 0, 0],
                    },
                    {
                        width: '5%', stack: [
                            { text: ':', bold: true, opacity: 0, },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                        ], alignment: 'center', color: '#111',
                    },
                    {
                        width: '50%', stack: [
                            { text: `----`, opacity: 0, },
                            { text: dado?.estabelecimento?.rua.substring(0, 50) },
                            { text: `${dado?.estabelecimento?.bairro}`.substring(0, 50), },
                            { text: `${dado?.estabelecimento?.municipio}`.substring(0, 50), },
                            { text: `${dado?.estabelecimento?.provincia}`.substring(0, 50), },
                        ], alignment: 'left', style: 'body',
                    },
                ],
                margin: [5, -3, 5, 0], fontSize: 9,
            },
            {
                columns: [
                    {
                        width: '45%', stack: [
                            { text: 'Endereço Sede', bold: true, fontSize: 11, },
                            { text: 'Rua/Avenida', bold: false },
                            { text: 'Bairro', bold: false },
                            { text: 'Distrito/Município', bold: false },
                            { text: 'Província', bold: false },

                        ], alignment: 'left', style: 'body', margin: [105, 0, 0, 0],
                    },
                    {
                        width: '5%', stack: [
                            { text: ':', bold: true, opacity: 0, },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            { text: ':', bold: true, margin: [0, 7, 0, 0], },

                        ], alignment: 'center', color: '#111',
                    },
                    {
                        width: '50%', stack: [
                            { text: `----`, opacity: 0, },
                            // { text: '..'  },
                            { text: `${dado?.estabelecimento?.empresa?.rua.substring(0, 50)}` },
                            { text: `${dado?.estabelecimento?.empresa?.bairro}`.substring(0, 50), },
                            { text: `${dado?.estabelecimento?.empresa?.municipio}`.substring(0, 50), },
                            { text: `${dado?.estabelecimento?.empresa?.provincia}`.substring(0, 50), },

                        ], alignment: 'left', style: 'body',
                    },
                ],
                margin: [5, -8, 5, 0], fontSize: 9,
            },
            {
                columns: [
                    {
                        width: '45%', stack: [
                            { text: 'Representante', },
                            { text: 'Director(a) Técnico(a)', },
                            { text: 'Categoria', fontSize: 11, },
                            { text: `Registado(a) na ${dado?.estabelecimento?.directorTecnico?.categoria?.ordem}`, fontSize: 11, },
                        ], alignment: 'left', style: 'body', margin: [50, 0, 0, 0],
                    },
                    {
                        width: '5%', stack: [
                            { text: ':', bold: true, },
                            { text: ':', bold: true, },
                            { text: ':', bold: true, },
                            { text: ':', bold: true, },
                        ], alignment: 'center', color: '#111', style: 'body'
                    },
                    {
                        width: '50%', stack: [
                            { text: capitalizeWords(`${dado?.estabelecimento?.empresa?.representante?.dadosPessoais?.nome}`.substring(0, 50)), },
                            { text: capitalizeWords(`${dado?.estabelecimento?.directorTecnico?.dadosPessoais?.nome}`.substring(0, 50)), },
                            { text: `${dado?.estabelecimento?.directorTecnico?.categoria?.designacao}`.substring(0, 50), fontSize: 11, },
                            { text: `${dado?.estabelecimento?.directorTecnico?.numeroCarteira}`.substring(0, 50), fontSize: 11, },
                        ], alignment: 'left', style: 'body',
                    },
                ],
                margin: [5, -10, 5, 0], fontSize: 9,
            },
            {
                text: [
                    { text: `Restrições: `, bold: true },
                    {
                        text: (() => {
                            if (dado?.restricaoAutorizacao === 'a') {
                                return `Nenhuma`;
                            } else if (dado?.restricaoAutorizacao === 'b') {
                                return `Psicotrópicos, Estupefacientes e outros medicamentos sujeitos à legislação especial.`;
                            } else if (dado?.restricaoAutorizacao === 'c') {
                                return `Medicamentos, material gastável e outros produtos de saude não descritos no paragrafo terceiro.`;
                            } else if (dado?.restricaoAutorizacao === 'd') {
                                return `Medicamentos, material gastável, equipamentos medico, material hospitalar e outros produtos de saude não descritos no paragrafo terceiro.`;
                            } else if (dado?.restricaoAutorizacao === 'e') {
                                return `Equipamentos médico, meios de diagnóstico e outros produtos de saúde não descritos no parágrafo terceiro.`;
                            }
                            else if (dado?.restricaoAutorizacao === 'f') {
                                return `Medicamentos, material gastável e outros produtos de saúde não descritos no parágrafo terceiro.`;
                            }
                            else {
                                return ''; // ou algum outro valor padrão
                            }
                        })(),
                    }
                ],
                style: 'body', margin: [45, 0, 45, 0],
            },
            {
                text: [
                    { text: `N.B: `, bold: true },
                    `Se o(a) técnico(a) supra deixar de exercer as suas funções e não for substituído no prazo de 30 (trinta) dias, considera-se automaticamente, suspensa a presente autorização até à correção da irregularidade.`,
                ], style: 'body', margin: [45, 0, 45, 0], fontSize: 8,
            },
            {
                columns: [
                    {
                        width: '50%', stack: [
                            { text: `Nº de Autorização ${dado?.estabelecimento?.numeroAutorizacao}`, bold: true, },
                            { text: `Emissão: ${new Date(dado?.dataEmissao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}`, bold: true, },

                        ], alignment: 'left', style: 'body', fontSize: 12,
                    },
                    {
                        width: '50%', stack: [
                            { text: `NIF: ${dado?.estabelecimento?.empresa.nif}`, bold: true, },
                            { text: `Prazo de Validade: ${anosDeValidade} ANOS`, bold: true, },
                        ], alignment: 'left', style: 'body', fontSize: 12, margin: [70, 0, 0, 0],
                    },
                ],
                margin: [45, 5, 45, 0],
            },
        ],
        /**CABEÇARIO**/
        header: function (currentPage, pageCount, pageSize) {
            return {
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: qrCodeSVG,
                                width: 40,
                                height: 40,
                            },
                        ],
                        alignment: 'left', margin: [0, 0, 0, 0],
                    },
                    {
                        width: '50%',
                        stack: [
                            { text: `${dado?.estabelecimento?.tipo?.designacao}`.toUpperCase(), },
                        ], alignment: 'right', style: 'header', margin: [0, 0, 0, 0],
                    },
                ],
                margin: [50, 50, 175, 0],
            }
        },
        footer: function (currentPage, pageCount) {
            return {
                stack: [
                    // Assinatura
                    {
                        text: 'O Director Geral',
                        alignment: 'center',
                        style: 'signature',
                    },
                    {
                        text: '_____________________________',
                        alignment: 'center',
                        style: 'signature',
                        margin: [0, 1, 0, 0],
                    },
                    {
                        text: 'Pombal Mayembe',
                        alignment: 'center',
                        style: 'signature',
                        margin: [0, 0, 0, 0],
                    },

                ],
                margin: [0, -75, 0, 0],
            }
        },
        /** FUNDO DA PAGINA **/
        background: function (currentPage, pageSize) {
            return {
                image: imageFundo64,
                width: pageSize.width - 30,
                height: pageSize.height - 20,
                alignment: 'center',
                margin: [0, 10, 0, 10],
            }
        },
        /** ESTILOS DE DOCUMENTO **/
        styles: {
            header: {
                fontSize: 11,
                bold: false,
                alignment: 'center',
                margin: [0, 0, 0, 0],
            },
            subheader: {
                fontSize: 11,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 0],
            },
            declaration: {
                fontSize: 14,
                bold: false,
                alignment: 'center',
                margin: [0, 5, 0, 5],
            },
            body: {
                fontSize: 11,
                alignment: 'justify',
                margin: [0, 0, 0, 5],
                lineHeight: 1.3,
            },
            signature: {
                fontSize: 12,
                bold: false,
                margin: [0, 10, 0, 0],
            },
        },
        defaultStyle: {
            font: 'Roboto',
        }
    };
    const pdfGerarAquivo = pdfMake.createPdf(docDefinition);
    return pdfGerarAquivo;
};
export {
    AutorizacaoPDF
}
