import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/home";

import Farmacovigilancia from "../pages/farmacovigilancia";
import LegislacaoDocumentos from "../pages/legislacaoDocumentos";
import Contactos from "../pages/contactos";

import Informacoes from "../pages/home/services/informacoes/informacioes";
import Licenciamento from "../admin/departamentos/dlif/licenciamento/utente/licenciamento";
import Agendamento from "../pages/home/services/agendamento/agendamento";
import Desalfandegar from "../pages/home/services/importacaoExportacao-remover/desalfandegar";
import Autorizados from "../pages/home/services/autorizados/autorizados";

import PageNoticias from "../pages/home/noticias";
import ViewNoticia from "../pages/home/noticias/viewNoticia";
import TemplateHome from "../layouts/templateHome";
import AgendaDisponivelParaMarcacao from "../pages/home/services/agendamento/agendaDisponivelParaMarcacao";
import DesalfandegarEquipamentos from "../pages/home/services/importacaoExportacao-remover/desalfandegarEquipamentos";
import DesalfandegarMedicamentosRecepcionados from "../pages/home/services/importacaoExportacao-remover/desalfandegarMedicamentosRecepcionados";
import DesalfandegarMedicamentosOutrosFarmaceuticos from "../pages/home/services/importacaoExportacao-remover/desalfandegarMedicamentosOutrosFarmaceuticos";
import ConsultaEstabelecimento from "../pages/consultor/consultaEstabelecimento";
import ConsultaTecnico from "../pages/consultor/consultaTecnico";
import ViewDestaque from "../pages/home/destaques/viewDestaque";
import VerifyAgendamentos from "../admin/departamentos/dlif/licenciamento/utente/verifyAgendamentos";
import EditeProcesso from "../admin/departamentos/dlif/licenciamento/editeProcesso";
import Renovacao from "../admin/departamentos/dlif/renovacao/utente";
import PoliticasServicos from "../pages/home/PoliticasServicos";
import ImportacaoExportacao from "../pages/home/services/importacaoExportacao-remover";
import FormSoliciteImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/formSoliciteImportacao";
import FormAddItemImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/formAddItemImportacao";
import ConsultaSolicitacaoImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/consultaSolicitacaoImportacao";
import GetSiteInfor from "../pages/components/getSiteInfor";
import ListaPublicaEstabelecimentosLicenciados from "../pages/listaPublicaEstabelecimentosLicenciados";
import ArtigoDetalhe from "../pages/home/artigosCientifico/listArtigos/artigoDetalhe";
import FormSoliciteDesalfandegamento from "../admin/departamentos/dmts/importacaoExportacao/utente/formSoliciteDesalfandegamento";
import ExportsRecibos from "../admin/departamentos/dlif/licenciamento/exportsRecibos";
import InspeccaoRegulatoria from "../pages/home/services/importacaoExportacao-remover/inspeccaoRegulatoria";
import MedicamentosAutorizados from "../pages/components/MedicamentosAutorizados";
import UsersMedicamentoDetalhes from "../pages/components/UsersMedicamentoDetalhes";
import FormSoliciteDesalfandegamentoOnly from "../admin/departamentos/dmts/importacaoExportacao/utente/desalfandegamentoDirecto/formSoliciteDesalfandegamentoDirecto";
import CadastroImportador from "../admin/departamentos/dmts/importacaoExportacao/cadastroImportador";

const CommonRoutes = ({ user }) => [
  //  {/* ROUTAS PUBLICAS */}

  <Route key="0001" exact element={<Home />} path="/" />,
  //  {/* /utente/:fazer-reclamacao"  */}
  <Route key="0002" element={<Home />} path="/utente/:action" />,
  <Route key="0003" element={<TemplateHome element={<PoliticasServicos />} />} path="/politicas-e-servicos" />,

  <Route key="0004" element={<TemplateHome element={<GetSiteInfor menuTitle='SOBRE NÓS' menu='SOBRE NÓS' />} />} path="/sobre-nos" />,
  <Route key="0005" element={<TemplateHome element={<Farmacovigilancia />} />} path="/farmacovigilancia" />,
  <Route key="0006" element={<TemplateHome element={<GetSiteInfor menuTitle='FARMACOVIGILÂNCIA / Ensaios Clinicos' opcao='Ensaios Clinicos' />} />} path="/ensaios-clinicos" />,
  <Route key="0007" element={<TemplateHome element={<GetSiteInfor menuTitle='FARMACOVIGILÂNCIA / Reações Adversas' opcao='Reações Adversas' />} />} path="/reacoes-adversas" />,
  <Route key="0008" element={<TemplateHome element={<LegislacaoDocumentos />} />} path="/legislacao-e-documentos" />,
  <Route key="0009" element={<TemplateHome element={<GetSiteInfor menuTitle='DESPACHOS' tipoFile='Despacho' />} />} path="/despachos" />,
  <Route key="00010" element={<TemplateHome element={<GetSiteInfor menuTitle='LEIS' tipoFile='Lei' />} />} path="/leis" />,
  <Route key="00011" element={<TemplateHome element={<GetSiteInfor menuTitle='DECRETOS' tipoFile='Decreto' />} />} path="/decretos" />,
  <Route key="00012" element={<TemplateHome element={<GetSiteInfor menuTitle='OUTROS' tipoFile='Outros' />} />} path="/outros" />,

  <Route key="00013" element={<TemplateHome element={<Informacoes />} />} path="/informacoes" />,
  <Route key="00014" element={<TemplateHome element={<GetSiteInfor menuTitle='REQUISITOS DE DESALFANDEGAMENTO' opcao='Desalfandegamento' />} />} path="/informacoes-sobre-desalfandegamento" />,
  <Route key="00015" element={<TemplateHome element={<GetSiteInfor menuTitle='REQUISITOS PARA AUTORIZAÇÃO DO EXERCÍCIO DA ACTIVIDADE FABRIL' opcao='Licenciamento de Fábricas' />} />} path="/informacoes-sobre-licenciamento-de-fabricas" />,
  <Route key="00016" element={<TemplateHome element={<GetSiteInfor menuTitle='REQUISITOS ADMINISTRATIVOS PARA ABERTURA DE FARMÁCIAS/ERVANÁRIAS' opcao='Licenciamento de Fármacias/Ervanárias' />} />} path="/informacoes-sobre-licenciamento-de-farmacias-e-ervanarias" />,
  <Route key="00017" element={<TemplateHome element={<GetSiteInfor menuTitle='REQUISITOS ADMINISTRATIVOS PARA REPRESENTANTES DE LABORATÓRIOS FARMACÊUTICOS' opcao='Representantes de Laboratório Farmacêuticos' />} />} path="/informacoes-sobre-representante-e-laboratorio-farmaceutico" />,
  <Route key="00018" element={<TemplateHome element={<GetSiteInfor menuTitle='REQUISITOS ADMINISTRATIVOS PARA ABERTURA DE DEPOSITO DE MEDICAMENTOS/EQUIPAMENTOS' opcao='Importadores' />} />} path="/informacoes-sobre-importadores" />,
  <Route key="00019" element={<TemplateHome element={<GetSiteInfor menuTitle='PRODUTOS CONTROLADOS' opcao='Produtos Controlados' />} />} path="/informacoes-sobre-produtos-controlados" />,
  <Route key="00020" element={<TemplateHome element={<GetSiteInfor menuTitle='PEDIDOS DE AIM' opcao='Pedidos de AIM/Registo de Medicamentos ' />} />} path="/informacoes-sobre-pedido-de-aim" />,

  <Route key="00021" element={<TemplateHome element={<Contactos />} />} path="/contactos" />,
  <Route key="00022" element={<TemplateHome element={<Autorizados />} />} path="/autorizados" />,
  <Route key="00023" element={<TemplateHome element={<ConsultaEstabelecimento />} />} path="/verifica-estabelecimento/:idEstabelecimento" />,
  <Route key="00024" element={<TemplateHome element={<ConsultaTecnico />} />} path="/verifica-tecnico/:idTecnico" />,

  <Route key="00025" element={<TemplateHome element={<ListaPublicaEstabelecimentosLicenciados />} />} path="/estabelecimentos-licenciados-armed" />,

  //  {/* EDICAO DE PROCESSO VIA UTENTE */}
  <Route key="00026" element={<TemplateHome element={<EditeProcesso />} />} path="/edite-processo-estabelecimento/:id" />,

  //  {/* IMPORTACAO E EXPORTACAO */}
  <Route key="00027" element={<TemplateHome element={<ImportacaoExportacao />} />} path="/importacao-e-exportacao" />,
  <Route key="00027" element={<TemplateHome element={<InspeccaoRegulatoria />} />} path="/inspeccao-regulatoria" />,
  <Route key="00027" element={<TemplateHome element={<CadastroImportador />} />} path="/utente/solicitacao/numero-de-importador" />,
  <Route key="00028" element={<TemplateHome footer={false} element={<FormSoliciteImportacao />} />} path="/inspeccao-regulatoria/solicitacao/importacao/:tipo" />,
  <Route key="00028" element={<TemplateHome footer={false} element={<FormSoliciteImportacao />} />} path="/solicitar/importacao/:tipo" />,
  <Route key="00029" element={<TemplateHome footer={false} element={<FormAddItemImportacao title={'ADICIONAR OS ITENS A IMPORTAR'} />} />} path="/solicitar/importacao/add-item/:id" />,
  <Route key="00030" element={<TemplateHome footer={false} element={<ConsultaSolicitacaoImportacao />} />} path="/utente/solicitacao/importacao/:id" />,
  <Route key="00030a" element={<TemplateHome footer={false} element={<FormSoliciteDesalfandegamento />} />} path="/utente/solicitacao/desalfandegamento/:id" />,
  <Route key="00030e" element={<TemplateHome footer={false} element={<FormSoliciteDesalfandegamentoOnly />} />} path="/utente/solicitacao/desalfandegamento-directo/:produto" />,

    //  {/* DESALFANDEGAMENTO DIRECTO */}
    <Route key="00029" element={<TemplateHome footer={false} element={<FormAddItemImportacao title={'ADICIONAR OS ITENS A DESALFANDEGAR'} />} />} path="/solicitar/desalfangamento/add-item/:id" />,

  <Route key="00031" element={<TemplateHome element={<Desalfandegar />} />} path="/desalfandegar" />,
  <Route key="00032" element={<TemplateHome element={<DesalfandegarEquipamentos />} />} path="/desalfandegar-equipamentos" />,
  <Route key="00033" element={<TemplateHome element={<DesalfandegarMedicamentosRecepcionados />} />} path="/desalfandegar-medicamentos-recepcionados" />,
  <Route key="00034" element={<TemplateHome element={<DesalfandegarMedicamentosOutrosFarmaceuticos />} />} path="/desalfandegar-medicamentos-outros-produto-farmaceuticos" />,

  <Route key="00035" element={<TemplateHome element={<Agendamento />} />} path="/agendamento" />,
  <Route key="00036" element={<TemplateHome element={<AgendaDisponivelParaMarcacao />} />} path="/agenda-disponivel" />,

  <Route key="00037" element={<TemplateHome element={<VerifyAgendamentos />} />} path="/verify-agendamentos/:agendaId" />,
  <Route key="00038" element={<TemplateHome element={<Licenciamento />} />} path="/licenciamento/:agendaId" />,
  <Route key="00039" element={<TemplateHome element={<Renovacao />} />} path="/solicitacao-de-renovacao-de-estabelecimento" />,

  <Route key="00040" element={<TemplateHome element={<PageNoticias />} />} path="/noticias" />,
  <Route key="00041" element={<TemplateHome element={<ViewNoticia />} />} path="/view-noticia/:id" />,
  <Route key="00042" element={<TemplateHome element={<ViewDestaque />} />} path="/view-destaque/:id" />,
  <Route key="00043" element={<TemplateHome element={<ArtigoDetalhe />} />} path="/view-artigo-cientifico/:id" />,

    // Medicamnrtos Autorizados

  <Route key="00044" element={<TemplateHome element={<MedicamentosAutorizados/>}/>} path="/medicamentos-autorizados" />,
  <Route key="00045" element={<TemplateHome element={<UsersMedicamentoDetalhes/>} />}  path="/medicamento-autorizado/:id" />,

  <Route key="0004" element={<TemplateHome element={<ExportsRecibos />} />} path="/utente/meu-recibo/:id" />,
]

export default CommonRoutes;
