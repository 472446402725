import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderSession from "../../../utils/headerSession";

interface UtilizacaoMedicamento {
  _id: string;
  tipo: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface ClasseTerapeutica {
  _id: string;
  nome: string;
  pai: string | null;
  subcategorias: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface FormaFarmaceutica {
  _id: string;
  titulo: string;
  descricao: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Solicitacao {
  _id: string;
  tipoSolicitacao: string;
  nomeGenerico: string;
  nomeComercial: string;
  classeTerapeutica: ClasseTerapeutica;
  formaFarmaceutica: FormaFarmaceutica;
  dosagem: string;
  indicacoesTerapeuticas: string;
  outrasIndicacoes: string;
  doseAdulto: string;
  dosepediatrica: string;
  duracaoTratamento: string;
  contraIndicacoes: string;
  precaucoes: string;
  toxicidade: string;
  motivoSubstituicao: string;
  razoesTerapeuticas: string;
  referenciasBibliograficas: string[];
  utilizacaoMedicamento: UtilizacaoMedicamento[];
  nomeSolicitante: string;
  biSolicitante: string;
  cargoSolicitante: string;
  localTrabalhoSolicitante: string;
  contatoTelefoneSolicitante: string;
  emailSolicitante: string;
  dataSolicitacao: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const DetalhesSolicitacao = () => {
  const location = useLocation();
  const solicitacao = location.state as Solicitacao;

  if (!solicitacao) {
    return (
      <Box textAlign="center">
        <Typography variant="h6">Solicitação não encontrada.</Typography>
        <HeaderSession title="FORMULÁRIO DE SOLICITAÇÃO" />
      </Box>
    );
  }

  return (
    <Container>
      <HeaderSession title="FORMULÁRIO DE SOLICITAÇÃO" />
      
      {/* Tabela de Dados da Solicitação */}
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#f5f5f5", width: "30%", fontWeight: "bold" }}>
                Campos
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              { label: "Tipo de Solicitação", value: solicitacao.tipoSolicitacao },
              { label: "Nome Genérico", value: solicitacao.nomeGenerico },
              { label: "Nome Comercial", value: solicitacao.nomeComercial },
              { label: "Classe Terapêutica", value: solicitacao.classeTerapeutica?.nome },
              { label: "Forma Farmacêutica", value: solicitacao.formaFarmaceutica?.titulo },
              { label: "Dosagem/Concentração", value: solicitacao.dosagem },
              { label: "Indicações Terapêuticas", value: solicitacao.indicacoesTerapeuticas },
              { label: "Outras Indicações", value: solicitacao.outrasIndicacoes },
              { label: "Dose para Adulto", value: solicitacao.doseAdulto },
              { label: "Dose Pediátrica", value: solicitacao.dosepediatrica },
              { label: "Duração do Tratamento", value: solicitacao.duracaoTratamento },
              { label: "Contra-indicações", value: solicitacao.contraIndicacoes },
              { label: "Precauções", value: solicitacao.precaucoes },
              { label: "Toxicidade", value: solicitacao.toxicidade },
              { label: "Motivo da Substituição", value: solicitacao.motivoSubstituicao },
              { label: "Razões Terapêuticas", value: solicitacao.razoesTerapeuticas },
            ].map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                  {item.label}
                </TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                Referências Bibliográficas
              </TableCell>
              <TableCell>
                {solicitacao.referenciasBibliograficas?.map((ref, index) => (
                  <Typography key={index}>{ref}</Typography>
                ))}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                Nível de Utilização
              </TableCell>
              <TableCell>
                {solicitacao.utilizacaoMedicamento?.map((nivel, index) => (
                  <Typography key={index}>{nivel.tipo}</Typography>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Tabela de Dados do Solicitante */}
      <Typography variant="h6" gutterBottom sx={{ mt: 3, mb: 2 }}>
        Dados do Solicitante
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#f5f5f5", width: "30%", fontWeight: "bold" }}>
                Campos
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              { label: "Nome do Solicitante", value: solicitacao.nomeSolicitante },
              { label: "B.I.", value: solicitacao.biSolicitante },
              { label: "Cargo", value: solicitacao.cargoSolicitante },
              { label: "Local de Trabalho", value: solicitacao.localTrabalhoSolicitante },
              { label: "Contato", value: solicitacao.contatoTelefoneSolicitante },
              { label: "E-mail", value: solicitacao.emailSolicitante },
              { label: "Data da Solicitação", value: solicitacao.dataSolicitacao },
            ].map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                    {item.label}
                </TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DetalhesSolicitacao;
