import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card, Button, Typography, Divider } from "@mui/material";
import ListaVazia from "../../../../utils/listaVazia";
import ObservacoesFeitas from "../observacoesFeitas";
import StatusEstabelecimento from "../../../../utils/statusEstabelecimento";
import { useSelector } from "react-redux";
import ImagemEdit from "../../../../utils/imagemEdit";
import StatusEntrada from "../../../../utils/statusEntrada";
import ChangeStatusEstabelecimento from "../../../gestAdmin/changeStatusEstabelecimento";

export default function NavLateral({ estabelecimento, observacoesFeitas }) {
  // const url = useLocation();
  const user = useSelector((state) => state.account.user);
  const [status, setStatus] = useState();

  const { id } = useParams();
  const { encaminhar } = useParams();
  const navigate = useNavigate();

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus); // Atualiza o estado com o novo status
    // console.log("Status atualizado para:", newStatus);
    window.location.reload(); // Recarrega a página inteira
  };

  // console.log(estabelecimento)

  return (
    <>
      <Card style={{ padding: 20, marginBottom: 20 }}>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", placeItems:'center'}}>
          <ImagemEdit
            data={estabelecimento}
            url={"/estabelecimento/change-logo"}
            local={"estabelecimentoId"}
            openEditLogo={false}
            // setOpenEditLogo={setOpenEditLogo}
          />
          <ChangeStatusEstabelecimento
            estabelecimentoId={estabelecimento?._id}
            currentStatus={status}
            onStatusChange={handleStatusChange}
          />
        </div>
        <Typography m align="center">
          Tipo da entrada
        </Typography>
        <StatusEntrada status={estabelecimento.tipoEntrada} />

        <Typography m align="center">
          Estado da solicitação
        </Typography>
        <StatusEstabelecimento status={estabelecimento.status} />

        <br />
        <Divider />

        <Box>
          <br />

          {estabelecimento?.status !== "Activo" ? (
            <>
              {estabelecimento?.status === "Reprovado" && (
                <Button
                  autoFocus
                  fullWidth
                  size="small"
                  color="success"
                  variant={encaminhar === "approve" ? "contained" : "outlined"}
                  onClick={() => navigate(`/admin/approve/Análise/${id}`)}
                >
                  Encaminhar para chefe da secção
                </Button>
              )}
              {estabelecimento?.status === "Análise" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Técnico" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    autoFocus
                    fullWidth
                    size="small"
                    color="success"
                    variant={
                      encaminhar === "approve" ? "contained" : "outlined"
                    }
                    onClick={() => navigate(`/admin/approve/Analisado/${id}`)}
                  >
                    Encaminhar para chefe da secção
                  </Button>
                  <Button
                    disabled={
                      user?.funcao === "Técnico" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                    onClick={() => navigate(`/admin/repprove/Reprovado/${id}`)}
                  >
                    Reprovar Solicitação
                  </Button>
                </>
              )}
              {estabelecimento?.status === "Analisado" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Chefe de Secção" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="success"
                    variant={
                      encaminhar === "approve" ? "contained" : "outlined"
                    }
                    onClick={() =>
                      navigate(`/admin/approve/Analisado Chefe de Secção/${id}`)
                    }
                  >
                    Encaminhar para chefe de departamento
                  </Button>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Chefe de Secção" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                    onClick={() => navigate(`/admin/repprove/Análise/${id}`)}
                  >
                    Voltar para Análise
                  </Button>
                </>
              )}
              {estabelecimento?.status === "Analisado Chefe de Secção" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="success"
                    variant={
                      encaminhar === "approve" ? "contained" : "outlined"
                    }
                    onClick={() => navigate(`/admin/inspecao/approve/${id}`)}
                  >
                    Encaminhar para inspecção
                  </Button>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                    onClick={() => navigate(`/admin/repprove/Analisado/${id}`)}
                  >
                    Voltar para chefe de secção
                  </Button>
                </>
              )}
              {estabelecimento?.status === "Inspecção Agendada" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Inspector" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={() =>
                      navigate(`/admin/inspeccionar-estabelecimento/${id}`)
                    }
                  >
                    Inspecçionar Agora
                  </Button>
                  <Button
                    disabled
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                  >
                    Cancelar Inspecção
                  </Button>
                </>
              )}
              {estabelecimento?.status === "Inspecção Realizada" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="success"
                    variant={
                      encaminhar === "approve" ? "contained" : "outlined"
                    }
                    onClick={() => navigate(`/admin/approve/Deferimento/${id}`)}
                  >
                    Encaminhar para Director(a) Geral
                  </Button>
                  <Button
                    disabled={
                      user?.funcao === "Chefe de Departamento" ||
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                    onClick={() => navigate(`/admin/inspecao/approve/${id}`)}
                  >
                    Reagendar Inspecção{" "}
                  </Button>
                </>
              )}
              {estabelecimento?.status === "Deferimento" && (
                <>
                  <Button
                    disabled={
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="success"
                    variant={
                      encaminhar === "approve" ? "contained" : "outlined"
                    }
                    onClick={() => navigate(`/admin/emissao/autorizacao/${id}`)}
                  >
                    Aprovar e Emitir Autorização de Exerciçio
                  </Button>
                  <Button
                    disabled={
                      user?.funcao === "Director Geral" ||
                      user?.funcao === "CNP"
                        ? false
                        : true
                    }
                    fullWidth
                    size="small"
                    color="error"
                    variant={
                      encaminhar === "repprove" ? "contained" : "outlined"
                    }
                    style={{ marginTop: 20 }}
                    onClick={() =>
                      navigate(`/admin/repprove/Inspecção Realizada/${id}`)
                    }
                  >
                    Voltar para chefe de departamento{" "}
                  </Button>
                </>
              )}
            </>
          ) : (
            <ListaVazia text={"Processo já análisado!"} />
          )}
        </Box>
      </Card>

      {observacoesFeitas.length !== 0 && (
        <ObservacoesFeitas observacoesFeitas={observacoesFeitas} />
      )}
    </>
  );
}
