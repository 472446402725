import { Container } from '@mui/material'
import MedicamentosAutorizados from '../../../components/MedicamentosAutorizados'

export default function Autorizados() {
    return (
        <Container style={{ minHeight: '72vh' }}>
            <MedicamentosAutorizados/>
        </Container>
    )
}

