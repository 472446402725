import { useState } from "react";
import api from "../../../../../../services/api";
import MessageSuccess from "../../../../../../messages/messageSuccess";
import MessageError from "../../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../../load/loadingBackdrop";
import RegisterAccess from "../../../../../../utils/registerAccess";
import HeaderSession from "../../../../../../utils/headerSession";
import ButtonLeft from "../../../../../../utils/buttonLeft";
import {
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup"; // VALIDACAO
import { useDispatch } from "react-redux";
import DocsMedicamentosControladosInternacionalmenteAnexo from "./docsMedicamentosControladosInternacionalmenteAnexo";
import DocsEquipamentoAnexo from "./docsEquipamentoAnexo";
import DocsMedicamentosControladosNacionaisAnexo from "./docsMedicamentosControladosNacionaisAnexo";
import DocsMedicamentosNormalAnexo from "./docsMedicamentosNormalAnexo";
import DocsReagentesTDRsAnexo from "./docsReagentesTDRsAnexo";
import DocsSuplementoAlimentarAnexo from "./docsSuplementoAlimentarAnexo";
import { CountryDropdown } from "react-country-region-selector";
import { makeStyles } from "@material-ui/core";
import PontosEntrada from "../../../../../../utils/pontosEntrada";

const useStyles = makeStyles((theme) => ({
  inputPais: {
    padding: 15,
    borderRadius: 4,
    borderColor: "#e1e1e1",
    width: "100%",
    // border: "dashed",
  },
}));

export default function FormSoliciteDesalfandegamentoDirecto() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [solicitacao, setSolicitacao] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const { produto } = useParams();

  async function HandleChangeFile(file, name) {
    setSolicitacao((prevCadastro) => ({ ...prevCadastro, [name]: file }));
  }

  function handleChange(e) {
    e.target.files
      ? setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
      : setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value });
  }

  async function createSolicitacaoDesalfandegamento() {
    const formData = new FormData();

    formData.append("numeroPorte", solicitacao?.numeroPorte);
    formData.append("numFatura", solicitacao?.numFatura);
    formData.append("destino", solicitacao?.destino);
    formData.append("pontoEntrada", solicitacao?.pontoEntrada);
    formData.append("numeroImportador", solicitacao?.numeroImportador);
    formData.append("paisOrigem", solicitacao?.paisOrigem);
    formData.append("endereco", solicitacao?.endereco);
    formData.append("tipoCertificado", produto);

    // Docs
    // formData.append("solicitacao", solicitacao?.solicitacao ?? '') //
    formData.append("cartaPorteBl", solicitacao?.cartaPorteBl ?? ""); //
    formData.append("certificadoCQL", solicitacao?.certificadoCQL ?? ""); //
    formData.append("certificadoOP", solicitacao?.certificadoOP ?? ""); //
    // formData.append("folhetoILP", solicitacao?.folhetoILP ?? '') //
    formData.append("faturaFinal", solicitacao?.faturaFinal ?? ""); //
    formData.append("faturaEmolumento", solicitacao?.faturaEmolumento ?? ""); //
    formData.append(
      "CertificadoAnaliticoLote",
      solicitacao?.CertificadoAnaliticoLote ?? ""
    );

    if (produto === "Medicamentos-controlados-internacionalmente") {
      formData.append(
        "certificadoExportacao",
        solicitacao?.certificadoExportacao ?? ""
      );
      formData.append(
        "certificadoProdutoControlado",
        solicitacao?.certificadoProdutoControlado ?? ""
      );
    }

    if (produto === "Suplemento-Alimentar") {
      formData.append(
        "declaracaoExclusividade",
        solicitacao?.declaracaoExclusividade ?? ""
      ); //
    }
    // if (produto === 'Medicamentos-controlados-nacionais') {}
    // if (produto === 'Medicamento-Normal') { }
    // if (produto === 'Equipamento') {}
    // if (produto === 'Reagentes-TDRs') {}

    setOpen(true);
    setErrorMessage("");
    setMessage("");

    await api
      .post("/declaracao-desalfandegamento-directo/register", formData)
      .then((res) => {
        setMessage(res.data.msg);
        dispatch({
          type: "alert",
          payload: { alert: "Solicitação foi enviada com sucesso.!" },
        });

        // console.log(res)
        navigate(
          `/solicitar/desalfangamento/add-item/${res.data.solicitacaoImportacao._id}`
        );
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        // console.log(error)
      })
      .finally(() => setOpen(false));
  }

  async function validate() {
    setErrorMessage("");

    // if (tipo !== 'Medicamentos-controlados-nacionais') {
    //     schema = yup.object().shape({
    //         autorizacaoExportacao: yup.string("Necessário fazer o upload da Autorização de exportação emitida pelas entidades competentes do país de origem")
    //             .required("Necessário fazer o upload da Autorização de exportação emitida pelas entidades competentes do país de origem"),
    //     })
    // }

    let schema = yup.object().shape({
      faturaEmolumento: yup
        .string(
          "Necessário fazer o upload da Factura de pagamento de emolumento Armed"
        )
        .required(
          "Necessário fazer o upload da Factura de pagamento de emolumento Armed"
        ),
      faturaFinal: yup
        .string(
          "Necessário fazer o upload da Factura Final dos produtos importados, na língua portuguesa"
        )
        .required(
          "Necessário fazer o upload da Factura Final dos produtos importados, na língua portuguesa"
        ),
      // folhetoILP: yup.string("Necessário fazer o upload dos folhetos informativos em língua portuguesa").required("Necessário fazer o upload dos folhetos informativos em língua portuguesa"),
      // certificadoOP: yup.string("Necessário fazer o upload do Certificado de Origem dos produtos").required("Necessário fazer o upload do Certificado de Origem dos produtos"),
      // certificadoCQL: yup.string("Necessário fazer o upload do Certificado de controlo de qualidade por lotes").required("Necessário fazer o upload do Certificado de controlo de qualidade por lotes"),
      // cartaPorteBl: yup.string("Necessário fazer o upload da Carta de porte ou BL").required("Necessário fazer o upload da Carta de porte ou BL"),
      // solicitacao: yup.string("Necessário fazer o upload da carta dirigida à Armed").required("Necessário fazer o upload da carta dirigida à Armed"),

      pontoEntrada: yup
        .string("Necessário selecionar o ponto de entrada")
        .required("Necessário selecionar o ponto de entrada"),
      destino: yup
        .string("Necessário selecionar o destino dos produtos")
        .required("Necessário selecionar o destino dos produtos"),
      numFatura: yup
        .string("Necessário inserir o número de factura emitida pela Armed")
        .required("Necessário inserir o número de factura emitida pela Armed"),
      numeroPorte: yup
        .string("Necessário inserir número de porte")
        .required("Necessário inserir o número de porte"),
    });

    try {
      await schema.validate(solicitacao);
      createSolicitacaoDesalfandegamento();
    } catch (error) {
      setErrorMessage(error.errors);
    }
    return false;
  }

  return (
    <div>
      <RegisterAccess page={"solicitação de importação only"} />
      <LoadingBackdrop open={open} text={"Enviando solicitação.!"} />

      <Container style={{ minHeight: "70vh" }}>
        <HeaderSession title={`SOLICITAÇÃO DE DESALFANDEGAME | ${produto}`} />
        {message && <MessageSuccess message={message} />}
        {messageError && <MessageError message={messageError} />}

        <>
          <Typography variant="h5" m={2}>
            Preenche o formulário:
          </Typography>

          <Card style={{ padding: 10 }}>
            <Grid container>
              <Grid xs={12} md item m>
                <TextField
                  type="text"
                  label="Nº de Importador"
                  fullWidth
                  name="numeroImportador"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>

              <Grid xs={12} md item m>
                <TextField
                  type="text"
                  label="Nº da Factura (Emolumento Armed)"
                  fullWidth
                  name="numFatura"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={12} md item m>
                <PontosEntrada handleChange={handleChange} />
              </Grid>

              <Grid xs={12} md item m>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Destino</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Destino"
                    name="destino"
                    onChange={handleChange}
                    // defaultValue={addItem?.moedas ?? ''}
                  >
                    <MenuItem value="" disabled>
                      ...
                    </MenuItem>
                    <MenuItem value="Comercialização">Comercialização</MenuItem>
                    <MenuItem value="Doação">Doação</MenuItem>
                    <MenuItem value="Uso pessoal">Uso pessoal</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={12} md item m>
                <TextField
                  type="text"
                  label="Nº de Porte"
                  fullWidth
                  name="numeroPorte"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>

              <Grid xs={12} md item m />
            </Grid>

            {/* <hr style={{ border: '1px solid #c43ab9', marginTop: 10 }} /> */}
            <Typography align="center" m={2} color={"secondary"}>
              DADOS DA ORIGEM
            </Typography>

            <Grid container>
              <Grid xs={12} md item m>
                <CountryDropdown
                  style={{
                    color: "gray",
                    border: '1px solid gray-100',
                    backgroundColor: "transparent",
                    height: "100%",
                  }}
                  className={classes.inputPais}
                  value={solicitacao?.paisOrigem ?? ""}
                  onChange={(pais) =>
                    setSolicitacao({ ...solicitacao, paisOrigem: pais })
                  }
                  defaultOptionLabel="Pais de Origem"
                />
              </Grid>

              <Grid xs={12} md item m>
                <TextField
                  type="text"
                  label="Endereço"
                  fullWidth
                  name="endereco"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={12} md item m>
                <TextField
                  type="text"
                  label="Nº da Fatura Final"
                  fullWidth
                  name="numFaturaFinal"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} md item m />
            </Grid>

            {/* ANEXAR DOCUMENTOS */}
            <Typography align="center" m={2} color={"secondary"}>
              ANEXAR DOCUMENTOS{" "}
            </Typography>

            {/* 
                        Medicamentos-controlados-internacionalmente
                        Medicamentos-controlados-nacionais
                        Medicamento-Normal
                        Suplemento-Alimentar
                        Equipamento
                        Reagentes-TDRs 
                        */}

            {produto === "Medicamentos-controlados-internacionalmente" && (
              <DocsMedicamentosControladosInternacionalmenteAnexo
                HandleChangeFile={HandleChangeFile}
              />
            )}
            {produto === "Medicamentos-controlados-nacionais" && (
              <DocsMedicamentosControladosNacionaisAnexo
                HandleChangeFile={HandleChangeFile}
              />
            )}
            {produto === "Medicamento-Normal" && (
              <DocsMedicamentosNormalAnexo
                HandleChangeFile={HandleChangeFile}
              />
            )}
            {produto === "Suplemento-Alimentar" && (
              <DocsSuplementoAlimentarAnexo
                HandleChangeFile={HandleChangeFile}
              />
            )}
            {produto === "Equipamento" && (
              <DocsEquipamentoAnexo HandleChangeFile={HandleChangeFile} />
            )}
            {produto === "Reagentes-TDRs" && (
              <DocsReagentesTDRsAnexo HandleChangeFile={HandleChangeFile} />
            )}

            <ButtonLeft
              title={"Submeter"}
              funcao={validate}
              disabled={!solicitacao}
            />
          </Card>
        </>
      </Container>
      <br />
    </div>
  );
}
