import { Alert, AlertTitle } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import InspeccaoRealizada from "./avfd/inspeccaoRealizada";
import RegisterAccess from "../../../../utils/registerAccess";


export default function GetInspeccoes() {
    const { id } = useParams()
    const [inspeccoes, setInspeccoes] = useState('');

    useEffect(() => {
        api.get(`/inspeccaos?estabelecimentoId=${id}`)
            .then(response => {
                // console.log(response)
                setInspeccoes(response.data.inspeccaos);
            }).catch(err => 'console.log(err)')
    }, [id])

    return (
        <>
            <RegisterAccess page={'ver inspeccoes'} />
            {inspeccoes ?
                <>
                    {inspeccoes.length > 0 ?

                        inspeccoes.map((inspeccao, index) => (
                            // <div></div>
                            <InspeccaoRealizada key={inspeccao._id} inspeccaoId={inspeccao._id} numero={index + 1 + 'ª'} />
                        ))
                        :
                        <Alert severity="info">
                            <AlertTitle>Nenhuma inspecção encontrada.</AlertTitle>
                            Este Estabelecimento ainda não teve nenhum tipo de inspecção.
                        </Alert>
                    }
                </>
                :
                <Loading text={'Buscando Inspecções'} />
            }
        </>
    )
}

