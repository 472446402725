
import { useEffect, useState } from "react";
import HeaderSession from "../../../../utils/headerSession";
import { Alert, AlertTitle, Card, Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import api from "../../../../services/api";
import RegisterAccess from "../../../../utils/registerAccess";
import ItensFactura from "./utente/itensFactura";
import DocumentViewer from "./documentViewer";
import FichaSolicitacaoImportacao from "./fichaSolicitacaoImportacao";
import { makeStyles } from "@material-ui/core";
import NavLateral from "../../../gestLicenciados/navLateral";
import DocumentosEmitidos from "./docsEmitidos/documentosEmitidos";
import FichaSolicitacaoDesalfandegamento from "./fichaSolicitacaoDesalfandegamento";
import HeaderGestorConfigs from "../../../../utils/headerGestorConfigs";
import ImagemEdit from "../../../../utils/imagemEdit";

const useStyles = makeStyles((theme) => ({
    grid1: {
        width: 300,
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('md')]: {
            // width: '100%',
            height: '100%',
            position: 'sticky',
            top: 70,
        },
    },
    grid2: {
        flexGrow: 1,
        marginLeft: 15,
        // minHeight: '100vh',
        width: 500,
        zIndex: 1,
        // position: 'relative',
    }
}))

export default function GestSolicitacaoImportacaoExportacao({ element: component, ...rest }) {
    const { id } = useParams()
    const classes = useStyles()
    const [solicitacaoImportacao, setSolicitacao] = useState('')
    const [estabelecimento, setEstabelecimento] = useState('')
    const [refresh, setRefresh] = useState(null)

    useEffect(() => {
        setEstabelecimento(null);

        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response);
                setSolicitacao(response.data.solicitacaoImportacao);
                setEstabelecimento(response.data.solicitacaoImportacao.importador);
            }).catch(err => 'console.log(err)')
    }, [id, refresh])


    return (
        <Container>
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'GESTÃO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />

            {estabelecimento ?
                <Grid container>

                    <Grid item className={classes.grid1} m>
                        {solicitacaoImportacao?.importador.status === 'Não Licenciado' ?
                            <Card>
                                <ImagemEdit data={solicitacaoImportacao?.importador} />

                                <p style={{ fontSize: 14, textAlign: 'center' }}>
                                    NÃO LICENCIADO
                                </p>
                            </Card>
                            :
                            <NavLateral estabelecimento={solicitacaoImportacao?.importador} thisId={solicitacaoImportacao?.importador._id} />
                        }
                    </Grid>

                    <Grid xs item className={classes.grid2} m>
                        {solicitacaoImportacao?.declaracao ?
                            <>
                                <FichaSolicitacaoDesalfandegamento solicitacaoImportacao={solicitacaoImportacao} setRefresh={setRefresh} admin={true} />
                                <br />
                                {solicitacaoImportacao?.declaracao?.docs &&
                                    <Card style={{ padding: 20 }}>
                                        <DocumentViewer pdfUrls={solicitacaoImportacao?.declaracao?.docs} title={'DOCUMENTOS ANEXADOS PARA DESALFANDEGAMENTO'} />
                                    </Card>
                                }
                                <br />
                            </>
                            :
                            <Alert severity="info">
                                <AlertTitle>Aguardando desalfandegamento</AlertTitle>
                                Este processo está aguardando a solicitação de desalfandegamento.
                            </Alert>
                        }

                        <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} setRefresh={setRefresh} admin={true} />

                        <br />
                        {solicitacaoImportacao?.docs &&
                            <Card style={{ padding: 20 }}>
                                <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} title={'DOCUMENTOS ANEXADOS PARA IMPORTAÇÃO'} />
                            </Card>
                        }

                        <br />
                        <Card style={{ padding: 20 }}>
                            <HeaderGestorConfigs title={`ITENS ADICIONADOS : ${solicitacaoImportacao?.items.length}`} />
                            <ItensFactura itens={solicitacaoImportacao?.items} />
                        </Card>

                        {((solicitacaoImportacao?.status === 'Aprovado' || solicitacaoImportacao?.status === 'Não solicitado') && solicitacaoImportacao?.dataAprovacao) &&
                            <>
                                <br />
                                <DocumentosEmitidos solicitacaoImportacao={solicitacaoImportacao} />
                            </>
                        }

                    </Grid>
                </Grid>
                :
                <Loading />
            }
        </Container>

    )
}