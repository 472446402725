import { Box } from "@material-ui/core"
import { Grid } from "@mui/material";
import InputDocUpload from "../../../../../../utils/inputFileUpload/inputDocUpload";


export default function DocsEquipamentoAnexo({ HandleChangeFile }) {

    return (
        <Box>
            <Grid container>
                <Grid xs={12} md item m>
                <InputDocUpload
                        onFileSelect={HandleChangeFile}
                        inputName={'certificadoOP'}
                        inputDescription={'Certificado de origem'}
                        inputTitle={'Certificado de origem'}
                    />
                </Grid>

                <Grid xs={12} md item m>
                    <InputDocUpload
                        onFileSelect={HandleChangeFile}
                        inputName={'faturaFinal'}
                        inputDescription={'Factura final em portugues (formato Excel)'}
                        inputTitle={'Factura final'}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid xs={12} md item m>
                    <InputDocUpload
                        onFileSelect={HandleChangeFile}
                        inputName={'cartaPorteBl'}
                        inputDescription={'Carta de porte'}
                        inputTitle={'Carta de porte'}
                    />
                </Grid>

                <Grid xs={12} md item m>
                <InputDocUpload
                        onFileSelect={HandleChangeFile}
                        inputName={'faturaEmolumento'}
                        inputDescription={'Factura de pagamento de emolumento na Armed'}
                        inputTitle={'Factura de pagamento de emolumento na Armed'}
                    />
                </Grid>

            </Grid>

        </Box>
    )
}


